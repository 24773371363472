import config from '../config';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale(config.locale);
import description from './description';
import { marked } from "marked";

export default {
  "check": [
    {
      group: "基本情報",
      key: "ans_date",
      title: "回答日",
      type: "date-date",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
      },
      value: null
    },
    {
      group: "基本情報",
      key: "sex",
      title: "性別",
      required: true,
      type: "chip-group",
      options: {
        mandatory: false,
        multiple: false,
        items: [{ text: "男", value: 1 }, { text: "女", value: 2 }]
      },
      value: null
    },
    /*
    {
      group: "基本情報",
      key: "birthdt",
      title: "生年月日",
      required: true,
      type: "date-date",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },*/
    {
      group: "基本情報",
      key: "age",
      title: "年齢",
      required: true,
      type: "text-field",
      options: {
        type: "number",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "歳",
        min: 40,
        max: 130,
        maxlength: 400,
      },
      value: null
    },
    {
      group: "基本情報",
      key: "careLevel",
      title: "要介護状態区分",
      type: "chip-group",
      options: {
      //  mandatory: false,
      //  multiple: false,
        items: [
          { text: "自立", value: 1 },
          { text: "要支援1", value: 2 },
          { text: "要支援2", value: 3 },
          { text: "要介護1", value: 4 },
          { text: "要介護2", value: 5 },
          { text: "要介護3", value: 6 },
          { text: "要介護4", value: 7 },
          { text: "要介護5", value: 8 }
        ]
      },
      value: null
    },
    {
      group: "生活機能全般",
      key: "judge_i_iv",
      title: "判定",
      type: "judge",
      value: null,
    },
    {
      group: "生活機能全般",
      key: "judge01_20",
      title: "1〜20の点数(＊)",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "生活機能全般",
      key: "check001",
      title: "1、バスや電車で1人で外出していますか<br>(1人で自家用車を運転して外出する場合も「はい」となります)",
      required: true,
      type: "chip-group",
      description: marked(description.check001),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: []
    },
    {
      group: "生活機能全般",
      key: "check002",
      title: "2、日用品の買い物をしていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check002),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: []
    },
    {
      group: "生活機能全般",
      key: "check003",
      title: "3、預貯金の出し入れをしていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check003),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "生活機能全般",
      key: "check004",
      title: "4、友人の家を訪ねていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check004),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "生活機能全般",
      key: "check005",
      title: "5、家族や友人の相談にのっていますか<br>(電話で相談に応じている場合も「はい」となります)",
      required: true,
      type: "chip-group",
      description: marked(description.check005),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "生活機能全般",
      title: "＊6 ～ 20 の質問事項も生活機能全般の対象となります。",
      type: "title",
    },
    {
      group: "運動器の機能",
      key: "judge06_10",
      title: "運動不足点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "運動器の機能",
      key: "check006",
      title: "6、階段を手すりや壁をつたわらずにのぼっていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check006),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "運動器の機能",
      key: "check007",
      title: "7、椅子に座った状態から何もつかまらずに立ち上がっていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check007),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "運動器の機能",
      key: "check008",
      title: "8、15分位続けて歩いていますか<br>(屋内、屋外などの場所は問いません)",
      required: true,
      type: "chip-group",
      description: marked(description.check008),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "運動器の機能",
      key: "check009",
      title: "9、この1年間に転んだことがありますか",
      required: true,
      type: "chip-group",
      description: marked(description.check009),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "運動器の機能",
      key: "check010",
      title: "10、転倒に対する不安は大きいですか",
      required: true,
      type: "chip-group",
      description: marked(description.check010),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "栄養状態",
      key: "judge11_12",
      title: "栄養改善点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "栄養状態",
      key: "check011",
      title: "11、6ヶ月で2〜3Kg以上の体重減少がありましたか",
      required: true,
      type: "chip-group",
      description: marked(description.check011),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "栄養状態",
      key: "height",
      title: "身長",
      required: true,
      type: "text-field",
      description: marked(description.height),
      options: {
        type: "number",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "cm",
        min: 1,
        max: 500,
        maxlength: 400,
      },
      value: ''
    },
    {
      group: "栄養状態",
      key: "weight",
      title: "体重",
      required: true,
      type: "text-field",
      description: marked(description.weight),
      options: {
        type: "number",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "kg",
        min: 1,
        max: 1000,
        maxlength: 400,
      },
      value: ''
    },
    {
      group: "栄養状態",
      key: "__bmi",
      title: "BMI",
      type: "bmi",
    },
    {
      group: "栄養状態",
      key: "check012",
      title: "12、栄養改善",
      type: "judge",
      value: null
    },
    {
      group: "口腔機能",
      key: "judge13_15",
      title: "口腔ケア点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "口腔機能",
      key: "check013",
      title: "13、半年前に比べて固いものが食べにくくなりましたか",
      required: true,
      type: "chip-group",
      description: marked(description.check013),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "口腔機能",
      key: "check014",
      title: "14、お茶や汁物でむせることがありますか",
      required: true,
      type: "chip-group",
      description: marked(description.check014),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "口腔機能",
      key: "check015",
      title: "15、口の渇きがきになりますか",
      required: true,
      type: "chip-group",
      description: marked(description.check015),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "閉じこもりの傾向",
      key: "judge16_17",
      title: "閉じこもり予防点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "閉じこもりの傾向",
      key: "check016",
      title: "16、週に1回以上は外出していますか(過去1ヶ月の状態の平均)",
      required: true,
      type: "chip-group",
      description: marked(description.check016),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "閉じこもりの傾向",
      key: "check017",
      title: "17、昨年と比べて外出の回数が減っていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check017),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "認知機能",
      key: "judge18_20",
      title: "物忘れ予防点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "認知機能",
      key: "check018",
      title: "18、周りの人から「いつも同じ事を聞く」などの物忘れがあると言われますか",
      required: true,
      type: "chip-group",
      description: marked(description.check018),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "認知機能",
      key: "check019",
      title: "19、自分で電話番号を調べて、電話をかけることをしていますか",
      required: true,
      type: "chip-group",
      description: marked(description.check019),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(0)", value: 0}, {text: "いいえ(1)", value: 1},],
      },
      value: null
    },
    {
      group: "認知機能",
      key: "check020",
      title: "20、今日が何月何日かわからない時がありますか<br>(月と日のどちらかしか分からない場合には「はい」となります。)",
      required: true,
      type: "chip-group",
      description: marked(description.check020),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "こころの健康状態",
      key: "judge21_25",
      title: "うつ予防点数",
      type: "judge",
      options: {
        suffix: "点",
      },
      value: null,
    },
    {
      group: "こころの健康状態",
      key: "check021",
      title: "21、(ここ2週間) 毎日の生活に充実感がない",
      required: true,
      type: "chip-group",
      description: marked(description.check021),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "こころの健康状態",
      key: "check022",
      title: "22、(ここ2週間) これまで楽しんでやれていたことが楽しめなくなった",
      required: true,
      type: "chip-group",
      description: marked(description.check022),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "こころの健康状態",
      key: "check023",
      title: "23、(ここ2週間) 以前が楽にできていたことが今はおっくうに感じられる",
      required: true,
      type: "chip-group",
      description: marked(description.check023),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "こころの健康状態",
      key: "check024",
      title: "24、(ここ2週間) 自分が役に立つ人間だと思えない",
      required: true,
      type: "chip-group",
      description: marked(description.check024),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
    {
      group: "こころの健康状態",
      key: "check025",
      title: "25、(ここ2週間) わけもなく疲れたような感じがする",
      required: true,
      type: "chip-group",
      description: marked(description.check025),
      options: {
        mandatory: false,
        multiple: false,
        items: [{text: "はい(1)", value: 1}, {text: "いいえ(0)", value: 0},],
      },
      value: null
    },
  ],
  // ----------------------------------------------------------------------------------
  "feedback": [
    {
      group: "面談",
      key: "fb_date",
      title: "実施日",
      required: false,
      type: "date-date",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "面談",
      key: "fb_tanto",
      title: "担当者",
      required: false,
      type: "text-field",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "面談",
      key: "fb_result",
      title: "面談結果",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "面談",
      key: "fb_kobetsu",
      title: "個別相談の希望有無",
      type: "chip-group",
      options: {
        mandatory: false,
        multiple: true,
        items: [{text: "運動", value: 1}, {text: "口腔", value: 2}, {text: "栄養", value: 3},],
      },
      value: null
    },
  ],
  "interview": [
    // -------------------
    {
      group: "運動",
      key: "fb1_date",
      title: "実施日",
      required: false,
      type: "date-date",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "運動",
      key: "fb1_tanto",
      title: "担当者",
      required: false,
      type: "text-field",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "運動",
      key: "fb1_result",
      title: "面談結果",
      type: "textarea",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "運動",
      key: "fb1_after",
      title: "その後の様子",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "栄養",
      key: "fb2_date",
      title: "実施日",
      required: false,
      type: "date-date",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "栄養",
      key: "fb2_tanto",
      title: "担当者",
      required: false,
      type: "text-field",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "栄養",
      key: "fb2_result",
      title: "面談結果",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "栄養",
      key: "fb2_after",
      title: "その後の様子",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "口腔",
      key: "fb3_date",
      title: "実施日",
      required: false,
      type: "date-date",
      options: {
        defaultDate: dayjs().format('YYYY-MM-DD'),
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "口腔",
      key: "fb3_tanto",
      title: "担当者",
      required: false,
      type: "text-field",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "口腔",
      key: "fb3_result",
      title: "面談結果",
      type: "textarea",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "口腔",
      key: "fb3_after",
      title: "その後の様子",
      type: "textarea",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "その他",
      key: "fb4_date",
      title: "実施日",
      required: false,
      type: "date-date",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "その他",
      key: "fb4_tanto",
      title: "担当者",
      required: false,
      type: "text-field",
      options: {
        type: "text",
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 400,
      },
      value: null
    },
    {
      group: "その他",
      key: "fb4_result",
      title: "面談結果",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
    {
      group: "その他",
      key: "fb4_after",
      title: "その後の様子",
      type: "textarea",
      options: {
        clearable: false,
        label: undefined,
        placeholder: undefined,
        prependIcon: undefined,
        prependInnerIcon: undefined,
        suffix: "",
        maxlength: 1200,
      },
      value: null
    },
  ]
};