<template>
  <div
    v-if="selectedGuest"
    class="careplan pa-2"
  >
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <v-col
          id="care-plan-guest-name"
          class="py-2"
          justify="center"
        >
          <v-skeleton-loader
            v-if="isGuestsLoading || !selectedGuest"
            type="heading"
          />
          <h2
            v-else
            id="guest-name"
            style="display:block; line-height: 1.2; float:left;"
          >
            <b style="vertical-align: middle; padding-right:10px">
              {{ selectedGuest.lastName }} {{ selectedGuest.firstName }}
            </b>
            <span class="mr-1">様</span>
            <span
              v-if="carePlanDtm"
              class="mr-1 care-plan-dt"
            >{{ carePlanDtm }}のチェックリスト</span>
          </h2>
        </v-col>
        <v-col
          id="checklist-edit-btn-outer"
          class="py-2 hide--print"
          style="text-align: right !important;"
        >
          <v-btn
            id="checklist-create-btn"
            color="primary"
            :loading="isCheckListCreating"
            :disabled="isCheckListsLoading || !selectedGuest || !selectedGuest.isOwner || isCheckListCreating"
            @click.stop="createCheckListDialog.visible = true"
          >
            <v-icon start>
              mdi-file-plus
            </v-icon>チェックリスト新規作成
            <v-tooltip
              activator="parent"
              location="top"
            >
              新しいチェックリストを作成します。
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-sheet elevation="5">
      <v-container
        class="pa-0"
        fluid
      >
        <v-row
          no-gutters
          class="careplan__container"
        >
          <v-col
            :class="{'hide--overflow': display.xs.value || (display.sm.value && !isHistoryClosed)}"
          >
            <v-row
              class="hide--print"
              no-gutters
            >
              <v-col
                id="mainTabAreaOuter"
                align-self="start"
              >
                <v-tabs
                  id="mainTabArea"
                  v-model="tabIdx"
                  color="primary"
                  bg-color="transparent"
                >
                  <v-tab
                    id="tab-1"
                    key="tab-1"
                    :disabled="isCheckListsLoading || !selectedCheckList"
                    @click.stop="tabSelect(0)"
                  >
                    <span>チェック</span>
                    <span>リスト</span>
                  </v-tab>
                  <v-tab
                    id="tab-2"
                    key="tab-2"
                    :disabled="isCheckListsLoading || !selectedCheckList"
                    @click.stop="tabSelect(1)"
                  >
                    予測
                  </v-tab>
                  <v-tab
                    id="tab-3"
                    key="tab-3"
                    :disabled="isCheckListsLoading || !selectedCheckList"
                    @click.stop="tabSelect(2)"
                  >
                    <span>フィード</span>
                    <span>バック</span>
                  </v-tab>
                  <v-tab
                    id="tab-4"
                    key="tab-4"
                    :disabled="isCheckListsLoading || !selectedCheckList"
                    @click.stop="tabSelect(3)"
                  >
                    <span>個別</span>
                    <span>相談</span>
                  </v-tab>
                </v-tabs>
              </v-col>
              <v-col
                md="auto"
                cols="12"
                align-self="center"
              >
                <span
                  v-tooltip="((tabIdx == 0) ?
                    'チェックリスト' : (tabIdx == 1) ?
                      'チェックリスト・フィードバック・個別相談' : (tabIdx == 2) ?
                        'フィードバック' : '個別相談') + 'を編集します'"
                >
                  <v-btn
                    id="edit-btn"
                    variant="text"
                    :disabled="isCheckListsLoading || !selectedGuest || !selectedCheckList || tabIdx == 1"
                    style="margin-left:0rem; margin-right:0.5rem"
                    @click.stop="editCheckList"
                  >
                    <v-icon color="gray-darken-1">
                      mdi-pencil
                    </v-icon> 編集
                  </v-btn>
                </span>
                <span v-tooltip="`予測を印刷します`">
                  <v-btn
                    id="print-btn"
                    variant="text" 
                    :disabled="isCheckListsLoading || !selectedCheckList || tabIdx != 1" 
                    style="margin-left:0rem; margin-right:0.5rem"
                    @click="print"
                  >
                    <v-icon color="gray-darken-1">
                      mdi-printer
                    </v-icon> 印刷
                  </v-btn>
                </span>
                <v-btn
                  id="delete-btn"
                  variant="text"
                  :disabled="isCheckListsLoading || !selectedGuest || !selectedGuest.isOwner || !selectedCheckList"
                  style="margin-left:0rem; margin-right:0.5rem"
                  @click.stop="showDeleteCheckListDialog(true)"
                >
                  <v-icon>mdi-trash-can</v-icon> 削除
                  <v-tooltip
                    activator="parent"
                    location="top"
                  >
                    このリストを削除します。
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>

            <!-- タブ1:チェックリスト -->
            <v-row
              v-if="tabIdx == 0 && !isCheckListsLoading"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-6"
              >
                <v-expansion-panels
                  v-model="customPanel_1"
                  multiple
                >
                  <v-expansion-panel key="check-panel-0">
                    <v-expansion-panel-title>
                      <div class="text-h6">
                        判定
                        <span v-html="JudgeDisp('judge_i_iv', checklists['check'])" />
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text id="judgement">
                      <v-row>
                        <v-col cols="4">
                          ① 全般的な生活機能低下の恐れ
                        </v-col>
                        <v-col
                          id="judge-1"
                          cols="2"
                          v-html="JudgeDisp('judge01_20', checklists['check'])"
                        />
                        <v-col cols="4">
                          ⑤ 閉じこもりの恐れ
                        </v-col>
                        <v-col
                          id="judge-5"
                          cols="2"
                          v-html="JudgeDisp('judge16_17', checklists['check'])"
                        />
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          ② 運動機能低下の恐れ
                        </v-col>
                        <v-col
                          id="judge-2"
                          cols="2"
                          v-html="JudgeDisp('judge06_10', checklists['check'])"
                        />
                        <v-col cols="4">
                          ⑥ 認知機能低下の恐れ
                        </v-col>
                        <v-col
                          id="judge-6"
                          cols="2"
                          v-html="JudgeDisp('judge18_20', checklists['check'])"
                        />
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          ③ 低栄養の恐れ
                        </v-col>
                        <v-col
                          id="judge-3"
                          cols="2"
                          v-html="JudgeDisp('judge11_12', checklists['check'])"
                        />
                        <v-col cols="4">
                          ⑦ うつ傾向の可能性あり
                        </v-col>
                        <v-col
                          id="judge-7"
                          cols="2"
                          v-html="JudgeDisp('judge21_25', checklists['check'])"
                        />
                      </v-row>
                      <v-row>
                        <v-col cols="4">
                          ④ 口腔機能低下の恐れ
                        </v-col>
                        <v-col
                          id="judge-4"
                          cols="2"
                          v-html="JudgeDisp('judge13_15', checklists['check'])"
                        />
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel key="check-panel-1">
                    <v-expansion-panel-title>
                      <div class="text-h6">
                        資料のダウンロード
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-row id="result">
                        <v-col cols="4">
                          <span>結果お知らせ</span>
                        </v-col>
                        <v-col cols="8">
                          <v-btn
                            id="pdf-btn"
                            :disabled="enableCheck(selectedGuest)"
                            variant="text"
                            @click="pdfCheckList"
                          >
                            <v-icon color="gray-darken-1">
                              mdi-file-pdf-box
                            </v-icon>結果
                            <v-icon color="gray-darken-1">
                              mdi-tray-arrow-down
                            </v-icon>
                            <v-tooltip
                              v-if="!enableCheck(selectedGuest)"
                              activator="parent"
                              location="top"
                            >
                              結果のPDFをダウンロードします。
                            </v-tooltip>
                          </v-btn>
                          <v-btn
                            id="pdf-predict-btn"
                            variant="text"
                            :disabled="enableCheck(selectedGuest)"
                            @click="pdfPrediction"
                          >
                            <v-icon color="gray-darken-1">
                              mdi-file-pdf-box
                            </v-icon>予測
                            <v-icon color="gray-darken-1">
                              mdi-tray-arrow-down
                            </v-icon>
                            <v-tooltip
                              v-if="!enableCheck(selectedGuest)"
                              activator="parent"
                              location="top"
                            >
                              予測のPDFをダウンロードします。
                            </v-tooltip>
                          </v-btn>
                        </v-col>
                        <v-tooltip
                          v-if="enableCheck(selectedGuest)"
                          activator="parent"
                          location="top"
                        >
                          自立していて入力に不備がない場合のみダウンロード可能です。
                        </v-tooltip>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                  <v-expansion-panel
                    v-for="(group, g) in customData"
                    :key="`check-group-${g}`"
                  >
                    <v-expansion-panel-title>
                      <div class="text-h6">
                        {{ group.name }}
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col cols="12">
                          <table
                            class="conditionlist__table"
                            :class="{'conditionlist__table--small': display.xs.value}"
                          >
                            <template v-for="(item, h) in group.items">
                              <tr
                                v-if="item.type !== 'etc'"
                                v-show="item.key !== 'judge_i_iv'"
                                :key="`check-item-${h}`"
                                :class="{
                                  'points_text': (item.type === 'judge' && $_.includes(item.key, 'judge')),
                                  'alert': ($_.includes(Judge(item), '該当あり')),
                                  'check': $_.includes(item.key, 'check'),
                                  'judge': $_.includes(item.key, 'judge'),
                                  [item.key]: !$_.isNil(item.key) && (!$_.includes(item.key, 'check') && !$_.includes(item.key, 'judge')),
                                  'other': $_.isNil(item.key)
                                }"
                              >
                                <th
                                  :colspan="item.type === 'title' ? 2 : 1"
                                  :width="item.type === 'title' ? '100%' : '50%'"
                                  v-html="item.title"
                                />
                                <td
                                  v-if="item.type !== 'title'"
                                  :id="$_.kebabCase(item.key)"
                                  width="50%"
                                >
                                  <template
                                    v-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value)"
                                  >
                                    {{ $_.get($_.find(item.options.items, x => x.value === item.value), 'text') }}
                                    <template v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['check'], (x) => x.key === item.etc.key))">
                                      ：<span v-html="item1.title" />
                                      <span :id="$_.kebabCase(item1.key)">{{ item1.value }}</span> {{ item1.options.suffix }}
                                    </template>
                                  </template>
                                  <template v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                                    {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                                  </template>
                                  <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                                    {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                                  </template>
                                  <template v-else-if="item.type === 'text-field' && !$_.isEmpty(item.value)">
                                    {{ item.value }} {{ item.options.suffix }}
                                  </template>
                                  <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value)">
                                    {{ item.value }}
                                  </template>
                                  <template v-else-if="item.type === 'textarea' && !$_.isEmpty(item.value)">
                                    {{ item.value }}
                                  </template>
                                  <template v-else-if="item.type === 'judge' && !$_.isNil(item.value)">
                                    <span v-html="Judge(item)" />
                                  </template>
                                  <template v-else-if="item.type === 'title'" />
                                  <template v-else-if="item.type === 'bmi'">
                                    <span v-html="calcBMI(checklists['check'])" />
                                  </template>
                                  <template v-else>
                                    -
                                  </template>
                                </td>
                              </tr>
                            </template>
                          </table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>

            <!-- タブ2:予測 -->
            <v-row
              v-else-if="tabIdx == 1 && !isCheckListsLoading"
              no-gutters
            >
              <template
                v-if="$_.isNil(selectedCheckList.results)
                  || $_.isUndefined(selectedCheckList.results.report_info_list)"
              >
                <v-col align="center">
                  <br>
                  <h3>予測データはありません。</h3>
                </v-col>
              </template>          
              <template v-else>
                <v-col
                  cols="6"
                  class="pa-6"
                >
                  <div class="text-h6">
                    各機能低下の恐れ
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="pa-6"
                  style="text-align: right;"
                >
                  <small>
                    来年度悪化の可能性 
                    <v-icon :color="dispColor(0)">mdi-circle-slice-8</v-icon>：低 /
                    <v-icon :color="dispColor(1)">mdi-circle-slice-8</v-icon>：注意 /
                    <v-icon :color="dispColor(2)">mdi-circle-slice-8</v-icon>：警告
                  </small>
                </v-col>
                <v-col
                  cols="12"
                  class="pa-6"
                >
                  <v-expansion-panels
                    v-model="customPanel_3"
                    multiple
                  >
                    <v-expansion-panel
                      v-for="(item, i) in reportInfo"
                      :key="`report-panel-${i}`"
                    >
                      <v-expansion-panel-title>
                        <div
                          :id="`analyse-title-${i}`"
                          class="text-h6"
                        >
                          <v-icon
                            v-if="item.func != '3年以内における要介護認定の可能性'"
                            :color="funcColor(item.func)"
                          >
                            mdi-circle-slice-8
                          </v-icon>
                          {{ item.func }}
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-row>
                          <v-col
                            :id="`analyse-comment-${i}`"
                          >
                            <div
                              v-for="(categoly_list, j) in item.category_list"
                              :key="`report-cate-${j}`"
                            >
                              <h4>{{ categoly_list.category }}</h4>
                              <ul style="margin-bottom:1rem">
                                <li
                                  v-if="!$_.isNil(categoly_list.judge_result_text) && $_.includes(categoly_list.judge_result_text, '該当と判定されました。改善を目指しましょう。')"
                                  class="first"
                                >
                                  <mark
                                    class="alert"
                                    v-html="categoly_list.judge_result_text.replace(/\n/g, '<br />')"
                                  />
                                </li>
                                <li
                                  v-else-if="!$_.isNil(categoly_list.judge_result_text)"
                                  class="first"
                                  v-html="categoly_list.judge_result_text.replace(/\n/g, '<br />')"
                                />
                                <!-- 心配事 -->
                                <li
                                  v-for="(worry_list, k) in categoly_list.worry_list"
                                  :key="`report-worry-${k}`"
                                  class="second"
                                >
                                  <p v-html="worry_list.worry_text.replace(/\n/g, '<br />')" />
                                </li>
                              </ul>
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            style="text-align: right;"
                            class="hide--print"
                          >
                            <v-btn
                              id="copy-to-clipboard"
                              variant="text"
                              @click="cp2Clipboard(`analyse-title-${i}`, `analyse-comment-${i}`)"
                            >
                              <v-icon>mdi-clipboard-text-outline</v-icon>
                              <v-tooltip
                                activator="parent"
                                location="top"
                              >
                                クリップボードにコピーします。
                              </v-tooltip>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </template>
            </v-row>

            <!-- タブ3:F/B -->
            <v-row
              v-else-if="tabIdx == 2 && !isCheckListsLoading"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-6"
              >
                <v-row
                  v-for="(group, l) in customFeedback"
                  :key="`feedback-group-${l}`"
                >
                  <v-col cols="12">
                    <h3 class="conditionlist__groupname">
                      {{ group.name }}
                    </h3>
                    <table
                      class="conditionlist__table"
                      :class="{'conditionlist__table--small': display.xs.value}"
                    >
                      <tr
                        v-for="(item, m) in group.items"
                        :key="`feedback-item-${m}`"
                      >
                        <th
                          :colspan="item.type === 'title' ? 2 : 1"
                          :width="item.type === 'title' ? '100%' : '50%'"
                          v-html="item.title"
                        />
                        <td
                          v-if="item.type !== 'title'"
                          :id="$_.kebabCase(item.key)"
                          width="50%"
                        >
                          <template v-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value)">
                            {{ $_.get($_.find(item.options.items, x => x.value === item.value), 'text') }}
                            <template v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['feedback'], (x) => x.key === item.etc.key))">
                              ：<span v-html="item1.title" />
                              <span :id="$_.kebabCase(item1.key)">{{ item1.value }}</span> {{ item1.options.suffix }}
                            </template>
                          </template>
                          <template v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                            {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                          </template>
                          <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                            {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                          </template>
                          <template v-else-if="item.type === 'text-field' && item.options.type !== 'number' && !$_.isEmpty(item.value)">
                            {{ item.value }} {{ item.options.suffix }}
                          </template>
                          <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value)">
                            {{ item.value }}
                          </template>
                          <template v-else-if="item.type === 'textarea' && !$_.isEmpty(item.value)">
                            {{ item.value }}
                          </template>
                          <template v-else-if="item.type === 'title'" />
                          <template v-else>
                            -
                          </template>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- タブ4:個別相談 -->
            <v-row
              v-else-if="tabIdx == 3 && !isCheckListsLoading"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-6"
              >
                <v-row
                  v-for="(group, n) in customInterview"
                  :key="`interview-group-${n}`"
                >
                  <v-col cols="12">
                    <h3 class="conditionlist__groupname">
                      {{ group.name }}
                    </h3>
                    <table
                      class="conditionlist__table"
                      :class="{'conditionlist__table--small': display.xs.value}"
                    >
                      <template v-for="(item, o) in group.items">
                        <tr
                          v-if="item.type != 'etc'"
                          :key="`interview-item-${o}`"
                        >
                          <th
                            width="50%"
                            v-html="item.title"
                          />
                          <td
                            :id="$_.kebabCase(item.key)"
                            width="50%"
                          >
                            <template v-if="item.type === 'chip-group' && !item.options.multiple && !$_.isNil(item.value)">
                              {{ $_.get($_.find(item.options.items, x => x.value === item.value), 'text') }}
                              <template v-if="!$_.isUndefined(item.etc) && !$_.isNil(item1 = $_.find(checklists['feedback'], (x) => x.key === item.etc.key))">
                                ：<span v-html="item1.title" />
                                <span :id="$_.kebabCase(item1.key)">{{ item1.value }}</span> {{ item1.options.suffix }}
                              </template>
                            </template>
                            <template v-else-if="item.type === 'chip-group' && item.options.multiple && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                              {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                            </template>
                            <template v-else-if="item.type === 'ew-chip-group' && !$_.isNil(item.value) && !$_.isEmpty(item.value)">
                              {{ $_(item.options.items).filter(x => $_.indexOf(item.value, x.value) >= 0).map(x => x.text).value().join('，') }}
                            </template>
                            <template v-else-if="item.type === 'text-field' && item.options.type !== 'number' && !$_.isEmpty(item.value)">
                              {{ item.value }} {{ item.options.suffix }}
                            </template>
                            <template v-else-if="item.type === 'date-date' && !$_.isNil(item.value)">
                              {{ item.value }}
                            </template>
                            <template v-else-if="item.type === 'textarea' && !$_.isEmpty(item.value)">
                              {{ item.value }}
                            </template>
                            <template v-else-if="item.type === 'title'" />
                            <template v-else>
                              -
                            </template>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- チェックリスト一覧 -->
          <v-col
            class="lightgray careplan__timeline hide--print"
            :class="{'close':isHistoryClosed, 'careplan__timeline--xs': display.xs.value}"
            cols="auto"
          >
            <v-row
              no-gutters
              class="careplan__timeline-container"
            >
              <v-col cols="12">
                <v-btn
                  block
                  variant="text"
                  height="48"
                  @click.stop="isHistoryClosed = !isHistoryClosed"
                >
                  <v-row no-gutters>
                    <v-col cols="auto">
                      <v-icon color="gray">
                        {{ historyIconName }}
                      </v-icon>
                      <v-tooltip
                        v-if="isHistoryClosed && (display.xs.value && !display.sm.value)"
                        activator="parent"
                        location="top"
                      >
                        作成日を表示します。
                      </v-tooltip>
                    </v-col>
                    <span v-if="!isHistoryClosed && !(display.xs.value && !display.sm.value)">リスト作成日</span>
                    <span v-else-if="display.xs.value && !display.sm.value">リスト作成日</span>
                    <v-tooltip
                      v-if="!isHistoryClosed && !(display.xs.value && !display.sm.value)"
                      activator="parent"
                      location="top"
                    >
                      作成日を折りたたみます。
                    </v-tooltip>
                  </v-row>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-progress-circular
                  v-if="isCheckListsLoading"
                  indeterminate
                  color="gray-lighten-2"
                  class="my-4"
                />
              </v-col>
              <v-col
                cols="12"
                class="careplan__timeline-content"
              >
                <v-list
                  v-show="!isCheckListsLoading"
                  density="compact"
                  color="transparent"
                  class="pa-0"
                >
                  <div
                    v-for="(list, p) in customCheckLists"
                    :key="'key_f_1_' + p"
                  >
                    <v-list-item
                      :id="`care-plan-item-${p}`"
                      :class="{'primary--text': checkListIdx === p}"
                      density="compact"
                      @click.stop="selectCheckList(list); isHistoryClosed = (display.xs.value && !display.sm.value) ?!isHistoryClosed:isHistoryClosed"
                    >
                      <v-icon class="mr-2">
                        mdi-file-document-outline
                      </v-icon>
                      <v-list-item-title>{{ list.targetDtm }}</v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!-- 削除確認ダイアログ -->
    <v-dialog
      id="deleteCarePlanDialog"
      v-model="deleteCheckListDialog.visible"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <h3>チェックリストの削除</h3>
        </v-card-title>
        <v-card-text>
          <div>{{ `${customSelectedCheckList.targetDtm}分の` }}</div>
          <div
            v-if="!!selectedGuest"
          >
            {{ `${selectedGuest.lastName} ${selectedGuest.firstName}さんのチェックリストを削除しますか？` }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-3">
            <v-row
              no-gutters
              justify="end"
            >
              <v-col
                cols="12"
                md="6"
                class="pa-1"
              >
                <v-btn
                  id="checklist-delete-cancel"
                  variant="text"
                  :disabled="isCheckListDeleting"
                  block
                  @click="showDeleteCheckListDialog(false)"
                >
                  キャンセル
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-1"
              >
                <v-btn
                  id="checklist-delete-submit"
                  color="secondary"
                  :loading="isCheckListDeleting"
                  :disabled="isCheckListDeleting"
                  block
                  @click="deleteCheckList"
                >
                  削除
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      id="createCheckListDialog"
      v-model="createCheckListDialog.visible"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <h3>チェックリストの新規作成</h3>
        </v-card-title>
        <v-card-text>
          <ew-date-input
            id="checklist-create-date"
            v-model="createCheckListDialog.date"
            label="作成日"
            :min="createCheckListDialog.min"
            :max="createCheckListDialog.max"
            :rules="[rule_ymd]"
            append-icon="mdi-calendar-outline"
          />
          ※ 同日のチェックリストがある場合は編集になります。
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-3">
            <v-row
              no-gutters
              justify="end"
            >
              <v-col
                cols="12"
                md="6"
                class="pa-1"
              >
                <v-btn
                  id="checklist-create-cancel"
                  variant="text"
                  block
                  @click="createCheckListDialog.visible = false"
                >
                  キャンセル
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-1"
              >
                <v-btn
                  id="checklist-create-submit"
                  color="primary"
                  :loading="isCheckListCreating"
                  :disabled="!canCreateCheckList"
                  block
                  @click="createCheckList"
                >
                  作成
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @namespace app.checklist
 */
import {mapGetters} from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
// dayjs設定
import "dayjs/locale/ja";
dayjs.locale(config.locale);
// 設定ファイル
import config from "../config";
// チェックリスト
import checklists from "../data/checklists";
import EwDateInput from "../components/EwDateInput";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import { useDisplay } from 'vuetify';
/**
 * ビューモデル
 * @class
 * @memberof app.checklist
 */
export default {
  name: "CheckList",
  components: {
    EwDateInput,
  },
  /**
   * 遷移前の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.token) {
        return await vm.checkCheckListRoute(
          {
            ..._.pick(to.params, [
              "guestId",
              "checkListId",
            ]),
            hash: to.hash,
          },
          true
        );
      }
    });
  },
  /**
   * ページ内遷移の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteUpdate(to, from, next) {
    // ユーザ確認
    if (_.isNil(this.activeUser) || _.isNil(this.activeUser.userId)) {
      // ログインダイアログ
      await this.$store.dispatch("openLoginDialog");
      next();
    } else {
      // 検証
      next(
        await this.checkCheckListRoute({
          ..._.pick(to.params, [
            "guestId",
            "checkListId",
          ]),
          hash: to.hash,
        })
      );
    }
  },
  setup() {
    const display = useDisplay();
    return {
      display
    };
  },
  data() {
    return {
      carePlanDtm: '',
      isGuestsLoading: false,
      isCheckListsLoading: false,
      defaultTab: 1,
      typeNames: [
        "check",
        "predict",
        "feedback",
        "interview",
      ],
      tabIdx: 0,
      customPanel_1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      customPanel_3: [0, 1, 2, 3, 4, 5, 6, 7],
      checkListIdx: 0,
      checklists,
      isCheckListCreating: false,
      isHistoryClosed: true,
      deleteCheckListDialog: {
        visible: false,
      },
      createCheckListDialog: {
        visible: false,
        date: dayjs().toISOString().substring(0, 10),
        min: dayjs().subtract(100, "year").toISOString().substring(0, 10),
        max: dayjs().add(1, "year").toISOString().substring(0, 10),
      },
      pathPrefix: 'guests',
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "activeUser",
      "activeOffices",
      "activeGuests",
      "selectedGuest",
      "activeCheckLists",
      "selectedCheckList",
    ]),
    canCreateCheckList() {
      return (
        !this.isCheckListCreating &&
        this.rule_ymd(this.createCheckListDialog.date) === true
      )
    },
    /**
     * 履歴アイコンの種別
     * @returns {string} アイコン名
     */
    historyIconName() {
      return this.isHistoryClosed ? "mdi-chevron-left-circle" : "mdi-chevron-right-circle";
    },
    /**
     * 利用者に登録されたチェックリスト一覧の加工
     * @returns {object} チェックリスト一覧
     */
    customCheckLists() {
      return _(this.activeCheckLists)
        .map((x) => ({
          checkListId: x.checkListId,
          targetDtm: dayjs(x.targetDtm).format("YYYY.MM.DD"),
          checklists: x.checklists,
        }))
        .value();
    },
    /**
     * 選択されたチェックリストの加工
     * @returns {object} チェックリスト
     */
    customSelectedCheckList() {
      return _([this.selectedCheckList || {}])
        .map((x) => ({
          checkListId: x.checkListId,
          targetDtm: dayjs(x.targetDtm).format("YYYY年M月D日"),
        }))
        .value()[0];
    },
    customFeedback() {
      let result = [];
      let group = null;
      _.forEach(this.checklists['feedback'], (item) => {
        // 新規グループのチェック
        if (item.group !== group) {
          // グループ登録
          result.push({
            name: item.group,
            items: [],
          });
          group = item.group;
        }
        // アイテム登録（ポインタコピー）
        _.last(result).items.push(item);
      });
      return result;
    },
    customInterview() {
      let result = [];
      let group = null;
      _.forEach(this.checklists['interview'], (item) => {
        // 新規グループのチェック
        if (item.group !== group) {
          // グループ登録
          result.push({
            name: item.group,
            items: [],
          });
          group = item.group;
        }
        // アイテム登録（ポインタコピー）
        _.last(result).items.push(item);
      });
      return result;
    },
    /**
     * 機能に対するインジゲータ色を設定する。
     */
    funcColor() {
      return function(val) {
        let value = {
          '生活機能全般' : 'life_functions',
          '運動機能' : 'motor_functions',
          '栄養状態' : 'life_nutritional_conditions',
          '口腔機能' : 'oral_functions',
          '閉じこもり' : 'seclusion',
          '認知機能' : 'cognitive_functions',
          'こころの状態' : 'depression',
        }
        try {
          return this.dispColor(this.selectedCheckList.results.predict[value[val]]);
        } catch (e) {
          //
        }
        return 'black';
      }
    },
    /**
     * 値に対応するインジゲータ色を設定する。
     */
    dispColor() {
      return function(val) {
        if (val == 2 || val == 3) {
          return "red";
        } else if (val == 1) {
          return "yellow";
        } else if (val == 0) {
          return "gray";
        }
        return "black";
      }
    },
    reportInfo: function() {
      return this.selectedCheckList.results.report_info_list
    },
    customCheck: function() {
      return this.checklists['check'];
    },
    /**
     * グループ化
     * @param {string} index インデックス
     * @returns {string} 値
     */
    customData: function() {
      let result = [];
      let group = null;
      let i = 0;
      _.forEach(this.customCheck /* this.checklists[index] */, (item) => {
        // 新規グループのチェック
        if (item.group !== group) {
          // グループ登録
          result.push({
            name: item.group,
            items: [],
            id: "inputGroup_" + i
          });
          i++
          group = item.group;
        }
        // アイテム登録（ポインタコピー）
        _.last(result).items.push(item);
      });
      return result;
    },
    /**
     * BMI値計算
     * @param object check チェックリストデータ
     * @param int mode 0:表示(default), 2:編集
     */
    calcBMI: function() {
      return function(check, mode = 0) {
        let height = _.get(_.find(check, (x) => x.key === 'height'), "value");
        let check012 = _.find(check, (x) => x.key === 'check012');
        if (_.isEmpty(height) || height <= 0) {
          check012.value = null;
          return '';
        }
        height /= 100;
        let weight = _.get(_.find(check, (x) => x.key === 'weight'), "value");
        if (_.isEmpty(weight)) {
          check012.value = null;
          return '';
        }
        let bmi = weight / height / height;
        if (mode === 2) {
        //  let check012 = _.find(checklists, (x) => x.key === 'check012');
          if (_.isNaN(bmi)) {
            check012.value = null;
          } else {
            check012.value = (bmi < 18.5) ? 1 : 0;
          }
        }
        if (_.isNaN(bmi)) {
          return '';
        }
        // return bmi.toFixed(1) + "[kg/㎡] = " + weight.toString() + "[kg] ÷ " + height.toString() + "<sup>2</sup>[㎡]"
        return bmi.toFixed(1) + "[kg/㎡] = 体重[kg] ÷ 身長<sup>2</sup>[㎡]"
      };
    },
    /**
     * チェック・再チェック判定
     * @param objerct item 対象項目
     * @param objerct check チェックオブジェクト
     */
    JudgeCheck: function() {
      return function(item, check) {
        if (item.key == 'check012') { // 栄養状態
          return this.JudgeDisp(item.key, check);
        }
        if (item.key == 'judge_i_iv') { // 生活機能全般 判定
          let keys = [
            ["judge01_20", "judge06_10", "judge11_12", "judge13_15"],
            ["judge16_17", "judge18_20", "judge21_25"],
          ];
          let exist = 0; // 該当なし
          let notexist = 0;
          for (let i = 0; i < keys.length; i++) {
            for (let key of keys[i]) {
              let val = _.get(_.find(check, (x) => x.key === key), 'value');
              if (2 === val) {
                exist++; // 該当ありかどうか
              } else if (1 === val) {
                notexist++;
              }
            }
          }
          if (exist) {
            item.value = 2;
          } else if (notexist) {
            item.value = 1;
          } else {
            item.value = 0;
          }
          return this.JudgeDisp(item.key, check);
        }

        // 判定
        let judges = {
          "judge01_20" : { // No.35 1〜20の点数
            keys: ["check001", "check002", "check003", "check004", "check005", "check006", "check007", "check008", "check009", "check010",
              "check011", "check012", "check013", "check014", "check015", "check016", "check017", "check018", "check019", "check020",],
            boundary: 10,
            suffix: ":10項目以上に該当あり）"
          },
          "judge06_10" : { // No.36 運動不足予防点数
            keys: ["check006", "check007", "check008", "check009", "check010",],
            boundary: 3,
            suffix: ":3項目以上に該当あり）",
          },
          "judge11_12" : { // No.37 栄養改善点数
            keys: ["check011", "check012",],
            boundary: 2,
            suffix: ":2項目に該当あり）",
          },
          "judge13_15" : { // No.38 口腔ケア予防点数
            keys: ["check013", "check014", "check015",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          },
          "judge16_17" : { // 閉じこもりの恐れ
            keys: ["check016", "check017",],
            boundary: 1,
            suffix: ":16に該当あり）",
          },
          "judge18_20" : { // 認知機能低下の恐れ
            keys: ["check018", "check019", "check020",],
            boundary: 1,
            suffix: ":1項目以上に該当あり）",
          },
          "judge21_25" : { // うつ傾向の可能性あり
            keys: ["check021", "check022", "check023", "check024", "check025",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          }
        };
        if (!_.isNil(judges[item.key])) {
          let judge = judges[item.key];
          let total = 0;
          if (item.key == "judge16_17") {
            let val = _.get(_.find(check, (x) => x.key === 'check016'), "value");
            total = !_.isNil(val) ? val : -1;
          } else {
            for (let key of judge.keys) {
              let value = _.get(_.find(check, (x) => x.key === key), "value");
              if (!_.isNil(value)) {
                total += value;
              } else {
                total = -1; // 未入力あり。
                break;
              }
            }
          }
          let result = "未入力あり";
          if (total === -1) {
            item.value = 0; // 未入力あり
          } else {
            item.value = (total >= judge.boundary) ? 2 : 1; // 1:該当なし
            result = _.toString(total);
            try {
              result += " " + item.options.suffix;
            } catch (err) {
              //
            }
            if (item.value == 2) { // 該当あり
              return "該当あり（" + result + judge.suffix;
            }
            return "該当なし（" + result + "）"
          }
          return result;
        }  
        return item.key;
      }
    },
    /**
     * 調査票・チェック判定
     * @param objerct item 対象項目
     */
    Judge: function() {
      return (item) => {
        return this.JudgeCheck(item, this.customCheck /* this.checklists['check'] */);
      }
    },
    /**
     * 該当・非該当表示
     * @param objerct val 値
     */
    JudgeDisp2: function() {
      return function(val) {
        let messages = [
          "<mark class='caution'>未入力あり</mark>",
          "<mark class='normal'>非該当</mark>",
          "<mark class='alert'>該　当</mark>",
        ];
        return messages[val] || "-";
      }
    },
    /**
     * チェック判定表示
     * @param objerct key キー
     * @param objerct check チェックオブジェクト
     */
    JudgeDisp: function() {
      return function(key, check) {
        // 判定結果を表示する場合
        let val = _.get(_.find(check, (x) => x.key === key), "value", 0);
        let messages = [];
        switch (key) {
          case 'judge_i_iv':
            messages = [
              "未入力あり",
              "<mark class='normal'>該当項目なし</mark>",
              "<mark class='alert'>該当項目あり</mark>",
            ];
            return messages[val] || "-";
          case 'check012': // 栄養状態
            messages = [
              "<mark class='normal'>BMIが 18.5以上です</mark>",
              "<mark class='alert'>BMIが 18.5未満です</mark>",
            ];
            return messages[val] || "未入力あり";
          default:
            return this.JudgeDisp2(val);
        }
      };
    },
  },
  watch: {
    /**
     * tokenの値が変化したときの処理
     * @param {String} val 新しい値
     * @param {String} old 古い値
     */
    async token(val, old) {
      if (_.isNil(old) && !_.isEmpty(val)) {
        await this.checkCheckListRoute({
          ..._.pick(this.$route.params, [
            "guestId",
            "checkListId",
          ]),
          hash: this.$route.hash,
        });
      }
    },
    /**
     * ログインダイアログの表示
     * @param {boolean} value 表示するかどうか
     */
    async visibleLoginDialog(value) {
      if (value === false) {
        let result = await this.$store.dispatch("selectGuest", {
          guestId: this.$route.params.guestId,
        });
        if (result === false) {
          // ユーザが存在しないため、一覧に戻る
          await this.$router.push(`/${this.pathPrefix}`).catch(() => {});
        }
      }
    },
    /**
     * タブ変更時の処理
     * @param {number} selectedIdx 選択されたタブのインデックス
     */
    async tabIdx(selectedIdx) {
      let currentIdx = _.indexOf(
        this.typeNames,
        _.trimStart(this.$route.hash, "#")
      );
      if (selectedIdx == this.defaultTab && currentIdx === -1) {
        await this.$router
          .push(`#${_.nth(this.typeNames, this.defaultTab)}`)
          .catch(() => {});
      }
    },
    /**
     * チェックリスト選択時の処理
     * @param {Object} checkList チェックリスト
     */
    selectedCheckList: {
      handler(checkList) {
        if (checkList) {
          // 選択時にロードする
          this.loadChecklists();
        } else {
          //選択されたチェックリストがない場合は初期化する
          for (let type of ['check', 'feedback', 'interview']) {
            _.forEach(this.checklists[type], (item) => {
              item.value = null;
            });
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--key-color', config.system_key_color);
    this.pathPrefix = this.$route.path.substring(0, 9) == '/_guests/' ? '_guests' : 'guests';
    this.isHistoryClosed = this.display.xs.value;
  },
  methods: {
    /**
     * 日付ルール
     * @param {*} value 値
     */
    rule_ymd(value) {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      return (pattern.test(value) && dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value) 
        || 'YYYY-MM-DD';
    },
    /**
     * 有効チェック
     * @param {*} item 
     */
    enableCheck(item) {
      return ((item.careLevel !== 1)  // 自立でない
      || (item.judge < 3)) // 未提出、不備あり
       ? true : false;
    },
    /**
     * 遷移時におけるURLの検査
     * @param {*} guestId 利用者ID
     * @param {*} checkListId ケアプランID
     * @param {*} hash ハッシュ
     * @returns {boolean} 遷移を許可するかどうか
     */
    async checkCheckListRoute({
      guestId,
      checkListId,
      hash
    }, isEnter = false) {
      // 1. 利用者確認
      if (_.isNil(guestId)) {
        // IDが不正：遷移を拒否
        return false;
      }
      if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
        // 利用者IDがキャッシュに存在しない：再読込
        this.isGuestsLoading = true;
        await this.$store.dispatch("updateGuests");
        this.isGuestsLoading = false;

        // 再確認
        if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
          // 発見できず：遷移を拒否
          _.delay(() => {
            this.$router.replace(`/${this.pathPrefix}`).catch(() => {});
          }, 500);
          return false;
        }
      }
      // 利用者を選択
      if (
        _.isNil(this.selectedGuest) ||
        this.selectedGuest.guestId !== guestId
      ) {
        await this.$store.dispatch("selectGuest", { guestId });
      }
      // 2. チェックリスト確認
      // 外部からの移動の場合、強制的にチェックリスト一覧を更新（情報鮮度を高めるため）
      if (isEnter && !_.isNil(this.token)) {
        await this.$store.dispatch("updateCheckLists");
      }
      if (_.isNil(checkListId) || _.isEmpty(checkListId)) {
        // チェックリストの指定がない：最初のひとつを指定
        if (this.activeCheckLists.length > 0) {
          // 最初のひとつを選択するURLを生成しreplace
          _.delay(() => {
            this.$router
              .replace(
                `/${this.pathPrefix}/${guestId}/checklists/${
                  _.head(this.activeCheckLists).checkListId
                }${hash}`
              )
              .catch(() => {});
          }, 500);
          return false;
        } else {
          // チェックリストがひとつも存在しない：遷移は認める（仕様）
          this.createCheckListDialog.visible = true;
          return false;
        }
      } else {
        // 選択しているプランと異なる場合は再選択
        if (
          _.isNil(this.selectedCheckList) ||
          this.selectedCheckList.checkListId !== checkListId
        ) {
          if (await this.$store.dispatch("selectCheckList", { checkListId })) {
            // DO NOTHING
          } else {
            // チェックリスト一覧を更新し再検証
            await this.$store.dispatch("updateCheckLists");
            if (await this.$store.dispatch("selectCheckList", { checkListId })) {
              // DO NOTHING
            } else {
              _.delay(() => {
                this.$router
                  .replace(`/${this.pathPrefix}/${guestId}/checklists`)
                  .catch(() => {});
              }, 500);
              return false;
            }
          }
        }
      }
      if (_.isNil(this.selectedCheckList.results)
       || _.isUndefined(this.selectedCheckList.results.report_info_list)
      ) {
        // AI呼び出し。
        // 必須項目数
        let _max = _(this.checklists['check'])
          .filter((x) => {
            return (!_.isNil(x.required) && x.required === true);
          })
          .value();

        
        // 必須項目中、値が入力された項目数
        let _val = _(_max)
          .filter((x) => {
            if (x.type === "text-field" && (x.options.type === "number")) {
              x.value = _.isEmpty(x.value) ? '' : `${x.value}`;
            }
            switch (x.type) {
              case "chip-group":
                if (!_.isNil(x.options.multiple) && x.options.multiple) {
                  return !_.isNil(x.value) && !_.isEmpty(x.value);
                } else {
                  return !_.isNil(x.value);
                }
              case "date-date":
                //return !_.isNil(x.value[0]) && !_.isNil(x.value[1]);
                return !_.isNil(x.value);
              case "text-field":
                return !_.isEmpty(x.value); //!_.isNil(x.value) && !_.isEmpty(x.value);
              case "textarea":
                return !_.isNil(x.value) || !_.isEmpty(x.value);
              case "title":
                return true;
              default:
                return !_.isNil(x.value);
              }
          })
          .value();

        if (_val.length == _max.length) {
          try {
            await this.$store.dispatch("callPredictFrail");
          } catch (err) {
            // alert(err.err_message);
          }
        }
      }
      // チェックリストUIの更新
      if (this.selectedCheckList) {
        let idx = _.findIndex(
          this.activeCheckLists,
          (x) => x.checkListId === this.selectedCheckList.checkListId
        );
        if (idx >= 0) {
          this.checkListIdx = idx;
        }
      }
      // 3. hashの処理
      if (_.isEmpty(hash)) {
        this.tabIdx = this.defaultTab;
      } else {
        let idx = _.indexOf(this.typeNames, _.trimStart(hash, "#"));
        if (idx == -1) {
          this.tabIdx = this.defaultTab;
        } else {
          this.tabIdx = idx;
        }
      }
      // 遷移を許可
      return true;
    },
    /**
     * チェックリスト削除ダイアログの表示
     */
    showDeleteCheckListDialog(value) {
      this.deleteCheckListDialog.visible = value;
    },
    /**
     * チェックリスト削除
     */
    async deleteCheckList() {
      this.isCheckListDeleting = true;
      await this.$store.dispatch("deleteCheckList", {
        checkListId: this.selectedCheckList.checkListId,
      });
      this.showDeleteCheckListDialog(false);
      _.delay(() => {
        this.isCheckListDeleting = false;
      }, 500);
      let hash = this.$route.hash;
      await this.$router
        .replace(`/${this.pathPrefix}/${this.selectedGuest.guestId}/checklists#${hash}`)
        .catch(() => {});
      return true;
    },
    /**
     * チェックリスト作成
     */
    async createCheckList() {
      // 同日のデータがあればそれを返す
      let same = _(this.activeCheckLists || [])
        .filter(x => dayjs(x.targetDtm).isSame(this.createCheckListDialog.date))
        .orderBy(['targetDtm', 'insDtm'], ['desc', 'desc'])
        .head();
      if (same) {
        if (!await confirm(`同日チェックリストあり
同日のチェックリストが既に登録されています。
このリストを開きますか？
キャンセル：日付選択に戻ります。`)) {
          return;
        }
      }
      this.isCheckListCreating = true;
      // チェックリストの作成
      try {
        let checkListId = await this.$store.dispatch("createCheckList", {
          guestId: this.selectedGuest.guestId,
          targetDt: dayjs(this.createCheckListDialog.date).format('YYYY-MM-DD'), //`${this.fiscalYear}-01-01`,
        });
        let checkList = _.find(
          this.activeCheckLists,
          (x) => x.checkListId === checkListId
        );
        _.delay(() => {
          this.isCheckListCreating = false;
        }, 500);
        if (checkList) {
          await this.$router
            .push(
              `/${this.pathPrefix}/${this.selectedGuest.guestId}/checklistedit/${
                checkList.checkListId
              }/${_.head(this.typeNames)}`
            )
            .catch(() => {});
        }
      } finally {
        this.isCheckListCreating = false;
      }
    },
    /**
     * チェックリスト編集
     */
    async editCheckList() {
      let guestId = this.selectedGuest.guestId;
      let checkListId = this.selectedCheckList.checkListId;
      if (guestId && checkListId) {
        let hash = _.nth(this.typeNames, this.tabIdx);
        await this.$router
          .push(`/${this.pathPrefix}/${guestId}/checklistedit/${checkListId}/${hash}`)
          .catch(() => {});
      }
    },
    /**
     * チェックリスト選択
     * @param {*} list チェックリスト
     */
    async selectCheckList(list) {
      let guestId = this.selectedGuest.guestId;
      let checkListId = list.checkListId;
      if (
        _.isNil(this.selectedCheckList) ||
        this.selectedCheckList.checkListId !== checkListId
      ) {
        let hash = this.$route.hash;
        await this.$router
          .push(`/${this.pathPrefix}/${guestId}/checklists/${checkListId}${hash}`)
          .catch(() => {});
      }
    },
    /**
     * タブ選択
     * @param {*} idx インデックス
     */
    async tabSelect(idx) {
      let currentIdx = _.indexOf(
        this.typeNames,
        _.trimStart(this.$route.hash, "#")
      );
      if (currentIdx !== idx) {
        await this.$router
          .push(`#${_.nth(this.typeNames, idx)}`)
          .catch(() => {});
      }
    },
    /**
     * チェックリストからチェックリストの読み込み（UIへのバインド）
     */
    loadChecklists() {
      if (this.selectedCheckList) {
        this.carePlanDtm = this.selectedCheckList.targetDtm?dayjs(this.selectedCheckList.targetDtm).format("YY年M月D日"):"";
        for (let type of ['check', 'feedback', 'interview']) {
          _.forEach(this.checklists[type], (item) => {
            item.value = !_.isNil(this.selectedCheckList.checklists[item.key]) ? this.selectedCheckList.checklists[item.key] : null;
          });
        }
      }
    },
    /**
     * チェックリスト印刷
     */
    print() {
      window.print();
    },
    /**
     * チェックリストPDF出力
     */
    async pdfCheckList() {
      let guests = [this.selectedGuest.guestId];
      let data = await this.$store.dispatch("pdfCheckList", {
        guests,
      });
      // base64 decode
      let base64 = data["DATA"];
      let bin = atob(base64.replace(/^.*,/, ''));
      let buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob = new Blob([buffer.buffer], {type: 'application/pdf'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'CheckList_' + dayjs().format('YYYYMMDD') + '.pdf';
      link.click();
    },
    /**
     * 予測PDF出力
     */
     async pdfPrediction() {
      let guests = [this.selectedGuest.guestId];
      let fiscalYear = this.fiscalYear;
      let data = await this.$store.dispatch("pdfPrediction", { guests, fiscalYear });
      // base64 decode
      let base64 = data["DATA"];
      let bin = atob(base64.replace(/^.*,/, ''));
      let buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      const blob = new Blob([buffer.buffer], {type: 'application/pdf'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Prediction_' + dayjs().format('YYYYMMDD') + '.pdf';
      link.click();
    },
    /**
     * クリップボードにコピーする
     * @param {*} id1,id2,id3 要素ID 
     */
     cp2Clipboard(id1, id2 = null, id3 = null) {
      let text = ''
      try {
        text += document.getElementById(id1).innerText;
      } catch(e) {
        //
      }
      try {
        if (text != '') {
          text += "\n";
        }
        if (!_.isNil(id2)) {
          text += document.getElementById(id2).innerText;
        }
      } catch(e) {
        //
      }
      if (!_.isNil(id3)) {
        if (text != '') {
          text += "\n";
        }
        text += document.getElementById(id3).innerText;
      }
      if (_.isEmpty(text)) {
        alert("分析内容が空です。");
        return;
      }
      navigator.clipboard.writeText(text)
      .then(() => {
        alert("クリップボードにコピーしました。");
      })
      .catch(e => {
        alert("クリップボードにコピーできませんでした。:" + e.message);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  div#care-plan-guest-name.py2{
    padding: 0 !important;
  }
  h2#guest-name {
    background-color: #eaeff1;
    padding: 0.1rem 0.1rem 0.5rem 0.6rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: -1px -1px 1px 0 #f1f7ff, inset 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  b {
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px #b0bbc1;
    line-height: 1.1;
  }

  .care-plan-dt {
    color: #ffffff;
    background-color: var(--key-color);
    padding: 0.1rem 0.2rem 0.2rem 0.3rem;
    font-weight: bold !important;
    border: 2px solid #fff;
    border-radius: 6px;
    box-shadow: 3px 2px 4px 0px rgb(137 150 172 / 30%);
    white-space: nowrap;
    text-shadow: 1px 1px #426676;
    font-size: 0.8rem;
    vertical-align: middle
  }
}
@import "../scss/conditiongraph.scss";
@import "../scss/comparing.scss";

:deep(div.v-list-item__content){
  display:flex;
}
:deep(#mainTabArea button ){
  font-size: 1.1rem;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  line-height:1;
  width: auto;
  background-color:#fbfbfbee;
  box-shadow: 0px -1px 3px #ddd inset;
  &.v-btn.text-primary {
    color: var(--key-color) !important;
    background-color: #dcf1f8 !important;
    border-right-color: #ddd !important;
    box-shadow:none;
  }
  span div.v-tab__slider {
    color:var(--key-color);
    height: 3px;
  }
}
.careplan {
  &__container {
    flex-wrap: nowrap;
  }

  &__timeline {
    border-radius: 0 5px 5px 0;
    border-left: 1px solid $color_gray_d !important;
    width: 160px;

    &--xs {
      width: 160px;
    }

    &.close {
      width: 55px;

      .careplan__timeline-content {
        overflow-y: hidden;
      }
    }
    & {
      transition: $transition_width;
    }

    &-container {
      position: sticky;
      top: 0;
      max-height: 100vh;

      flex-wrap: nowrap;
      flex-direction: column;
    }

    &-content {
      overflow-y: auto;
      flex: auto;
      .v-list-item {
        &.primary--text {
          color: var(--key-color);
          background-color: #eee;
          div.v-list-item__content {
            div.v-list-item-title {
              font-weight: bold;
              font-size: 0.9rem;
            }
          }
        }
        div.v-list-item__content {
          div.v-list-item-title {
            font-weight: normal;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
.togglebutton__item {
  min-width: 100px !important;

  &--selected {
    background-color: $color_primary !important;
    color: white !important;
  }
}

.conditionlist {
  &__groupname {
    position: sticky;
    top: 0;
    z-index: 2;

    margin-top: -0.5rem;
    margin-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__table {
    width: 100%;
    margin-bottom: 3rem;
    border-collapse: collapse;
    tr:hover {
      th,
      td {
        background-color: $color_gray_f;
      }
    }

    th {
      text-align: left;
      padding: 0.5rem 1rem 0.5rem 0;
      width: 350px;
      color: $color_gray;
      border-bottom: 1px solid $color_gray_e;
    }

    td {
      color: $color_gray;
      padding: 0.5rem 1rem 0.5rem 0;
      border-bottom: 1px solid $color_gray_e;
    }

    &--small {
      display: block;

      tr {
        display: block;
      }

      th,
      td {
        display: block;
        max-width: 100%;
      }
      th {
        padding: 0.75rem 0 0 0;
        border-bottom: 0;
      }
      td {
        padding: 0 0 0.75rem 0;
      }
    }
  }
}


:deep(.v-expansion-panel-title ){
    background-color: var(--key-color);
    color: #fff;
    font-size: 1.6rem !important;
    font-weight: bold;
}

:deep(.mdi-chevron-down::before ){
    color: #fff;
}

.v-application .title {
    font-size: 1.4rem;
    font-weight: bold;
}

:deep(hr.page-break ){
  visibility:hidden;
  padding:0;
  margin:0;
  height:0;
}
:deep(table.conditionlist__table tr ){
  th,td,span,
    mark{
    font-size: 1.3rem;
  }
  &.points_text{
    border-top: 1px solid #ccc;
    background: #eee;
    th,
    td span {
      color: #0B5882;
    }
  }
  &.alert {
    background-color: #FEDAEF;
    th,
    td span {
      color: #AE0900;
    }
  }
  &.height th,
  &.height td,
  &.weight th,
  &.weight td,
  &.__bmi th,
  &.__bmi td span,
  &.other th{
    font-weight:normal;
    font-size: 1.2rem;
  }
  &.height th,
  &.weight th,
  &.__bmi th{
    padding-left:3rem;
  }
  &.height th,
  &.height td,
  &.weight th,
  &.weight td,
  &.other th,
  &.other td {
    border-bottom:0;
  }
}

:deep(.careplan__container .v-tab){
  font-weight:bold;
  font-size: 1rem !important;
}

:deep(div#judgement ){
  /* 判定エリア専用 */
  .v-expansion-panel-content__wrap {
    padding:1.3rem;
    .row .col {
      padding:0.3rem !important;
      mark {
        white-space: nowrap;
        box-shadow: 1px 1px 2px #777;
      }
    }
  }
}
:deep(table.conditionlist__table tr ){
  th.indent{
    padding-left: 3rem !important;
  }
  th.indent:not(.last),
  td.indent:not(.last) {
    border-bottom: none !important;
  }
  th.life_title,
  td.life_title{
    border-bottom: none !important;
  }
  td{
    padding: 1rem !important;
    mark {
      white-space: nowrap;
      box-shadow: 1px 1px 2px #777;
      font-size: 1rem;
    }
  }
}
:deep(div.row.no-gutters>div.col>div.row.life ){
  margin: 0 !important;
  table {
    margin-bottom: 0;
  }
  div.col.col-12 {
    padding: 0;
    margin: 1em 0;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  div.col>h3.conditionlist__groupname {
    background: #218FD3 !important;
    color: #fff;
    font-size: 1.25rem !important;
    font-weight: bold;
    display: block;
    padding: 16px 24px;
    border-radius: 5px 5px 0 0;
  }
}
:deep(.v-expansion-panel-content__wrap){
  /* 資料のダウンロードエリア専用 */
  #result .col span{
    font-size:1.3rem;
  }

  div.row {
    margin: 0 !important;
    div{
      font-weight:bold;
    }
  }
  & {
    padding:1rem 1.5rem;
  }

  h3{
    background: $color_section_bg;
    padding: 1rem 1.6rem 1rem 1.5rem !important;
    border: 0;
    margin:1rem 0 0 0 !important;
    font-size:1.3rem;
  }
  h3.emphasis {
    font-size: 1.6rem;
    color: #212a44 !important;
    border-left: 6px solid $color_primary !important;
    background: #f4f4f4 !important;
  }
  ul{
    margin-left: 1rem;
    li {
      list-style: none;
      &.first {
        color: #333;
        font-weight:bold;
      }

      &.second {
        margin-top:1em;

          border: 1px solid #ccc;
          padding:1em;
          border-radius: 6px;

        p,ol {
          width:96%;
        }
        b {
          background-color: #777;
          padding: 0.3em 0.5em;
          display: block;
          color: #fff;
          border-radius: 6px;
          text-align: center;
          position: absolute;
        }
        ol {
            display: block;
            background: #eee;
            padding: 1em 0.3em 0.6em 0.6em;
            border-radius: 6px;
        }
      }
    }
  }
  h4 {
    mark {
      border:1px solid #826804;
    }
  }
  h5 {
    font-size: 1.4rem;
    position: relative;
    padding: 1.5rem 2rem 1.5rem 150px;
    min-height: 170px;
    border-radius: 10px;
    background: #FFECE6;
    border: 4px solid #FFDED3;
    margin-bottom: 1.2rem !important;
  }

  h5:before {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 0;
    height: 0;
    transform: scale(0.4);
    content: url(../assets/care1.png);
  }

  hr {
    border-top:1px solid $color_section_border;
    margin-top:1rem;
    margin-bottom:2rem;
  }
  div div {
    font-size: 1.3rem;
  }

  div div.v-col{
    padding:0 !important;
  }

  div div strong {
    font-size: 1.3rem;
    font-weight: bold;
    color: #b8545d;
  }

  .v-row .v-col section {
    border: 1px solid $color_section_border;
    border-radius: 0.3em;
    background-color: $color_section_bg;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    padding: 1.1rem ;
    margin-bottom: 1.2rem !important;
  }
  .v-row .v-col section ul li{
    font-size: 1.3rem;
  }
}
div.analyse h3.conditionlist__groupname{
  border-bottom: 2px solid #999;
  padding-bottom:0 !important;
  margin-bottom: 1rem;
}

@media print {

  body {
      width:auto !important;
  }
  /* 印刷時は折りたたんでいても強制展開 */
  :deep(.v-expansion-panel-text ){
    display:block !important;
  }
  /* 基本生活/身体精神選択時の印刷設定 */
  div.careplan.pa-2>div.v-sheet>div.v-container {
    div.v-row.careplan__container{
      div.v-col>div.v-row>div.v-col>div.v-row {
        break-inside: auto;
        h3.conditionlist__groupname {
          margin-top:0;
          margin-bottom: 0.8rem;
          font-size:1.4rem;
          border-left: 6px solid var(--key-color) !important;
          padding :0.3rem 0 0.3rem 0.8rem;
        }
        table.conditionlist__table {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom: 0;
          tr {
            width: 50%;
            display: flex;
            th {
              padding: 0.5rem 1rem 0.5rem 0.5rem;
              width: 50%;
              color: #000;
              border: 2px solid #ddd;
              border-radius: 6px;
              margin-bottom: 0.4rem;
              font-size:1.2rem;
              font-weight: bold;
            }
            td {
              color: #000;
              padding: 0.5rem 0 0.5rem 0.5rem;
              width: 50%;
              border-bottom: 0;
              font-size:1.2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  #checklist-edit-btn-outer {
    text-align: right !important;
    padding-right: 0.5rem !important;
  }
}
/* ----------------------スマホ向け設定 ---------------------- */
@media screen and (max-width: 600px) {
  :deep(.v-btn ){
    letter-spacing: 0 !important;
  }
  :deep(table.conditionlist__table){
  border-bottom: 1px solid #bbb;

  tr:not(.ew-memo):not(.opt-ew-memo) {
    border-left: 0;
    border-top: 0;
    th {
      background-color: #fafafa;
      border-right: 0;
      border-bottom: 1px solid #bbb;
      text-shadow: 1px 1px  #fff;
      color: #000;
      padding:10px 0!important;;
    }
    th,td{
      padding-left: 1rem!important;
      font-size: 1rem;
    }
    td{
      border-right: 0;
      border-bottom: 0;
    }
  }
} 
  #checklist-edit-btn-outer {
    text-align: left !important;
    padding-right: 0 !important;
  }
  #checklist-create-btn {
    padding: 0 0.25rem !important;
  }
  :deep(div.v-expansion-panel div.v-expansion-panel-text div.v-expansion-panel-text__wrapper ){
    padding: 0 8px 8px 8px !important;
  }
  h3.conditionlist__groupname {
    margin-top: 1rem !important;
  }
  .v-expansion-panel-content__wrap{ 
    ul li {
      padding-left: 1em;
    }
    ul, 
    h4 {
      margin-left: 0 !important;
    }
  }
  .v-application ul, .v-application ol {
    padding-left: 0 !important;
  }
  /* スマホ表示時のケアプラン「閲覧モード」の「タブメニュー（基本生活～比較）」 の各設定*/
  :deep(div.v-slide-group__content){
    display: block;
    div.v-tab{
      font-size: 1rem !important;
      line-height: 1rem;
      min-width: 70px;
      padding:0 4px;
      height:50px;
      display: table-cell;
      vertical-align: middle;
      span{
        font-size: 1rem !important;
        display: inline-block;
      }
    }
  }

  /* スマホ表示時のケアプラン「閲覧モード」の「基本生活」「身体精神」「分析」 のテーブル設定*/
  :deep(table.conditionlist__table ){
    margin-bottom: 1rem;
    border:1px solid #fff;
    box-shadow: none;
    & tr {
        background-color: #fff;
      th {
        border-left: 3px solid $color_primary;
        border-right-color: #ffff;
        padding-top:0 !important;
        margin-top:0.8rem;
        padding-left:0.8rem;
        font-size: 1.1rem;
      }
      td {
        font-size: 1rem;
        padding-left:1rem;
        padding-top:0.5rem;
      }
    }
  }

  /* スマホ表示時のケアプラン「閲覧モード」の「分析」 の各設定*/
  :deep(div.analyse ){
    padding-left:8px !important;
    padding-right:8px !important;
    button.v-expansion-panel-title {
      padding: 0 12px;
      div.title {
        font-size: 1rem !important;
        line-height: 1.1rem !important;
      }
    }
    /* AS/SS機能 */
    .v-expansion-panel-text__wrapper div div {
      font-size: 1rem !important;
      strong {
        font-size:1rem !important;
      }
      h3 {
        font-size: 1.1rem !important;
      }
    }
    section {
      padding: 1rem;
      h4 {
        font-size: 1.1rem;
      }
      ul {
        padding-left: 18px;
      }
      ul li{
        font-size: 1rem !important;
      }
      ul li b {
        font-size: 1rem !important;
      }
    }
    /* けあワンのワンポイントエリア */
    h5 {
      font-size: 1rem !important;
      padding:0.8rem 0.5rem 0.8rem 100px;
      min-height: 140px;
    }
    /* けあワンの画像サイズ、位置設定 */
    h5:before {
      transform: scale(0.25);
      top: 12px;
      left: 5px;
    }
  }
  /* 比較:スマホ表示時の余白調整 */
  div.v-container--fluid>div.v-row>div.v-col {
    div.v-row.v-row--no-gutters>div.pa-6{
      div.v-row {
        margin:0;
      }
    }
  }
}
</style>

<style lang="scss">
.comparing-selected {
  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.comparing-chart__item {
  canvas {
    @media print {
      margin: 0 auto;
    }
  }
}

section ul li b {
  font-size: 1.3rem;
  color:#b8545d;
}

/* -- printout stylesheet --------------------------------------------------- */
@media print {
  header {
    display:none !important;
  }
  footer {
    display:none !important;
  }
  .wrap_footer_ai_ver {
    display:none !important;
  }
  .v-application--wrap {
    min-height: auto;
  }
  .tooltip {
    visibility:hidden !important;
  }
  .hide--overflow {
    overflow: hidden;
  }
  h2,
  .v-application h2>span.text-subtitle-1 {
    font-size: 1.4rem !important;
    font-weight: bold;
  }
  .v-main{
    padding: 0 !important;
  }
  .v-application .elevation-5 {
    -webkit-box-shadow:none !important;
    box-shadow:none !important;
    border: none !important;
  }
  .v-application .pa-2 {
      padding: 0 !important;
  }
  .v-application .pa-6 {
    padding: 0 !important;
  }

  div.v-container{
    padding: 0!important;
  }

  .page-break {
    page-break-after: always;
  }

  .v-dialog__content {
    display: none !important;
  }

  /* 「分析」画面の印刷設定 */
  body div.analyse {
    * {
      font-size: 20px !important;
      box-shadow: none;
    }
    .text-h6 {
      font-weight: bold;
    }
    .v-expansion-panel-title__icon{
      display:none;
    }

    hr {
      visibility:hidden;
      padding:0 !important;
      margin:0 !important;
      height:0 !important;
      break-after: auto;
    }
    hr.my-6 {
      page-break-after: avoid !important;
    }
    h4 {
      margin-bottom: 4px !important;
      font-size:18px !important;
    }

    div.v-col{
      padding: 4px;
    }

    div.v-col-12{
      padding: 12px 0 0 0;
    }
    
    .title{
      font-size:20px !important;
      font-weight: bold;
      padding:0 4px !important;
    }

    .v-expansion-panels {
      display:block !important;
    }

    .v-expansion-panel::before {
      -webkit-box-shadow:none !important;
      box-shadow:none !important;
      border: none !important;
    }
    .v-expansion-panel-title {
      color:#000!important;
      font-weight:bold;
      background-color: inherit !important;
      border:2px solid #333;
      margin:0!important;
      padding:4px 6px!important;
    }

    .v-expansion-panel {
      margin-top: 8px !important;
    }
    .v-expansion-panel-text {
      font-size:20px !important;
    }
    .v-expansion-panel-text__wrapper {
      padding: 0 !important;
      margin-bottom: 0 !important;
      h5 {
        margin-top: 0.6rem !important;
        margin-bottom: 0 !important;
      }
      .v-row .v-col section {
        margin-top: 8px !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        padding:8px 10px 14px 10px !important;
        margin-bottom: 10px !important;
      }
      p.analysis_text {
        padding: 1rem 220px 0 1rem;
      }
    }
    .v-expansion-panel > .v-expansion-panel-title {
      min-height: 40px;
    }
    .v-expansion-panel-text__wrapper h3 {
      margin: 0 !important;
      background: none !important;
    }
    .v-expansion-panel-text__wrapper h3.emphasis {
      margin: 0.8rem 0 !important;
    }
    div.v-row div.v-col div h3 {
      font-size:20px !important;
      padding: 6px !important;
    }
    ul, ol {
      padding-left: 1.5rem !important;
    }
    li {
      padding-left: 0;
      margin-top: 8px;
      font-size:18px !important;
      line-height: 18px;
    }
    .text-subtitle-1 {
      font-size: 20px !important;
    }
    .v-row {
      margin:0 !important;
    }
    .footer_ai_ver {
      display: none;
    }
    span.text-caption {
      display: none;
    }
  }
  /* 「比較」画面の印刷設定 */
  div.care-plan-edit.pa-2,
  div.comparing_plans {
    &.v-col.v-col-12,
    &>div.v-container.pa-0.v-container--fluid>div.v-row>div.v-col.v-col-12>div.mb-6.v-sheet.elevation-5{
      padding:8px !important;
      div.mb-6.v-sheet>div.v-container,
      div.v-container.pa-6.v-container--fluid{
        padding: 0 !important;
        &>div.v-row>div.pa-0>div.v-row:nth-of-type(1) {
          margin: 0;
        }
        &>div.v-row>div.pa-0>div.v-row.comparing-selected {
          margin:0 !important;
          &>div {
            display:block !important;
            margin: 0 0 10px 0;
            min-width:100%;
            padding:10px !important;
            background-color: #FCFCFC;
            border:1px solid #ddd;
            border-radius: 6px;
          }
        }
      }
    }
    div.v-row.comparing-chart.justify-center {
      display:block;
      .comparing-chart__item {
        margin: 0;
        padding:0;
        max-width: 90%;
      }
    }
  }
}
</style>