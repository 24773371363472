<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="290px"
    :open-delay="openDelay"
    :disabled="disabled"
  >
    <template #activator="{props}">
      <v-text-field
        :id="id"
        v-model="date"
        :class="{'init_date': !modelValue}"
        v-bind="props"
        clearable
        :label="label"
        hint="YYYY-MM-DD"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="appendIcon"
        readonly
        :rules="rules"
        :disabled="disabled"
        class="my-0"
        :style="styles"
        @click:clear="clearDate"
      />
    </template>
    <v-date-picker
      v-model="dateForPicker"
      title="日付を選択してください"
      :min="min"
      :max="max"
      :disabled="disabled"
      @update:model-value="save"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/ja";
import config from "../config";
dayjs.locale(config.locale);

export default {
  name: "DateInput",
  props: {
    id: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: [Date, String],
      default: undefined
    },
    defaultDate: {
      type: [Date, String],
      default: '1940-01-01'
    },
    styles: {
      type: Object,
      default: undefined
    },
    preventEmitOnUnmount: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: undefined
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    },
    locale: {
      type: String,
      default: undefined
    },
    dense: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openDelay: {
      type: Number,
      default: 500
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      menu: false,
      date: this.modelValue ? dayjs(this.modelValue).format('YYYY-MM-DD') : '',
      currentYear : new Date().getFullYear(), // 現在の年を取得
      dateForPicker: this.defaultDate, // 1940年1月1日をデフォルト値として設定
    };
  },
  watch: {
    modelValue: {
      handler(val) {
        if (val) {
          this.date = dayjs(val).format('YYYY-MM-DD');
          // 有効な日付の場合datepickerと共有する
          if (dayjs(this.date, 'YYYY-MM-DD').isValid()) {
            this.dateForPicker = dayjs(val).format('YYYY-MM-DD');
          } else {
            this.dateForPicker = dayjs('1940/1/1 00:00:00').format('YYYY-MM-DD');
          }
        } else {
          this.date = '';
          this.dateForPicker = dayjs().format('YYYY-MM-DD'); // 現在の日付にリセット
        }
      },
      immediate: true
    },
    menu(val) {
      if (val) {
        if (!this.date) {
          this.dateForPicker = dayjs().format('YYYY-MM-DD'); // 現在の日付にリセット
        } else {
          this.dateForPicker = dayjs(this.date).format('YYYY-MM-DD');
        }

        // 既にintervalが存在していない場合にのみセットする
        if (!this.interval) {
          this.interval = setInterval(() => {
            const yearButtons = document.querySelectorAll('.v-date-picker-years .v-btn');
            let reachedCurrentYear = false;

            yearButtons.forEach(button => {
              const yearMatch = button.textContent.trim().match(/\d+/);
              if (yearMatch) {
                const year = yearMatch[0];
                const wareki = this.year2Wareki(year);
                if (wareki && !button.textContent.includes(wareki)) {
                  button.textContent = `${year}(${wareki})`;
                }

                const currentYear = new Date().getFullYear().toString();
                if (year === currentYear) {
                  reachedCurrentYear = true;
                }
              }
            });

            // 現在の年に達したらintervalを停止
            if (reachedCurrentYear) {
              clearInterval(this.interval);
              this.interval = null; // intervalをnullに戻して再び動作可能にする
            }
          }, 500);
        }
      } else {
        // 年選択画面が閉じられたときにintervalを停止
        clearInterval(this.interval);
        this.interval = null; // intervalをリセット
      }
    },
    date(val) {
      if (val === '' || !dayjs(val, 'YYYY-MM-DD').isValid()) {
        this.$emit("update:modelValue", null);
      } else {
        this.$emit("update:modelValue", val);
      }
    }
  },
  unmounted() {
    if (this.modelValue && !this.preventEmitOnUnmount) {
      this.$emit("update:modelValue", null);
    }
  },
  mounted() {
    if (!this.modelValue) {
      this.dateForPicker = this.defaultDate; // 初期値を1940-01-01に設定
    } else {
      this.dateForPicker = dayjs(this.modelValue).format('YYYY-MM-DD');
    }
    this.date = this.dateForPicker; // 同期させる

    this.$nextTick(() => {
      this.interval = setInterval(() => {
        const yearButtons = document.querySelectorAll('.v-date-picker-years .v-btn');
        let reachedCurrentYear = false; // 現在の年に達したかどうかのフラグ

        yearButtons.forEach(button => {
          const yearMatch = button.textContent.trim().match(/\d+/);
          if (yearMatch) {
            const year = yearMatch[0]; // 年だけを抽出
            const wareki = this.year2Wareki(year);
            if (wareki && !button.textContent.includes(wareki)) {
              button.textContent = `${year}(${wareki})`;
            }

            const currentYear = new Date().getFullYear().toString();
            if (year === currentYear) {
              reachedCurrentYear = true;
            }
          }
        });

        // 現在の年に達した場合、ループを停止
        if (reachedCurrentYear) {
          clearInterval(this.interval);
        }
      }, 500); // 500ミリ秒ごとに更新
    });
  },

  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    clearDate() {
      this.date = null;
      this.dateForPicker = dayjs().format('YYYY-MM-DD'); // 現在の日付にリセット
    },
    save(date) {
      this.date = date;
      this.menu = false;
    },
    year2Wareki(year) {
      console.log("year2Wareki::::", year);
      year = parseInt(year, 10);
      const eras = [
        { start: 2019, name: 'R', prevYear: 31, prevName: 'H' }, // 令和、平成31年から開始
        { start: 1989, name: 'H', prevYear: 64, prevName: 'S' }, // 平成、昭和64年から開始
        { start: 1926, name: 'S', prevYear: 15, prevName: 'T' }, // 昭和、大正15年から開始
        { start: 1912, name: 'T', prevYear: 45, prevName: 'M' }, // 大正、明治45年から開始
        { start: 1868, name: 'M', prevYear: null, prevName: null } // 明治
      ];

      for (let i = 0; i < eras.length; i++) {
        const era = eras[i];
        if (year == era.start) {
          // 切り替わりの年
          if (era.prevName && era.prevYear) {
            return `${era.prevName}${era.prevYear}/${era.name}1`;
          } else {
            return `${era.name}1`;
          }
        } else if (year > era.start) {
          let eraYear = year - era.start + 1;
          return `${era.name}${eraYear}`;
        }
      }

      // 元号の開始以前の年
      return year;
    },
  },
}
</script>
<style scoped lang="scss">
.init_date :deep(div.v-input__control input) {
  color: #F6F6F6;
}
.v-date-picker {
  min-width: 550px !important; /* 幅を広げる */
}
:deep(.v-date-picker-controls__mode-btn) {
  width: 5rem;
  span i::after {
    content: "年選択";
    font-size: 0.8rem;
    font-weight: bold;
  }
}
:deep(.v-date-picker--year .v-date-picker-controls .v-date-picker-controls__mode-btn) {
  transform: rotate(0deg) !important;
}
</style>
