<template>
  <div
    v-if="selectedCheckList"
    class="care-plan-edit pa-2"
  >
    <v-container
      class="pa-0"
      fluid
    >
      <v-row no-gutters>
        <v-col
          id="care-plan-guest-name"
          class="py-2"
          justify="center"
        >
          <v-skeleton-loader
            v-if="isGuestsLoading || !selectedGuest"
            type="heading"
          />
          <h2
            v-else
            id="guest-name"
            style="display:block; line-height: 1.2; float:left;"
          >
            <b style="vertical-align: middle; padding-right:10px">
              {{ selectedGuest.lastName }} {{ selectedGuest.firstName?selectedGuest.firstName+"様":"" }}</b><span
              v-if="carePlanDtm"
              class="mr-1 care-plan-dt"
            >{{ carePlanDtm }}のチェックリスト</span>
          </h2>
        </v-col>
        <v-col
          class="py-2 hide--print"
          cols="4"
          style="text-align: right !important;"
        >
          <!-- CHAT_HELP -->
          <ew-chat-help v-tooltip="`チャット形式でAIに質問できます。`" />
          <v-btn
            id="save-end-btn"
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating || !selectedGuest"
            @click="saveCheckList"
          >
            <v-icon start>
              mdi-file-document-edit-outline
            </v-icon>保存/編集終了
            <v-tooltip
              activator="parent"
              location="top"
            >
              編集内容を保存して参照画面へ遷移します。
            </v-tooltip>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="pa-0 mb-6 steptab hide--print steptab--sticky">
      <v-row justify="center">
        <!--
        <ew-step-tab
          id="tab-0"
          :selected="$route.params.typeName==='life'"
          :disabled="false"
          :checked="false"
          @click="selectStep(0)"
        >
          <div class="steptab__content">
            <span>日常生活</span>
            <span>調査票</span>
          </div>
          <template #message>
            <a
              style="text-decoration:none;cursor: pointer;"
              @click="selectStep(0, '#reqindex')"
            >
              <v-chip
                v-if="status1.max > 0 && status1.val < status1.max"
                id="num-of-required-fields-0"
                size="small"
                color="secondary"
                class="chip--required px-2"
                style="cursor: pointer;"
              >
                {{ `残り ${status1.max - status1.val}項目` }}
              </v-chip>
              <v-tooltip
                activator="parent"
                location="bottom"
              >
                クリックすると必須未入力項目にジャンプします。
              </v-tooltip>
            </a>
          </template>
        </ew-step-tab>
        -->
        <ew-step-tab
          id="tab-1"
          :selected="$route.params.typeName==='check'"
          :disabled="false"
          :checked="status2.isCompleted"
          @click="selectStep(0)"
        >
          <div class="steptab__content">
            <span>チェック</span>
            <span>リスト</span>
          </div>
          <template #message>
            <a
              style="text-decoration:none;cursor: pointer;"
              @click="selectStep(0, '#reqindex')"
            >
              <v-chip
                v-if="status2.max > 0 && status2.val < status2.max"
                id="num-of-required-fields-1"
                size="small"
                color="secondary"
                class="chip--required px-2"
                style="cursor: pointer;"
              >
                {{ (display.xs.value && !display.sm.value) ? `残 ${status2.max - status2.val}` : `残り ${status2.max - status2.val}項目` }}
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  クリックすると必須未入力項目にジャンプします。
                </v-tooltip>
              </v-chip>
            </a>
          </template>
        </ew-step-tab>
        <ew-step-tab
          id="tab-2"
          :selected="$route.params.typeName == 'feedback'"
          :disabled="false"
          :checked="status3.isCompleted"
          @click="selectStep(1)"
        >
          <div class="steptab__content">
            <span>フィード</span>
            <span>バック</span>
          </div>
          <template #message>
            <a
              style="text-decoration:none;cursor: pointer;"
              @click="selectStep(1, '#reqindex')"
            >
              <v-chip
                v-if="status3.max > 0 && status3.val < status3.max"
                id="num-of-required-fields-3"
                size="small"
                color="secondary"
                class="chip--required px-2"
                style="cursor: pointer;"
              >
                {{ (display.xs.value && !display.sm.value) ? `残 ${status3.max - status3.val}` : `残り ${status3.max - status3.val}項目` }}
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  クリックすると必須未入力項目にジャンプします。
                </v-tooltip>
              </v-chip>
            </a>
          </template>
        </ew-step-tab>
        <ew-step-tab
          id="tab-3"
          :selected="$route.params.typeName == 'interview'"
          :checked="status4.isCompleted"
          @click="selectStep(2)"
        >
          <div class="steptab__content">
            <span>個別</span>
            <span>面談</span>
          </div>
          <template #message>
            <a
              style="text-decoration:none;cursor: pointer;"
              @click="selectStep(2, '#reqindex')"
            >
              <v-chip
                v-if="status4.max > 0 && status4.val < status4.max"
                id="num-of-required-fields-3"
                size="small"
                color="secondary"
                class="chip--required px-2"
                style="cursor: pointer;"
              >
                {{ (display.xs.value && !display.sm.value)? `残 ${status4.max - status4.val}` : `残り ${status4.max - status4.val}項目` }}
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  クリックすると必須未入力項目にジャンプします。
                </v-tooltip>
              </v-chip>
            </a>
          </template>
        </ew-step-tab>
      </v-row>
    </v-container>

    <!-- 日常生活調査票（/condition-1のみ表示） -- >
    <v-container
      v-if="$route.params.typeName === 'life'"
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-sheet
            elevation="5"
            class="mb-6"
          >
            <v-container
              class="pa-6"
              fluid
            >
              <v-row no-gutters>
                <v-col
                  id="life"
                  cols="12"
                >
                  <v-expansion-panels
                    v-model="customPanel_1"
                    multiple
                  >
                    <v-expansion-panel
                      v-for="(group, g) in customLife"
                      :key="g"
                    >
                      <v-expansion-panel-title>
                        <div :id="`num-of-blank-fields-${g}`">
                          <div class="text-h6">
                            {{ group.name }}
                          </div>
                          <div
                            v-if="$_.inBlank(group.items, { required: true }).length > 0"
                            class="text-red-lighten-4"
                          >
                            未入力：{{ $_.inBlank(group.items, { required: true }).length }}件
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-row>
                          <v-col
                            v-for="(item, i) in group.items"
                            v-show="item.key !== '__filter'"
                            :key="`customLife_${i}`"
                            cols="12"
                            class="mt-1"
                            :class="item.type !='title' && (group.items.length > (i+1)) ? 'life_edit':item.type ==='title'?'life_title':''"
                          >
                            <! -- chip-groupによる複数選択 -- >
                            <v-sheet
                              v-if="item.type==='chip-group'"
                              :class="item.classes ? item.classes : 'normal'"
                            >
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || (item.key === 'sex' && item.value === 0) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4
                                v-if="!$_.isUndefined(item.title)"
                                :id="item.key + '_' + i"
                              >
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) || (item.key === 'sex' && item.value === 0) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  チェックリスト<! -- ({{ JSON.stringify(item.value) }}) -- >
                                </v-chip>
                              </h4>
                              <v-chip-group
                                :id="$_.kebabCase(item.key)"
                                v-model="item.value"
                                column
                                selected-class="chip__item--selected"
                                :mandatory="$_.isNil(item.options.mandatory) ? false : item.options.mandatory"
                                :multiple="$_.isNil(item.options.multiple) ? false : item.options.multiple"
                              >
                                <v-chip
                                  v-for="(elem, j) in item.options.items"
                                  :id="`${$_.kebabCase(item.key)}-${j}`"
                                  :key="j"
                                  :value="elem.value"
                                  class="chip__item"
                                  @click.stop="lifeChange($event, item, elem);nextScroll(item.key + '_' + i)"
                                >
                                  {{ elem.text }}
                                </v-chip>
                                <! -- @click="lifeChange($event, item, elem)" -- >
                                
                                <template v-if="!$_.isUndefined(item.etc) && !$_.isNil(item.etc['item'] = $_.find(checklists['life'], (x) => x.key === item.etc.key))">
                                  <span v-html="item.etc.item.title" />
                                  <v-text-field
                                    :id="$_.kebabCase(item.etc.item.key)"
                                    v-model="item.etc.item.value"
                                    :disabled="item.options.multiple ? (!$_.isNil(item.value) && -1 == item.value.indexOf(item.etc.id)) : (item.value != item.etc.id)"
                                    :type="item.etc.item.options.type"
                                    :clearable="item.etc.item.options.clearable"
                                    :label="item.etc.item.options.label"
                                    :placeholder="item.etc.item.options.placeholder"
                                    :prepend-icon="item.etc.item.options.prependIcon"
                                    :prepend-inner-icon="item.etc.item.options.prependInnerIcon"
                                    :counter="item.etc.item.options.counter"
                                    :suffix="item.etc.item.options.suffix"
                                    :min="item.etc.item.options.min"
                                    :max="item.etc.item.options.max"
                                    variant="outlined"
                                    density="compact"
                                    :maxlength="$_.isUndefined(item.etc.item.options.maxlength) ? '' : item.etc.item.options.maxlength"
                                  />
                                </template>
                              </v-chip-group>
                            </v-sheet>

                            <! -- text-fieldによる文字、数字入力 -- >
                            <v-sheet v-else-if="item.type==='text-field'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  チェックリスト
                                </v-chip>
                              </h4>

                              <v-col :class="['pa-0', (item.options.type === 'number') ? 'input--short' : 'input--long']">
                                <v-text-field
                                  v-if="item.type === 'number'"
                                  :id="$_.kebabCase(item.key)"
                                  v-model.number="item.value"
                                  :type="number"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  :max="item.options.max"
                                  :rules="[rule_number]"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                  @change="lifeChange($event, item)"
                                />
                                <v-text-field
                                  v-else
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                  @change="lifeChange($event, item)"
                                />
                              </v-col>
                            </v-sheet>

                            <v-sheet v-else-if="item.type==='date-date'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || item.value.length === 0 ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                                <v-chip
                                  v-if="!$_.isNil(item.value) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || item.value.length === 0 ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  チェックリスト
                                </v-chip>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <ew-date-input
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :prevent-emit-on-unmount="true"
                                  :default-date="item.options && 'defaultDate' in item.options?item.options.defaultDate:'2020-01-01'"
                                  append-icon="mdi-calendar-outline"
                                  locale="ja-JP"
                                  @input="lifeChange($event, item)"
                                />
                              </v-col>
                            </v-sheet>
                            <! -- textareaに文字、数字入力 -- >
                            <v-sheet v-else-if="item.type==='textarea'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  チェックリスト
                                </v-chip>
                              </h4>

                              <v-col class="pa-0">
                                <v-textarea
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :name="$_.kebabCase(item.key)"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                  @change="lifeChange($event, item)"
                                />
                              </v-col>
                            </v-sheet>

                            <v-sheet
                              v-else-if="item.type==='judge'"
                              v-show="item.key !== 'judge_i_iv'"
                              style="width:100%"
                            >
                              <v-col class="pa-0 points_text">
                                <b v-html="item.title" />：<span v-html="Judge(item)" />
                              </v-col>
                            </v-sheet>

                            <v-sheet v-else-if="item.type==='title'">
                              <v-col class="pa-0">
                                <span
                                  class="life_title"
                                  v-html="item.title"
                                />
                              </v-col>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="px-1"
      >
        <v-col
          cols="12"
          md="auto"
          class="pa-2"
        >
          <v-btn
            :id="`${$route.params.typeName}-next-btn`"
            block
            size="large"
            rounded
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="toPage('check')"
          >
            保存して次に進む
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    -->
    <!-- タブ1:チェックリスト -->
    <v-container
      v-if="$route.params.typeName === 'check'"
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-sheet
            elevation="5"
            class="mb-6"
            style="border-radius: 6px; border-top: 1px solid #eee;"
          >
            <v-container
              class="pa-6"
              fluid
            >
              <v-row no-gutters>
                <v-col
                  id="check"
                  cols="12"
                  class="pa-0"
                >
                  <v-expansion-panels
                    v-model="customPanel_2"
                    multiple
                  >
                    <v-expansion-panel :key="0">
                      <v-expansion-panel-title>
                        <div class="text-h6">
                          判定
                          <span v-html="JudgeDisp('judge_i_iv', checklists['check'])" />
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text id="judgement">
                        <v-row>
                          <v-col
                            cols="12"
                            class="mt-1"
                          >
                            <!-- chip-groupによる複数選択 -->
                            <v-sheet>
                              <v-row>
                                <v-col cols="4">
                                  ① 全般的な生活機能低下の恐れ
                                </v-col>
                                <v-col
                                  id="judge-1"
                                  cols="2"
                                  v-html="JudgeDisp('judge01_20', checklists['check'])"
                                />
                                <v-col cols="4">
                                  ⑤ 閉じこもりの恐れ
                                </v-col>
                                <v-col
                                  id="judge-5"
                                  cols="2"
                                  v-html="JudgeDisp('judge16_17', checklists['check'])"
                                />
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  ② 運動機能低下の恐れ
                                </v-col>
                                <v-col
                                  id="judge-2"
                                  cols="2"
                                  v-html="JudgeDisp('judge06_10', checklists['check'])"
                                />
                                <v-col cols="4">
                                  ⑥ 認知機能低下の恐れ
                                </v-col>
                                <v-col
                                  id="judge-6"
                                  cols="2"
                                  v-html="JudgeDisp('judge18_20', checklists['check'])"
                                />
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  ③ 低栄養の恐れ
                                </v-col>
                                <v-col
                                  id="judge-3"
                                  cols="2"
                                  v-html="JudgeDisp('judge11_12', checklists['check'])"
                                />
                                <v-col cols="4">
                                  ⑦ うつ傾向の可能性あり
                                </v-col>
                                <v-col
                                  id="judge-7"
                                  cols="2"
                                  v-html="JudgeDisp('judge21_25', checklists['check'])"
                                />
                              </v-row>
                              <v-row>
                                <v-col cols="4">
                                  ④ 口腔機能低下の恐れ
                                </v-col>
                                <v-col
                                  id="judge-4"
                                  cols="2"
                                  v-html="JudgeDisp('judge13_15', checklists['check'])"
                                />
                              </v-row>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel
                      v-for="(group, g2) in customCheck"
                      :key="g2+1"
                    >
                      <v-expansion-panel-title>
                        <div :id="`num-of-blank-fields-${g2}`">
                          <div class="text-h6">
                            {{ group.name }}
                          </div>
                          <div
                            v-if="$_.inBlank(group.items, { required: true }).length > 0"
                            class="text-red-lighten-4"
                          >
                            未入力：{{ $_.inBlank(group.items, { required: true }).length }}件
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-row>
                          <v-col
                            v-for="(item, i) in group.items"
                            :key="`customCheck_${i}`"
                            cols="12"
                            class="mt-1"
                          >
                            <!-- chip-groupによる複数選択 -->
                            <v-sheet v-if="item.type==='chip-group'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || item.value.length === 0 ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4
                                :id="item.key + '_' + i"
                                class="separator"
                              >
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || item.value.length === 0 ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip>
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="item.description"
                                  id="description_btn4"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-chip-group
                                :id="$_.kebabCase(item.key)"
                                v-model="item.value"
                                column
                                selected-class="chip__item--selected"
                                :mandatory="$_.isNil(item.options.mandatory) ? false : item.options.mandatory"
                                :multiple="$_.isNil(item.options.multiple) ? false : item.options.multiple"
                              >
                                <v-chip
                                  v-for="(elem, j) in item.options.items"
                                  :id="`${$_.kebabCase(item.key)}-${j}`"
                                  :key="j"
                                  :value="elem.value"
                                  class="chip__item"
                                  @click.stop="nextScroll(item.key + '_' + i)"
                                >
                                  {{ elem.text }}
                                </v-chip>
                                <template v-if="!$_.isUndefined(item.etc) && !$_.isNil(item.etc['item'] = $_.find(checklists['check'], (x) => x.key === item.etc.key))">
                                  <span v-html="item.etc.item.title" />
                                  <v-text-field
                                    :id="$_.kebabCase(item.etc.item.key)"
                                    v-model="item.etc.item.value"
                                    :disabled="item.value != item.etc.id"
                                    :type="item.etc.item.options.type"
                                    :clearable="item.etc.item.options.clearable"
                                    :label="item.etc.item.options.label"
                                    :placeholder="item.etc.item.options.placeholder"
                                    :prepend-icon="item.etc.item.options.prependIcon"
                                    :prepend-inner-icon="item.etc.item.options.prependInnerIcon"
                                    :counter="item.etc.item.options.counter"
                                    :suffix="item.etc.item.options.suffix"
                                    :min="item.etc.item.options.min"
                                    :max="item.etc.item.options.max"
                                    variant="outlined"
                                    density="compact"
                                    :maxlength="$_.isUndefined(item.etc.item.options.maxlength) ? '' : item.etc.item.options.maxlength"
                                  />
                                </template>
                              </v-chip-group>
                            </v-sheet>

                            <!-- text-fieldによる文字、数字入力 -->
                            <v-sheet v-else-if="item.type==='text-field'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip>
                                <span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  id="description_btn6"
                                  variant="text"
                                  size="small"
                                  class="hide--print"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>

                              <v-col class="pa-0 input--short">
                                <v-text-field
                                  v-if="item.options.type === 'number'"
                                  :id="$_.kebabCase(item.key)"
                                  v-model.number="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  :max="item.options.max"
                                  :rules="[value => validateInputNum(value, item.options.min, item.options.max)]"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                                <v-text-field
                                  v-else
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>

                            <v-sheet v-else-if="item.type==='date-date'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || item.value.length === 0 ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || item.value.length === 0 ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  id="description_btn7"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <ew-date-input
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :prevent-emit-on-unmount="true"
                                  :default-date="item.options && 'defaultDate' in item.options?item.options.defaultDate:'2020-01-01'"
                                  append-icon="mdi-calendar-outline"
                                  locale="ja-JP"
                                />
                              </v-col>
                            </v-sheet>
                            <!-- textarea による文字、数字入力 -->
                            <v-sheet v-else-if="item.type==='textarea'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  :id="`required__` + $_.kebabCase(item.key)"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  id="description_btn7"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>

                              <v-col class="pa-0">
                                <v-textarea
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :name="$_.kebabCase(item.key)"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>
                            <!-- bmi による自動計算 -->
                            <v-sheet v-else-if="item.type==='bmi'">
                              <v-col class="pa-0 points_text">
                                <b v-html="item.title" />：<span v-html="calcBMI(checklists['check'], 2)" />
                              </v-col>
                            </v-sheet>

                            <v-sheet
                              v-else-if="item.type==='judge'"
                              v-show="item.key !== 'judge_i_iv'"
                            >
                              <v-col class="pa-0 points_text">
                                <b v-html="item.title" />：
                                <template v-if="$_.includes(Judge(item), '該当あり')">
                                  <mark class="alert"><span v-html="Judge(item)" /></mark>
                                </template>
                                <template v-else>
                                  <span v-html="Judge(item)" />
                                </template>
                              </v-col>
                            </v-sheet>

                            <v-sheet v-else-if="item.type === 'title'">
                              <v-col class="pa-0">
                                <span
                                  class="life_title"
                                  v-html="item.title"
                                />
                              </v-col>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="px-1"
      >
        <v-col
          cols="12"
          md="auto"
          class="pa-2"
        >
          <v-btn
            :id="`${$route.params.typeName}-next-btn`"
            block
            size="large"
            rounded
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="toPage('feedback')"
          >
            保存して次に進む
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!-- タブ2:F/B -->
    <v-container
      v-if="$route.params.typeName==='feedback'"
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-sheet
            elevation="5"
            class="mb-6"
          >
            <v-container
              class="pa-6"
              fluid
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="pa-0"
                >
                  <v-expansion-panels
                    v-model="customPanel_3"
                    multiple
                  >
                    <v-expansion-panel
                      v-for="(group, g) in customFeedback"
                      :key="g"
                    >
                      <v-expansion-panel-title>
                        <div :id="`num-of-blank-fields-${g}`">
                          <div class="text-h6">
                            {{ group.name }}
                          </div>
                          <div
                            v-if="$_.inBlank(group.items).length > 0"
                            class="text-red-lighten-4"
                          >
                            未入力：{{ $_.inBlank(group.items).length }}件
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-row>
                          <v-col
                            v-for="(item, i) in group.items"
                            :key="i"
                            cols="12"
                            class="mt-1"
                          >
                            <!-- chip-groupによる複数選択 -->
                            <v-sheet v-if="item.type==='chip-group'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || (item.key === 'sex' && item.value === 0) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4 :id="item.key + '_' + i">
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || (item.key === 'sex' && item.value === 0) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-chip-group
                                :id="$_.kebabCase(item.key)"
                                v-model="item.value"
                                column
                                selected-class="chip__item--selected"
                                :mandatory="item.options.mandatory"
                                :multiple="item.options.multiple"
                              >
                                <v-chip
                                  v-for="(elem, j) in item.options.items"
                                  :id="`${$_.kebabCase(item.key)}-${j}`"
                                  :key="j"
                                  :value="elem.value"
                                  class="chip__item"
                                  @click.stop="nextScroll(item.key + '_' + i)"
                                >
                                  {{ elem.text }}
                                </v-chip>
                              </v-chip-group>
                            </v-sheet>
                            <!-- text-fieldによる文字、数字入力 -->
                            <v-sheet v-else-if="item.type === 'text-field'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <v-text-field
                                  v-if="item.options.type === 'number'"
                                  :id="$_.kebabCase(item.key)"
                                  v-model.number="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  :max="item.options.max"
                                  :rules="[value => validateInputNum(value, item.options.min, item.options.max)]"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                                <v-text-field
                                  v-else
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>
                            <v-sheet v-else-if="item.type==='date-date'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || item.value.length === 0 ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || item.value.length === 0 ? 'secondary' : 'grey-lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <ew-date-input
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :prevent-emit-on-unmount="true"
                                  :default-date="item.options && 'defaultDate' in item.options?item.options.defaultDate:'2020-01-01'"
                                  append-icon="mdi-calendar-outline"
                                  locale="ja-JP"
                                />
                              </v-col>
                            </v-sheet>
                            <!-- textarea による文字、数字入力 -->
                            <v-sheet v-else-if="item.type==='textarea'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0">
                                <v-textarea
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :name="$_.kebabCase(item.key)"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>
                            <v-sheet v-else-if="item.type==='title'">
                              <v-col class="pa-0">
                                <b v-html="item.title" />
                              </v-col>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="px-1 hide--print"
      >
        <v-col
          cols="12"
          md="auto"
          class="pa-2"
        >
          <v-btn
            :id="`${$route.params.typeName}-prev-btn`"
            block
            size="large"
            variant="text"
            rounded
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="toPage('check')"
          >
            保存して前に戻る
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="pa-2"
        >
          <v-btn
            :id="`${$route.params.typeName}-next-btn`"
            block
            size="large"
            rounded
            color="primary"
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="toPage('interview')"
          >
            保存して次に進む
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- タブ４：個別相談 -->
    <v-container
      v-if="$route.params.typeName==='interview'"
      class="pa-0"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-sheet
            elevation="5"
            class="mb-6"
          >
            <v-container
              class="pa-6"
              fluid
            >
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="pa-0"
                >
                  <v-expansion-panels
                    v-model="customPanel_4"
                    multiple
                  >
                    <v-expansion-panel
                      v-for="(group, g) in customInterview"
                      :key="g"
                    >
                      <v-expansion-panel-title>
                        <div :id="`num-of-blank-fields-${g}`">
                          <div class="text-h6">
                            {{ group.name }}
                          </div>
                          <div
                            v-if="$_.inBlank(group.items).length > 0"
                            class="text-red-lighten-4"
                          >
                            未入力：{{ $_.inBlank(group.items).length }}件
                          </div>
                        </div>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <v-row>
                          <v-col
                            v-for="(item, i) in group.items"
                            :key="i"
                            cols="12"
                            class="mt-1"
                          >
                            <!-- chip-groupによる複数選択 -->
                            <v-sheet v-if="item.type==='chip-group'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || (item.key === 'sex' && item.value === 0) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4 :id="item.key + '_' + i">
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || (item.key === 'sex' && item.value === 0) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-chip-group
                                :id="$_.kebabCase(item.key)"
                                v-model="item.value"
                                column
                                selected-class="chip__item--selected"
                                :mandatory="item.options.mandatory"
                                :multiple="item.options.multiple"
                              >
                                <v-chip
                                  v-for="(elem, j) in item.options.items"
                                  :id="`${$_.kebabCase(item.key)}-${j}`"
                                  :key="j"
                                  :value="elem.value"
                                  class="chip__item"
                                  @click.stop="nextScroll(item.key + '_' + i)"
                                >
                                  {{ elem.text }}
                                </v-chip>
                              </v-chip-group>
                            </v-sheet>
                            <!-- text-fieldによる文字、数字入力 -->
                            <v-sheet v-else-if="item.type==='text-field'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  class="hide--print"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <v-text-field
                                  v-if="item.type === 'number'"
                                  :id="$_.kebabCase(item.key)"
                                  v-model.number="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  :max="item.options.max"
                                  :rules="[value => validateInputNum(value, item.options.min, item.options.max)]"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                                <v-text-field
                                  v-else
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :type="item.options.type"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  :min="item.options.min"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>
                            <v-sheet v-else-if="item.type==='date-date'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || item.value.length === 0 ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || item.value.length === 0 ? 'secondary' : 'grey-lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0 input--short">
                                <ew-date-input
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :prevent-emit-on-unmount="true"
                                  :default-date="item.options && 'defaultDate' in item.options?item.options.defaultDate:'2020-01-01'"
                                  append-icon="mdi-calendar-outline"
                                  locale="ja-JP"
                                />
                              </v-col>
                            </v-sheet>
                            <!-- textarea による文字、数字入力 -->
                            <v-sheet v-else-if="item.type==='textarea'">
                              <span
                                v-if="!$_.isNil(item.required) && item.required"
                                :id="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'reqindex' : ''"
                                class="jmpofst"
                              />
                              <h4>
                                <v-chip
                                  v-if="!$_.isNil(item.required) && item.required"
                                  size="small"
                                  :color="$_.isNil(item.value) || $_.isEmpty(item.value) ? 'secondary' : 'grey lighten-1'"
                                  class="chip--required mx-2"
                                >
                                  必須
                                </v-chip><span v-html="item.title" />
                                <v-btn
                                  v-if="!$_.isNil(item.description) && item.description"
                                  variant="text"
                                  size="small"
                                  @click.stop="showDescriptionDialog(true, item.title, item.description)"
                                >
                                  <v-icon color="gray">
                                    mdi-help-circle
                                  </v-icon>
                                </v-btn>
                              </h4>
                              <v-col class="pa-0">
                                <v-textarea
                                  :id="$_.kebabCase(item.key)"
                                  v-model="item.value"
                                  :name="$_.kebabCase(item.key)"
                                  :clearable="item.options.clearable"
                                  :label="item.options.label"
                                  :placeholder="item.options.placeholder"
                                  :prepend-icon="item.options.prependIcon"
                                  :prepend-inner-icon="item.options.prependInnerIcon"
                                  :counter="item.options.counter"
                                  :suffix="item.options.suffix"
                                  variant="outlined"
                                  :maxlength="$_.isUndefined(item.options.maxlength) ? '' : item.options.maxlength"
                                />
                              </v-col>
                            </v-sheet>
                            <v-sheet v-else-if="item.type==='title'">
                              <v-col class="pa-0">
                                <b v-html="item.title" />
                              </v-col>
                            </v-sheet>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="px-1"
      >
        <v-col
          cols="12"
          md="auto"
          class="pa-2"
        >
          <v-btn
            :id="`${$route.params.typeName}-prev-btn`"
            block
            size="large"
            variant="text"
            rounded
            :loading="isUpdating"
            :disabled="isUpdating"
            @click="toPage('feedback')"
          >
            保存して前に戻る
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Description Dialog -->
    <v-dialog
      id="modalDescription"
      v-model="descriptionDialog.visible"
      persistent
      scrollable
      min-width="300px"
      max-width="80%"
    >
      <v-card>
        <v-card-title>
          <div v-html="descriptionDialog.title" />
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4 markdown">
          <div v-html="descriptionDialog.html" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-container class="pa-1 px-3 mr-0">
            <v-row
              no-gutters
              justify="end"
            >
              <v-col
                cols="12"
                md="3"
              >
                <v-btn
                  id="modal-description-close"
                  color="primary"
                  block
                  @click="showDescriptionDialog(false)"
                >
                  閉じる
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 編集モードから抜けるときの確認 -->
    <v-dialog
      id="modalConfirm"
      v-model="confirmDialog.visible"
      persistent
      width="500px"
    >
      <v-card>
        <v-card-title>保存確認</v-card-title>
        <v-card-text>保存していないデータがある場合、遷移するとデータが失われる可能性があります。</v-card-text>
        <v-card-actions>
          <v-container class="pa-3">
            <v-row
              no-gutters
              justify="end"
            >
              <v-col
                cols="12"
                md="4"
                class="pa-1"
              >
                <v-btn
                  id="save-cancel"
                  variant="text"
                  :disabled="isUpdating"
                  block
                  @click="confirmDialog.value = 1"
                >
                  キャンセル
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pa-1"
              >
                <v-btn
                  id="save-not-save"
                  variant="text"
                  :disabled="isUpdating"
                  block
                  @click="confirmDialog.value = 2"
                >
                  保存せず遷移
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pa-1"
              >
                <v-btn
                  id="save-save"
                  color="primary"
                  :disabled="isUpdating"
                  :loading="isUpdating"
                  block
                  @click="confirmDialog.value = 3"
                >
                  保存して遷移
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- AIモデル実行時に表示するダイアログ -->
    <v-dialog
      id="modal-create-aiplan"
      :model-value="isPredictFrailLoading"
      persistent
      width="300px"
    >
      <v-card
        color="info"
        dark
      >
        <v-card-text>
          <span>AIによるフレイル分析中・・・</span>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @namespace app.checklistedit
 */
import { mapGetters } from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

// dayjs設定
import "dayjs/locale/ja";
dayjs.locale(config.locale);
// 設定ファイル
import config from "../config";
// チェックリスト
import checklists from "../data/checklists";
// コンポーネントのロード
import EwStepTab from "../components/EwStepTab.vue";
import EwDateInput from "../components/EwDateInput.vue";
import EwChatHelp from "../components/EwChatHelp.vue";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import { useDisplay } from 'vuetify';
/**
 * ビューモデル
 * @class
 * @memberof app.checklistedit
 */
export default {
  name: "ChecklistEdit",
  components: {
    EwStepTab,
    EwDateInput,
    EwChatHelp,
  },
  /**
   * 遷移前の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.token) {
        return await vm.checkCheckListRoute.call(
          vm,
          _.pick(to.params, [
            "guestId",
            "checkListId",
            "typeName"
          ])
        );
      }
    });
  },
  /**
   * ページ内遷移の処理
   * @param {*} to 遷移先
   * @param {*} from 遷移元
   * @param {*} next 遷移許可
   */
  async beforeRouteUpdate(to, from, next) {
    // ユーザ確認
    if (_.isNil(this.activeUser) || _.isNil(this.activeUser.userId)) {
      // ログインダイアログ
      await this.$store.dispatch("openLoginDialog");
      next();
    } else {
      // 検証
      next(
        await this.checkCheckListRoute(
          _.pick(to.params, [
            "guestId",
            "checkListId",
            "typeName"
          ])
        )
      );
    }
  },
  setup() {
    const display = useDisplay();
    return {
      display
    };
  },
  data() {
    return {
      carePlanDtm: '',
      isGuestsLoading: false,
      isUpdating: false,
      isAiExed: false,
      typeNames: [
        "check",
        "feedback",
        "interview",
      ],
      checklists,
      descriptionDialog: {
        visible: false,
        title: null,
        html: null,
      },
      /*
      status1: {
        val: 0,
        max: 0,
        isCompleted: false,
      },
      */
      status2: {
        val: 0,
        max: 0,
        isCompleted: false,
      },
      status3: {
        val: 0,
        max: 0,
        isCompleted: false,
      },
      status4: {
        val: 0,
        max: 0,
        isCompleted: false,
      },
      // customPanel_1: [],
      customPanel_2: [],
      customPanel_3: [],
      customPanel_4: [],
      confirmDialog: {
        visible: false,
        value: null,
      },
      isPredictFrailLoading: false,
      openAiEnable: true,
      openAiComment2: `
ChatGPTの回答を表示するには上部にある[分析する]ボタンを押下してください`,
      openAiLoading: false,
      // asa = `M_AssessmentSupport_AnalysisComment` ・・・userSelectedItem
      // asc = `M_AssessmentSupport_Condition`       ・・・???
      // asd = `M_AssessmentSupport_Disease`         ・・・deteriorateItem
      // sc = `M_SupportContents`                    ・・・aiSelectedItem
      // gr = `T_GptResponses`                    ・・・basicTable
      markIds: {
        asa:[],
        asc:[],
        asd:[],
        sc:[],
        gr:[]
      },
      pathPrefix: 'guests',
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "activeUser",
      "activeGuests",
      "selectedGuest",
      "activeCheckLists",
      "selectedCheckList",
    ]),
    customCheck() {
      return this.customData('check');
    },
    customFeedback() {
      return this.customData('feedback');
    },
    customInterview() {
      return this.customData('interview');
    },
    /**
     * グループ化
     * @param {string} index インデックス
     * @returns {string} 値
     */
    customData: function() {
      return function(index) {
        let result = [];
        let group = null;
        let i = 0;
        _.forEach(this.customCheckList(index), (item) => {
          // 新規グループのチェック
          if (item.group !== group) {
            // グループ登録
            result.push({
              name: item.group,
              items: [],
              id: "inputGroup_" + i
            });
            i++
            group = item.group;
          }
          // アイテム登録（ポインタコピー）
          _.last(result).items.push(item);
        });
        return result;
      }
    },
    /**
     * BMI値計算
     * @param object check チェックリストデータ
     * @param int mode 0:表示(default), 2:編集
     */
    calcBMI: function() {
      return function(check, mode = 0) {
        let height = _.get(_.find(check, (x) => x.key === 'height'), "value");
        let check012 = _.find(check, (x) => x.key === 'check012');
        if (_.isEmpty(height) || height <= 0) {
          check012.value = null;
          return '';
        }
        height /= 100;
        let weight = _.get(_.find(check, (x) => x.key === 'weight'), "value");
        if (_.isEmpty(weight)) {
          check012.value = null;
          return '';
        }
        let bmi = weight / height / height;
        if (mode === 2) {
        //  let check012 = _.find(checklists, (x) => x.key === 'check012');
          if (_.isNaN(bmi)) {
            check012.value = null;
          } else {
            check012.value = (bmi < 18.5) ? 1 : 0;
          }
        }
        if (_.isNaN(bmi)) {
          return '';
        }
        // return bmi.toFixed(1) + "[kg/㎡] = " + weight.toString() + "[kg] ÷ " + height.toString() + "<sup>2</sup>[㎡]"
        let addition = '';
        if (18.5 <= bmi && bmi < 25) {
          addition = " (普通体重)";
        } else if (25.0 <= bmi && bmi < 30) {
          addition = " (肥満(1度))";
        } else if (30.0 <= bmi && bmi < 35) {
          addition = " (肥満(2度))";
        } else if (35.0 <= bmi && bmi < 40) {
          addition = " (肥満(3度))";
        } else if (40.0 <= bmi) {
          addition = " (肥満(4度))";
        }
        return bmi.toFixed(1) + " [kg/㎡] = 体重[kg] ÷ 身長<sup>2</sup>[㎡]" + addition;
      };
    },
    /**
     * チェック・再チェック判定
     * @param objerct item 対象項目
     * @param objerct check チェックオブジェクト
     */
    JudgeCheck: function() {
      return function(item, check) {
        if (item.key == 'check012') { // 栄養状態
          return this.JudgeDisp(item.key, check);
        }
        if (item.key == 'judge_i_iv') { // 生活機能全般 判定
          let keys = [
            ["judge01_20", "judge06_10", "judge11_12", "judge13_15"],
            ["judge16_17", "judge18_20", "judge21_25"],
          ];
          let exist = 0; // 該当なし
          let notexist = 0;
          for (let i = 0; i < keys.length; i++) {
            for (let key of keys[i]) {
              let val = _.get(_.find(check, (x) => x.key === key), 'value');
              if (2 === val) {
                exist++; // 該当ありかどうか
              } else if (1 === val) {
                notexist++;
              }
            }
          }
          if (exist) {
            item.value = 2;
          } else if (notexist) {
            item.value = 1;
          } else {
            item.value = 0;
          }
          return this.JudgeDisp(item.key, check);
        }

        // 判定
        let judges = {
          "judge01_20" : { // No.35 1〜20の点数
            keys: ["check001", "check002", "check003", "check004", "check005", "check006", "check007", "check008", "check009", "check010",
              "check011", "check012", "check013", "check014", "check015", "check016", "check017", "check018", "check019", "check020",],
            boundary: 10,
            suffix: ":10項目以上に該当あり）"
          },
          "judge06_10" : { // No.36 運動不足予防点数
            keys: ["check006", "check007", "check008", "check009", "check010",],
            boundary: 3,
            suffix: ":3項目以上に該当あり）",
          },
          "judge11_12" : { // No.37 栄養改善点数
            keys: ["check011", "check012",],
            boundary: 2,
            suffix: ":2項目に該当あり）",
          },
          "judge13_15" : { // No.38 口腔ケア予防点数
            keys: ["check013", "check014", "check015",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          },
          "judge16_17" : { // 閉じこもりの恐れ
            keys: ["check016", "check017",],
            boundary: 1,
            suffix: ":16に該当あり）",
          },
          "judge18_20" : { // 認知機能低下の恐れ
            keys: ["check018", "check019", "check020",],
            boundary: 1,
            suffix: ":1項目以上に該当あり）",
          },
          "judge21_25" : { // うつ傾向の可能性あり
            keys: ["check021", "check022", "check023", "check024", "check025",],
            boundary: 2,
            suffix: ":2項目以上に該当あり）",
          }
        };
        if (!_.isNil(judges[item.key])) {
          let judge = judges[item.key];
          let total = 0;
          if (item.key == "judge16_17") {
            let val = _.get(_.find(check, (x) => x.key === 'check016'), "value");
            total = !_.isNil(val) ? val : -1;
          } else {
            for (let key of judge.keys) {
              let value = _.get(_.find(check, (x) => x.key === key), "value");
              if (!_.isNil(value)) {
                total += value;
              } else {
                total = -1; // 未入力あり。
                break;
              }
            }
          }
          let result = "未入力あり";
          if (total === -1) {
            item.value = 0; // 未入力あり
          } else {
            item.value = (total >= judge.boundary) ? 2 : 1; // 1:該当なし
            result = _.toString(total);
            try {
              result += " " + item.options.suffix;
            } catch (err) {
              //
            }
            if (item.value == 2) { // 該当あり
              return "該当あり（" + result + judge.suffix;
            }
            return "該当なし（" + result + "）"
          }
          return result;
        }  
        return item.key;
      }
    },
    /**
     * 調査票・チェック判定
     * @param objerct item 対象項目
     */
    Judge: function() {
      return (item) => {
        return this.JudgeCheck(item, this.customCheckList('check'));
      }
    },
    /**
     * 該当・非該当表示
     * @param objerct val 値
     */
    JudgeDisp2: function() {
      return function(val) {
        let arr = [
          "<mark class='caution'>未入力あり</mark>", // 0
          "<mark class='normal'>非該当</mark>", // 1
          "<mark class='alert'>該　当</mark>", // 2
        ];
        return (arr[val] !== undefined) ? arr[val] : "-";
      };
    },
    /**
     * チェック判定表示
     * @param objerct key キー
     * @param objerct check チェックオブジェクト
     */
    JudgeDisp: function() {
      return function(key, check) {
        // 判定結果を表示する場合
        let val = _.get(_.find(check, (x) => x.key === key), "value", 0);
        let arr = [];
        switch (key) {
          case 'judge_i_iv':
            arr = [
              "未入力あり", // 0
              "<mark class='normal'>該当項目なし</mark>", // 1
              "<mark class='alert'>該当項目あり</mark>", // 2
            ];
            return (arr[val] !== undefined) ? arr[val] : "-";
          case 'check012': // 栄養状態
            arr = [
              "<mark class='normal'>BMIが 18.5以上です</mark>", // 0
              "<mark class='alert'>BMIが 18.5未満です</mark>", // 1
            ];
            return (arr[val] !== undefined) ? arr[val] : "未入力あり";
          default:
            return this.JudgeDisp2(val);
        }
      };
    },
    customCheckList: function() {
      return function(index) {
        return this.checklists[index];
      };
    },
  },
  watch: {
    /**
     * tokenの値が変化したときの処理
     * @param {String} val 新しい値
     * @param {String} old 古い値
     */
    async token(val, old) {
      if (_.isNil(old) && !_.isEmpty(val)) {
        await this.checkCheckListRoute(
          _.pick(this.$route.params, [
            "guestId",
            "checkListId",
            "typeName"
          ])
        );
      }
    },
    /**
     * チェックリストのリアクタブル処理
     */
    checklists: {
      handler(val) {
        // 件数計算とか
        let types = ["check"];
        for (let type of types) {
          // 必須項目数
          let _max = _(val[type])
            .filter((x) => {
              return (!_.isNil(x.required) && x.required === true);
            })
            .value();

          // 必須項目中、値が入力された項目数
          let _val = _(_max)
            .filter((x) => {
              if (x.type === "text-field" && x.options.type === "number") {
                // x.value = _.isEmpty(x.value) ? '' : `${x.value}`;
                x.value = `${x.value}`;
              }
              switch (x.type) {
                case "chip-group":
                  if (!_.isNil(x.options.multiple) && x.options.multiple) {
                    return !_.isNil(x.value) && !_.isEmpty(x.value);
                  } else {
                    return !_.isNil(x.value);
                  }
                case "date-date":
                  //return !_.isNil(x.value[0]) && !_.isNil(x.value[1]);
                  return !_.isNil(x.value) && x.value.length !== 0;
                case "text-field":
                  return !_.isEmpty(x.value); //!_.isNil(x.value) && !_.isEmpty(x.value);
                case "textarea":
                  return !_.isNil(x.value) || !_.isEmpty(x.value);
                case "title":
                  return true;
                default:
                  return !_.isNil(x.value);
              }
            })
            .value();

          switch (type) {
            /*
            case "life":
              this.status1.max = _max.length;
              this.status1.val = _val.length;
              // 全項目記入済か確認
              this.status1.isCompleted = _max.length === _val.length;
              break;
            */
            case "check":
              this.status2.max = _max.length;
              this.status2.val = _val.length;
              // 全項目記入済か確認
              this.status2.isCompleted = _max.length === _val.length;
              break;
            case "feedback":
              this.status3.max = _max.length;
              this.status3.val = _val.length;
              // 全項目記入済か確認
              this.status3.isCompleted = _max.length === _val.length;
              break;
            case "interview":
              this.status4.max = _max.length;
              this.status4.val = _val.length;
              // 全項目記入済か確認
              this.status4.isCompleted = _max.length === _val.length;
              break;
          }
        }
      },
      deep: true,
      immediate: true,
    },
    /*
    customLife: {
      handler(val) {
        this.customPanel_1 = _.range(val.length);
      },
      immediate: true,
    },
    */
    customCheck: {
      handler(val) {
        this.customPanel_2 = _.range(val.length + 1);
      },
      immediate: true,
    },
    customFeedback: {
      handler(val) {
        this.customPanel_3 = _.range(val.length + 1);
      },
      immediate: true,
    },
    customInterview: {
      handler(val) {
        this.customPanel_4 = _.range(val.length + 1);
      },
      immediate: true,
    },
    /**
     * チェックリスト選択時の処理
     */
    selectedCheckList: {
      handler(checkList) {
        if (checkList) { // 選択時にロードする
          this.loadChecklists();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.documentElement.style.setProperty('--key-color', config.system_key_color);
    this.pathPrefix = this.$route.path.substring(0, 9) == '/_guests/' ? '_guests' : 'guests';
  },
  methods: {
    /**
     * 数値の範囲チェック
     * @param {Event} event イベント
     * @param {Number} min 最小値
     * @param {Number} max 最大値
     */
     validateInputNum(value, min, max) {
      // 未入力（nullまたはundefined）は許容
      if (value === null || value === undefined || value === '') {
        value = true;
      // 数値型に変換
      } else if(!isNaN(value)) {
        value = Number(value);
      }
       // 0を含む全ての数値を範囲チェック
      if (value < min || value > max) {
        value = `${min}~${max}の範囲で入力してください`;
      } else {
        value = true;
      }
      return value;
    },
    /**
     * 必須チェック
     * @param {*} value
     * @returns {String} エラーメッセージ 
     */
    rule_required(value) {
      return !!value || "空欄にはできません。";
    },
    /**
     * 数値チェック
     * @param {*} value
     * ＠returns {String} エラーメッセージ
     */
    rule_number(value) {
      if (typeof value === "number") {
        return _.isNumber(value) && !_.isNaN(value) && value > 0 || "１以上の数値を半角文字で入力してください。";
      } else {
        let value_ = _.toNumber(value);
        return _.isNumber(value_) && !_.isNaN(value_) && value_ > 0 || "１以上の数値を半角文字で入力してください。";
      }
    },
    /**
     * 日付ルール
     * @param {*} value 値
     */
    rule_ymd(value) {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      return (pattern.test(value) && dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') === value) 
        || 'YYYY-MM-DD';
    },
    /**
     * 遷移時におけるURLの検査
     * @param {*} guestId 利用者ID
     * @param {*} checkListId ケアプランID
     * @param {*} typeName ハッシュ
     * @returns {boolean} 遷移を許可するかどうか
     */
    async checkCheckListRoute({
      guestId,
      checkListId,
      typeName
    }) {
      // 1. 利用者確認
      if (_.isNil(guestId)) {
        // IDが不正：遷移を拒否
        return false;
      }
      if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
        // 利用者IDがキャッシュに存在しない：再読込
        this.isGuestsLoading = true;
        await this.$store.dispatch("updateGuests");
        this.isGuestsLoading = false;

        // 再確認
        if (!_.some(this.activeGuests, (x) => x.guestId === guestId)) {
          // 発見できず：遷移を拒否
          _.delay(() => {
            this.$router.replace(`/${this.pathPrefix}`).catch(() => {});
          }, 500);
          return false;
        }
      }
      // 利用者を選択
      if (
        _.isNil(this.selectedGuest) ||
        this.selectedGuest.guestId !== guestId
      ) {
        await this.$store.dispatch("selectGuest", { guestId });
      }
      // 2. チェックリスト確認
      if (_.isNil(checkListId)) {
        // IDが不正：遷移を拒否
        return false;
      }
      if (!_.some(this.activeCheckLists, (x) => x.checkListId === checkListId)) {
        // チェックリストIDがキャッシュに存在しない：再読込
        await this.$store.dispatch("updateCheckLists");

        // 再確認
        if (!_.some(this.activeCheckLists, (x) => x.checkListId === checkListId)) {
          // それでも発見できない：遷移を拒否
          _.delay(() => {
            this.$router
              .replace(`/${this.pathPrefix}/${guestId}/checklists`)
              .catch(() => {});
          }, 500);
          return false;
        }
      }
      if (
        // チェックリストを選択
        _.isNil(this.selectedCheckList) ||
        this.selectedCheckList.checkListId !== checkListId
      ) {
        await this.$store.dispatch("selectCheckList", { checkListId });
      }

      // 3. typeName確認
      if (_.isNil(typeName) || !_.findIndex(this.typeNames, typeName)) {
        // typeNameが不正：遷移を拒否
        _.delay(() => {
          this.$router
            .replace(`/${this.pathPrefix}/${guestId}/checklists/${checkListId}`)
            .catch(() => {});
        }, 500);
        return false;
      }
      // OK
      return true;
    },
    /**
     * 上位画面へ遷移
     */
    async saveCheckList() {
      // 保存
      await this.updateCheckList();
      // AI呼び出し。
      if (this.status2.isCompleted) {
        this.isPredictFrailLoading = true;
        try {
          await this.$store.dispatch("callPredictFrail");
          // 遷移
        } catch (err) {
          if (err.err_message) {
            await alert(err.err_message);
          } else {
            // await alert(JSON.stringify(err));
          }
        }
      }
      this.isPredictFrailLoading = false;
      let route = `/${this.pathPrefix}/${this.$route.params.guestId}/checklists/${this.$route.params.checkListId}#${this.$route.params.typeName}`;
      await this.$router.push(route).catch(() => {});
    },
    /**
     * ページ内遷移
     */
    async toPage(hash) {
      // 保存処理
      await this.updateCheckList();

      // 遷移
      let route = `/${this.pathPrefix}/${this.$route.params.guestId}/checklistedit/${this.$route.params.checkListId}/${hash}`;
      await this.$router.push(route).catch(() => {});

      // スクロール
      this.$goTo(0, { duration: 0, offset: 0, easing: "linear" });
    },
    showDescriptionDialog(value, title, html) {
      if (value) {
        this.descriptionDialog.title = title;
        this.descriptionDialog.html = DOMPurify.sanitize(html);
        this.descriptionDialog.visible = true;
      } else {
        this.descriptionDialog.visible = false;
        this.title = null;
        this.html = null;
      }
    },
    //ボタン押下時のオートスクロール
    nextScroll(id) {
      const targetTop = document.getElementById(id).getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
          top: targetTop,
          behavior: 'smooth'
      });
    },
    /**
     * タブ選択
     * @param {*} idx タブインデックス
     * @param {*} lavel Jump先のラベル（あれば）
     */
    async selectStep(idx, lavel = '') {
      let typeName = _.nth(this.typeNames, idx);
      if (typeName) {
        let url = `/${this.pathPrefix}/${this.$route.params.guestId}/checklistedit/${this.$route.params.checkListId}/${typeName}`;
        if (this.$route.params.typeName !== typeName) {
          await this.$router
            .push(url)
            .catch(() => {});
          await this.$goTo(0);
        }
        if (lavel !== '') {
          document.location.href = `${url}${lavel}`;
        }
      }
    },
    // 状態データのみのオブジェクトを作成
    createCheckListValues() {
      return {
        /*
        ..._(this.checklists["life"])
          .map((x) => _.pick(x, ["key", "value"]))
          .reduce((obj, x) => {
            obj[x.key] = _.cloneDeep(x.value);
            return obj;
          }, {}),
        */
        ..._(this.checklists["check"])
          .map((x) => _.pick(x, ["key", "value"]))
          .reduce((obj, x) => {
            obj[x.key] = _.cloneDeep(x.value);
            return obj;
          }, {}),
          ..._(this.checklists["feedback"])
          .map((x) => _.pick(x, ["key", "value"]))
          .reduce((obj, x) => {
            obj[x.key] = _.cloneDeep(x.value);
            return obj;
          }, {}),
          ..._(this.checklists["interview"])
          .map((x) => _.pick(x, ["key", "value"]))
          .reduce((obj, x) => {
            obj[x.key] = _.cloneDeep(x.value);
            return obj;
          }, {}),
      };
    },
    /**
     * AI用のクエリを作成
     */
    createAIQueryFromCheckLists() {
      let values = this.createCheckListValues();
      return values;
    },
    /**
     * ケアプランからconditionの読み込み（UIへのバインド）
     */
    loadChecklists() {
      if (this.selectedCheckList) {
        this.carePlanDtm = this.selectedCheckList.targetDtm
          ? dayjs(this.selectedCheckList.targetDtm).format("YY年M月D日") : "";
        for (let type of this.typeNames) {
          _.forEach(this.checklists[type], (item) => {
            item.value = this.selectedCheckList.checklists[item.key];
          });
        }
      }
    },
    async updateCheckList() {
      // チェックリストの保存
      this.isUpdating = true;
      // 調査票のみ編集して保存すると Judge が走らないので判定が更新されない。
      // this.lifeToCheck();
      let checklists = this.createAIQueryFromCheckLists();
      await this.$store.dispatch("saveCheckListChecklists", { checklists });
      this.isUpdating = false;
    },
  },
  // end of methods
};
</script>

<style lang="scss">
// vuetifyで生成された子要素にscopeがついてないので
.chip--required > .v-chip__content {
  font-size: 0.7rem !important;
  color:#fff !important;
}
.services__toggle > .v-btn__content {
  position: fixed;
  top: calc(50% - 20px);
  right: 5px;
}
</style>

<style lang="scss" scoped>
@import "../scss/conditiongraph.scss";
@import "../scss/comparing.scss";

:deep(div .steptab__item div.text-center a .text-secondary ){
  background-color: #e62323 !important;
  border-color: #e62323 !important;
  .v-chip__underlay{
    background-color: inherit !important;
  }
}
:deep(h4 ){
  .text-secondary {
      background-color: #e62323 !important;
      border-color: #e62323 !important;
      .v-chip__underlay{
        background-color: inherit !important;
      }
  }
  .text-grey-lighten-1,
  .text-grey.lighten-1 {
      background-color: #bdbdbd!important;
      border-color: #bdbdbd!important;
      .v-chip__content{
        color: rgba(0,0,0,.87);
      }
  }
}
:deep(.v-chip--size-default ){
  border-radius: 16px;
}
:deep(.v-slide-group__content>.chip__item ){
    min-width: 100px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1rem;
    padding: 1.8rem 1rem;
    margin-bottom: 1rem;
}
#modal-add-service .v-card-text .v-container {
  max-width: 100% !important;
  #service_serch_info{
    padding-left: 24px;
  }
}

:deep(#loader ){
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 80%);
}

.v-chip-group .v-chip.chip__item--selected.chip__item {
  background-color: var(--key-color) !important;
  color: white !important;
}

:deep(.serviceitem .v-container .v-row ){
  .v-col {
    padding-left: 1.2rem;
    .v-selection-control__input {
      position: absolute;
      top: 40%;
      left: -1.7rem;
    }
  }
}
  h2#guest-name {
    background-color: #eaeff1;
    padding: 0.1rem 0.1rem 0.5rem 0.6rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: -1px -1px 1px 0 #f1f7ff, inset 2px 2px 4px 0px rgba(0, 0, 0, 0.3);
  b {
    font-size: 1.2rem;
    text-shadow: 1px 1px 2px #b0bbc1;
    line-height: 1.1;
  }

  .care-plan-dt {
    color: #ffffff;
    background-color: var(--key-color);
    padding: 0.1rem 0.2rem 0.2rem 0.3rem;
    font-weight: bold !important;
    border: 2px solid #fff;
    border-radius: 6px;
    box-shadow: 3px 2px 4px 0px rgb(137 150 172 / 30%);
    white-space: nowrap;
    text-shadow: 1px 1px #426676;
    font-size: 0.8rem;
    vertical-align: middle
  }
}

:deep(#inputGroup_1){
  div.v-input__control {
    border-bottom: 3px var(--key-color) solid;
    background-color: rgb(225 242 255);
    font-weight:bold;
    div.v-select__selection--comma{
      min-height: 1.3rem;
    }
  }
}
:deep(.basic_care){
  h5 {
    color:#660303;
    padding:1.5rem!important;
    min-height:auto!important
  }
  h5:before {
    content: none !important;
  }
}
:deep(#subTabArea.v-tabs){
  margin-bottom: 12px;
  .v-slide-group__content {
    background-color:inherit !important;
    .v-tab{
      font-size:1.1rem!important;
      background-color: #fff;
      margin-right: 5px !important;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border: 3px solid var(--key-color);
      color: var(--key-color) !important;
      padding: 0 1rem !important;
      line-height:1;
      font-weight:bold;
      top: 8px;
      width:auto !important;
    }
    .v-tab--selected{
      background-color: var(--key-color);
      color:#fff !important;
      top: 0;
      .v-tab__slider {
        opacity: 0;
      }
    }
  }
}

:deep(.parent-memo){
  div{
    div.my-2{
      margin-top:0;
      button.memo_btn{
        display: block;
        position: absolute;
        background-color: inherit;
        right: 10px;
        top: 68px;
      }
    }
    div.not_entered{
      button.memo_btn{
        top: 85px!important;
      }
    }
  } 
}

:deep(.v-col.v-col-12.mt-1.sep ){
    border-top: solid 3px #666;
    padding-top: 2rem !important;
    margin-top: 1rem!important;
}
div.v-container.pa-6.v-container--fluid.underline {
  border-top: 6px solid var(--key-color);
}
@media screen and (max-width: 600px) {
  div#care-plan-guest-name{
    padding-top: 2px !important;;
  }
  h2#guest-name {
    margin-left: 5px;
    b {
      font-size: 1rem !important;
      line-height: 1.6;
    }
    .care-plan-dt {
      padding: 0 0.2rem 0.1rem 0.2rem;
    }
  }
}
@media screen and (max-width: 960px) {
  div.services__title h3 {
    width: 96%!important;
  }
}
@media screen and (max-width: 900px) {
  :deep(.parent-memo){
    div {
      div.my-2{
        margin-top:0;
        button.memo_btn{
          display: inline;
          position: relative;
          left: 0;
          top: 0;
        }
      }
      div.not_entered{
        button.memo_btn{
          top: 0 !important;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  div.services__title h3 {
    width: 96%!important;
  }
  :deep(#subTabArea.v-tabs){
    margin-bottom:-8px;
    margin-left:8px;
    .v-tab {
      font-size:0.9rem!important;
    }
  }
}
// steptab
.steptab {
  margin: 0px;
  width: auto;
  max-width: none !important;
  min-height: 75px !important;

  &--sticky {
    position: sticky;
    top: 0;
    z-index: 2;
    background: linear-gradient(
      180deg,
      rgba(250, 250, 250, 0.9) 0%,
      rgba(250, 250, 250, 0.6) 60%,
      rgba(250, 250, 250, 0) 90%,
      rgba(250, 250, 250, 0) 100%
    );
  }

  & > div {
    flex-wrap: nowrap;
  }

  &__content {
    display: flex;
    flex-direction: column;

    .scrolled .steptab--sticky & {
      flex-direction: row;
    }
  }
}

// services
.services {
  position: relative;

  &__current,
  &__refer {
    @media print {
      -webkit-box-flex: 0 !important;
      -ms-flex: 0 0 50% !important;
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }

  &__current {
    &-mobile {
      transition: filter ease 300ms;

      &--hide {
        filter: contrast(60%) blur(1px);
      }
    }
  }

  &__refer {
    &-mobile {
      position: absolute;
      left: calc(100% - 45px);
      height: 100%;
      z-index: 1;

      //opacity: 0.75;

      transition: $transition_left, $transition_opacity, filter ease 300ms;
      filter: contrast(60%) blur(1px);

      &--show {
        left: 25px;

        filter: contrast(100%) blur(0);
        //opacity: 1;
      }
    }
  }

  &__toggle {
    position: absolute;
    right: -20px;
    z-index: 1;
    min-width: auto !important;
    width: 65px !important;
    height: 100% !important;
    border-radius: 5px 0 0 5px;
  }

  &__title {
    height: 40px;
  }

  &__items {
    & > *:last-child {
      margin-bottom: 0 !important;
    }

    &-container {
      transition: min-height 300ms ease;
    }
  }

  &__change {
    height: 40px;
  }

  &__determine {
    height: 52px;
  }
}

.selectservice {
  &__filter {
    background-color: $color_gray_f !important;
  }

  &__button-add {
    height: 56px !important;
  }
}

// common
h4 {
  margin-bottom: 0.1em;
}

.input--short {
  width: 210px;
}
:deep(.text-field--short ){
  .v-input__control {
    width: 7rem;
    padding-top: 0.6rem;
    input {
      padding: 0.6rem 0 0.6rem 0.3rem;
    }
    span.v-text-field__suffix{
      padding-right: 0.3rem;
    }
  }
}
.input--long {
  width: 100%;
}
.chip__item {
  min-width: 100px;
  justify-content: center;
  font-size: 1rem;

  &--selected {
    background-color: $color_primary;
    color: white !important;
  }
}
.togglebutton__item {
  min-width: 100px !important;

  &--selected {
    background-color: $color_primary !important;
    color: white !important;
  }
}

.jmpofst {
  position: absolute;
  margin-top:-100px;
}

.v-application .text-h3 {
    font-size: 1.8rem !important;
    padding:1rem !important;
}

:deep(.v-expansion-panel-title ){
    background-color: var(--key-color);
    color: #fff;
    div {
      font-weight: bold;
      line-height: 1;
    }
}

:deep(.mdi-chevron-down::before ){
    color: #fff;
}

.v-application .title {
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
}

:deep(hr.page-break ){
  visibility:hidden;
  padding:0;
  margin:0;
  height:0;
}
:deep(table.conditionlist__table tr ){
  th,td{
    font-size: 1.3rem;
  }
}

:deep(.careplan__container .v-tab){
  font-weight:bold;
  font-size: 1rem !important;
}

:deep(div#judgement ){
  /* 判定エリア専用 */
  .v-expansion-panel-text__wrap {
    padding: 0.6rem 1.3rem 1.3rem 1.3rem;
    .row .col {
      padding:0.3rem !important;
      mark {
        white-space: nowrap;
        box-shadow: 1px 1px 2px #777;
      }
    }
  }
}

:deep(.v-expansion-panel-text__wrap){
  padding:1rem 1.5rem;
  span.life_title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    display: block;
  }
  div.life_edit div.v-sheet{
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }
  div.indent {
    padding-left: 3rem !important;
    margin-bottom: 0 !important;
  }

  div.indent:not(.last){
    border-bottom: none !important;
  }

  div.life_title{
    border-bottom: none !important;
  }
  div.points_text {
    padding: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    b {
      display: block;
      width: 40%;
      margin-bottom: 1rem;
    }
    span {
      mark {
        white-space: nowrap;
        box-shadow: 1px 1px 2px #777;
        font-size: 1rem;
      }
    }
  }

  div.row {
    margin: 0 !important;
    div{
      font-weight:bold;
    }
  }

  .points_text {
    font-size:1.2rem;
    b,span {
      font-size:1.2rem;
    }
  }

  h3{
    background: $color_section_bg;
    padding: 1rem 1.6rem 1rem 1.5rem !important;
    border: 0;
    margin:1rem 0 1.2rem 0 !important;
  }
  h3.emphasis {
    font-size: 1.6rem;
    color: #212a44 !important;
    border-left: 6px solid $color_primary !important;
    background: #f4f4f4 !important;
  }
  h4 {
    font-size: 1.4rem;
    margin-bottom: 0.5em;
  }
  h5 {
    font-size: 1.4rem;
    position: relative;
    padding: 1.5rem 2rem 1.5rem 150px;
    min-height: 170px;
    border-radius: 10px;
    background: #FFECE6;
    border: 4px solid #FFDED3;
    margin-bottom: 1.2rem !important;
  }

  h5:before {
    position: absolute;
    top: 17px;
    left: 5px;
    width: 0;
    height: 0;
    transform: scale(0.4);
    content: url(../assets/care1.png);
  }

  hr {
    border-top:1px solid $color_section_border;
    margin-top:1rem;
    margin-bottom:2rem;
  }
  div div {
    font-size: 1.3rem;
  }

  div div.v-col{
    padding:0 !important;
  }

  div div strong {
    font-size: 1.3rem;
    font-weight: bold;
    color: #b8545d;
  }
  section ul{
    li {
      line-height: 1.1;
      margin-bottom: 0.6rem;
    }
    li.under_line{
      text-decoration: underline; /* 下線 */
      text-decoration-thickness: 0.8em; /* 線の太さ */
      text-decoration-color: rgba(255, 228, 0, 0.8); /* 線の色 */
      text-underline-offset: -0.60em; /* 線の位置。テキストに重なるようにやや上部にする */
      text-decoration-skip-ink: none; /* 下線と文字列が重なる部分でも下線が省略されない（線が途切れない） */
    }
  }

  .v-row .v-col section {
    border: 1px solid $color_section_border;
    border-radius: 0.3em;
    background-color: $color_section_bg;
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    padding: 1.1rem ;
    margin-bottom: 1.2rem !important;
  }
  .v-row .v-col section ul li{
    font-size: 1.3rem;
  }
}
</style>

<style lang="scss">
.comparing-selected {
  @media print {
    -webkit-print-color-adjust: exact;
  }
}

.comparing-chart__item {
  canvas {
    @media print {
      margin: 0 auto;
    }
  }
}

section ul li b {
  font-size: 1.3rem;
  color:#b8545d;
}

@media print {
  /* 印刷時は折りたたんでいても強制展開 */
  .v-expansion-panel-text {
    display:block !important;
  }
  /* Chromeの印刷バグ対応 ここから */
  .chartjs-size-monitor-expand>div {
    display: none !important;
  }
  .chartjs-size-monitor-shrink>div {
    display: none !important;
  }
  /* Chromeの印刷バグ対応 ここまで */
}

  /* ケアプラン「編集モード」の「ステップメニュー」の各設定*/
div.scrolled div.steptab--sticky span.steptab__icon-check{
  top:16px !important;
}
div.care-plan-edit {
  div.steptab {
    min-height: 60px;
    div.steptab__item {
      text-align: center;
      margin: 0 2px;
      span.steptab__icon-check span.v-badge__wrapper {
        top: -5px!important;
      }
      button.steptab__circle{
        width: 60px !important;
        height: 60px !important;
        min-width: 60px;
        span div.steptab__content{
          flex-direction: column;
          align-items: center;
          span {
            line-height: 1rem;
            font-size: 0.8rem !important;
          }
        }
      }
    }
  }
}
/* ----------------------スマホ向け設定 ---------------------- */
@media screen and (max-width: 600px) {
  /* スマホ表示時のケアプラン「編集モード」の「ステップメニュー（基本生活～比較）」の各設定*/
  div.care-plan-edit {
    div.steptab {
      min-height: 60px;
      div.steptab__item {
        margin: 0 2px;
        button.steptab__circle{
          width: 60px !important;
          height: 60px !important;
          min-width: 60px;
          span div.steptab__content span {
            line-height:1rem
          }
        }
        //残り項目のアラートバッチ
        div a span {
          &.v-chip.v-size--small {
            font-size: 14px !important;
            font-weight:bold;
          }
          &.px-2 {
            padding-right: 6px !important;
            padding-left: 6px !important;
          }
        }
      }
    }
  }
  #reason-dialog-content {
    div.v-card__title {
      font-size:1rem !important;
    }
    th {
      background-color: #fff !important;
      span.v-chip__content {
        color:#000 !important;
        text-shadow: none !important;
        font-size:1rem !important;
      }
    }
  }
  /* スマホ表示時のケアプラン「編集モード」の「ステップメニュー（基本生活～比較）」 のスクロール時の各設定*/
  div.scrolled div.steptab {
    /* メニュー名称折り返し設定（スクロール時）*/
    .steptab__content {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    /* 入力チェックアイコン表示位置設定（スクロール時）*/
    span.steptab__icon-check {
        top: 16px !important;
        right: 6px !important;
        z-index: 1;
    }
  }

  /* スマホ表示時の「プラン作成日」 [ケアプラン閲覧]*/
  div.careplan__timeline {
    position: absolute;
    top: 103px;
    z-index: 3;
    width: 150px;
    right: 0;
    overflow: hidden;
    border-radius: 0 !important;
    border-bottom: 1px solid #989898 !important;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    i {
      vertical-align: top;
    }
    button{
      height: 26px !important;
    }
    button span.v-btn__content span{
      font-size: 0.8rem;
      font-weight: bold; 
    }
    &.close{
        width: 128px !important;
        height: 26px !important;
    }
  }

  /* スマホ表示時のケアプラン「編集モード」の「ステップメニュー（基本生活～比較）」 の見出し（青背景の見出し兼、折りたたみボタン）*/
  button.v-expansion-panel-title {
    padding: 0 12px !important;
    min-height: 48px !important;
    div.title {
      font-size: 1rem !important;
      line-height: 1.1rem !important;
    }
  }

  /* 分析 */
  div.v-expansion-panels {
    div.px-1 div.pa-2 button span{
      /* 「分析するボタン」 */
      i {
        font-size: 26px !important;
      }
      span.text-h3 {
        font-size: 1.3rem !important;
        padding:0 !important;
      }        
    }
    /* 分析：AS,SS機能（分析） */
    div.v-expansion-panel div.v-expansion-panel-text div.v-expansion-panel-text__wrapper {
      padding: 0.4rem !important;
      div div {
        font-size: 1rem !important;
        h3 {
          font-size: 1.1rem !important;
        }
      }
      h4 {
        span {
          font-size: 1.1rem !important;
        }
        span.v-chip__content,
        button span {
          font-size: 0.7rem !important;
        }
      }

      section {
        padding: 1rem !important;
        h4 {
          font-size: 1.1rem !important;
        }
        ul {
          padding-left: 18px !important;
        }
        ul li{
          font-size: 1rem !important;
        }
        ul li b {
          font-size: 1rem !important;
        }
      }
      strong {
        font-size: 1rem !important;
      }
      h5 {
        font-size: 1.1rem !important;
        padding:0.8rem 0.5rem 0.8rem 100px !important;
        min-height: 140px !important;
      }
      h5:before {
        transform: scale(0.25) !important;
        top: 12px !important;
        left: 5px !important;
      }
      .chip__item.wide_chip {
        width:100%;
      }
      .chip__item.wide_chip.chip__item--selected {
        font-size: 1rem;
        margin-bottom:0 !important;
        margin-right: 0;
        border-radius: 8px 8px 0 0 !important;
      }
      .v-slide-group__content article {
        margin-bottom:1rem;
        padding:0.5rem 0.1rem;
        border-radius: 0 0 6px 6px;;
        width: 100%;
        span {
          font-size: 0.8rem;
          white-space:normal;
          line-height: 1;
          margin: 2px 0 2px 0;
          padding:0.4rem 0.6rem;
        }
      }
    }
  }
  /* サービス：スマホ用間隔調整*/
  div.v-sheet div.v-container--fluid div.v-row div.v-col div.v-row.ma-6.service_plans.v-row--no-gutters{
    &>div.v-col.v-col-12{
      padding:4px!important;
      &>div:nth-of-type(1){
        /* 見出しエリア */
        margin: 0;
      }
      div.services__items-container div.services__items div.ew-service-item-group>div>div>div>div.text-right {
        padding: 0!important;
      }
      &>div:nth-of-type(3)>div{
        padding: 0!important;
      }
    }
    margin:6px !important;
    div.py-4.v-col.v-col-12{
      padding:0!important;
      div.ma-0.pa-0.v-col-md-6.v-col-12{
        padding:0!important;
      }
    }
  }
  /* サービス：比較時の比較元の表示範囲調整 */
  .services {
    &__refer {
      &-mobile {
        left: calc(100% - 10px) !important;
        &--show {
          left: 10px !important;
        }
      }
    }
  }
  /* サービス：比較時の比較元の表示範囲の幅および、「＜」アイコン位置調整 */
  button.services__toggle.v-btn {
    width: 45px !important;
    & > .v-btn__content {
      i.mdi-chevron-right,
      i.mdi-chevron-left {
        font-size: 60px !important;
        text-shadow: 0 0 5px #000;

        color: #FFF !important;
        right: -22px;
      }
    }
  }
  /* サービス：比較領域確保の為に横幅を少し縮める */
  div.ma-0 div.pa-6{
    div div.v-row {
      width: 99%;
    }
  }

  #tab_border {
    border-top: 3px solid var(--key-color) !important;
    padding-top: 6px !important;
  }
  /* サービス：対象(選択した)サービス エリア */
  div.v-container--fluid div.v-row div.services__current-mobile,
  div.v-container--fluid div.v-row div.services__refer-mobile {
    div.services__items-container div.services__items div.ew-service-item-group{
      .serviceitem .px-4 {
        padding-right: 0 !important;
        padding-left: 0.5rem !important;
      }
      div.serviceitem,
      div.v-container--fluid div div{
        max-width: 97% !important;
      }
      div.v-col-sm-auto.v-col-12,
      div.v-card__title.serviceitem__name {
        max-width: 100% !important;
      }
    }
  }
  /* サービス：AIバージョンエリア */
  div.v-container--fluid div.v-row div.services__current-mobile div.ma-0 div.pa-6 div:nth-of-type(3) {
    div.v-col.v-col-12 > div:nth-of-type(1){
      padding : 0.5rem 0 0 1rem ;
    }
  }

  /* ケアプラン編集 */
  div.service_plans div.v-col-12,
  div.mb-6 div.pa-6,
  div.ma-0 div.pa-6{
    padding:8px !important;
    div div.v-row {
      & div {
        &.pt-0.v-col.v-col-12,
        &.py-0.v-col.v-col-12{
          /* ケアプラン編集＞サービスの主に決定系ボタン関連 */
          padding:8px !important;
        }
      }
    }
    /* サービス：サービスプラン作成エリア上部 */
    div.services__title {
      height: auto !important;
      /* サービス：「サービスプランを作成」 */
      h3 {
        display:block;
        width:96% !important;
      }
      /* サービス：「回数を含めて予測します・・・」 */
      span {
        display:block;
        left:10px !important;
        width:95% !important;
      }
    }
    /* サービス：棒グラフエリア */
    div.v-row div.v-col-12 {
      div.conditiongraph__header,
      div.conditiongraph {
        svg {
          width:230px;
        }
        div.conditiongraph__item div.text-left.text-truncate {
          white-space: normal !important;
          font-size:0.8rem !important;
          line-height: 0.8rem;
          span {
            font-size:0.8rem !important;
            white-space: nowrap;
          }
        }
      }
    }
    h3 {
      margin-top: 0 !important;
      margin-bottom: 0.5em !important;
      padding-top: 0.5rem;
    }
  }
}
</style>