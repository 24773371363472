<template>
  <div id="app">
    <v-app :class="{'scrolled': offsetTop > 200, 'sp_mode': display.xs.value}">
      <!-- Header -->
      <v-app-bar
        class="site-header"
        elevation="0"
        absolute
        density="compact"
        color="lightgray"
      >
        <v-toolbar-title class="site-header__logo">
          <h1>SOIN-R</h1>
          <span class="version_area">{{ dispVersion }}</span>
        </v-toolbar-title>
        <v-toolbar-items>
          <v-row
            no-gutters
            class="mx-2"
            align-content="center"
          >
            <v-col>
              <v-chip
                id="history-btn"
                :to="`/${pathPrefix}/history`"
                exact
              >
                <v-icon start>
                  mdi-history
                </v-icon>履歴一覧
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  履歴一覧画面を表示します。
                </v-tooltip>
              </v-chip>
            </v-col>
          </v-row>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-row
            no-gutters
            class="mx-2"
            align-content="center"
          >
            <v-col>
              <v-chip
                id="app-bar-userlist"
                :to="`/${pathPrefix}/customer`"
                exact
              >
                <v-icon start>
                  mdi-account-multiple
                </v-icon>利用者一覧
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  利用者一覧画面に戻ります。
                </v-tooltip>
              </v-chip>
            </v-col>
          </v-row>
        </v-toolbar-items>
        <v-spacer />
        <!-- account -->
        <v-menu>
          <template #activator="{props}">
            <v-toolbar-items>
              <v-row
                no-gutters
                align-content="center"
              >
                <v-col id="app-bar-username-outer">
                  <v-chip
                    id="app-bar-username"
                    v-bind="props"
                  >
                    {{ dispName }}
                  </v-chip>
                  <v-tooltip
                    activator="parent"
                    location="bottom"
                  >
                    プルダウンメニューを表示します。
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                align-content="center"
              />
            </v-toolbar-items>
          </template>
          <v-list
            class="site-header__account-menu"
          >
            <v-list-item
              v-for="(item, i) in accountMenuItems"
              :id="`app-bar-menu-${i}`"
              :key="item.title"
              class="site-header__account-menuitem"
              :class="item.class"
              :prepend-icon="item.icon"
              :title="item.title"
              @click="clickAccountMenuItem(i)"
            />
            <!-- span class="text-caption">
              ご意見、ご要望、アンケートにおきましては<br>
              Googleフォームを利用させていただいております。
            </span -->
          </v-list>
        </v-menu>
      </v-app-bar>
      <!-- Content -->
      <v-main id="main_area">
        <v-container
          fluid
          style="padding-top: 0 !important;"
        >
          <router-view v-slot="{ Component }">
            <transition
              name="fade"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </v-container>
      </v-main>
      <!-- Footer -->
      <v-footer id="bottomElement">
        <v-row no-gutters>
          <v-col
            class="text-caption text-right"
            cols="12"
          >
            &copy; 2019 - {{ new Date().getFullYear() }} Care Design Institute Inc, All Rights Reserved.
          </v-col>
        </v-row>
      </v-footer>
      <!-- Botton Go Top and Bottom -->
      <div id="autoScroll">
        <v-btn
          id="go_top"
          class="hide--print"
          fixed
          @click="$goTo(0)"
        >
          <v-img :src="require('./assets/go_top.svg')" />
          <v-tooltip
            activator="parent"
            location="top"
          >
            ページの先頭に戻ります。
          </v-tooltip>
        </v-btn>
        <v-btn
          id="go_bottom"
          class="hide--print"
          fixed
          @click="$goTo('#bottomElement')"
        >
          <v-img :src="require('./assets/go_bottom.svg')" />
          <v-tooltip
            activator="parent"
            location="top"
          >
            ページの最下部に移動します。
          </v-tooltip>
        </v-btn>
      </div>
      <!-- ----------------------------- 以下、ダイアログ ---------------------------------- -->
      <!-- 基本情報ダイアログ -->
      <v-dialog
        id="basicInfoDialog"
        v-model="infoDialog.visible"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <h3>ユーザ情報</h3>
          </v-card-title>
          <v-card-text>
            <v-form ref="infoDialog">
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="infoDialog.editedItem.accountName"
                      :disabled="infoDialog.isLoading"
                      label="アカウント名"
                      density="compact"
                      variant="filled"
                      readonly
                      color="gray-lighten-2"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="infoDialog.headers"
                      :items="customOffices"
                      density="compact"
                      hide-default-footer
                      :loading="isOfficesLoading"
                      loading-text="データを取得中..."
                      items-per-page-text="表示行数"
                      no-data-text="事業所登録がありません。"
                    >
                      <template #[`item.office`]="{item}">
                        <span
                          style="max-width:150px; font-size:small;"
                          class="d-inline-block text-truncate"
                        >{{ item.officeName }}</span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="infoDialog.editedItem.userName"
                      :disabled="infoDialog.isLoading"
                      label="名前"
                      :rules="[rule_required]"
                      density="compact"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="infoDialog.editedItem.userMail"
                      :disabled="infoDialog.isLoading"
                      label="メールアドレス"
                      :rules="[rule_required, rule_email]"
                      density="compact"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container class="pa-3">
              <v-row
                no-gutters
                justify="end"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="pa-1"
                >
                  <v-btn
                    id="info-dialog-cancel"
                    :disabled="infoDialog.isLoading"
                    variant="text"
                    min-width="150px"
                    block
                    @click="showInfoDialog(false);"
                  >
                    キャンセル
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pa-1"
                >
                  <v-btn
                    id="info-dialog-save"
                    :loading="infoDialog.isLoading"
                    :disabled="!canSaveInfo"
                    color="config.system_key_color"
                    min-width="150px"
                    block
                    @click="updateUserInfo"
                  >
                    保存
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- パスワード変更ダイアログ -->
      <v-dialog
        id="passChgDialog"
        v-model="changePassDialog.visible"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h5">
            <h3>パスワードの変更</h3>
          </v-card-title>
          <v-card-text>
            <v-form ref="changePassDialog">
              <v-container class="pa-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      id="chang-pass-dialog-oldpassword"
                      v-model="changePassDialog.password1"
                      label="現在のパスワード"
                      :rules="[rule_required]"
                      :type="changePassDialog.visiblePassword1 ? 'text' : 'password'"
                      :append-icon="changePassDialog.visiblePassword1 ? 'mdi-eye-off' : 'mdi-eye'"
                      :disabled="changePassDialog.isLoading"
                      density="compact"
                      @click:append="changePassDialog.visiblePassword1 = !(changePassDialog.visiblePassword1)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      id="change-pass-dialog-newpassword"
                      v-model="changePassDialog.password2"
                      label="新しいパスワード"
                      :rules="[rule_required, rule_pwcheck]"
                      :type="changePassDialog.visiblePassword2 ? 'text' : 'password'"
                      :append-icon="changePassDialog.visiblePassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                      :disabled="changePassDialog.isLoading"
                      density="compact"
                      @click:append="changePassDialog.visiblePassword2 = !(changePassDialog.visiblePassword2)"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      id="change-pass-dialog-renewpassword"
                      ref="repass"
                      v-model="changePassDialog.password3"
                      label="新しいパスワード（確認用）"
                      :rules="[rule_repass]"
                      :type="changePassDialog.visiblePassword3 ? 'text' : 'password'"
                      :append-icon="changePassDialog.visiblePassword3 ? 'mdi-eye-off' : 'mdi-eye'"
                      :disabled="changePassDialog.isLoading"
                      density="compact"
                      @click:append="changePassDialog.visiblePassword3 = !(changePassDialog.visiblePassword3)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container class="pa-3">
              <v-row
                no-gutters
                justify="end"
              >
                <v-col
                  cols="12"
                  md="4"
                  class="pa-1"
                >
                  <v-btn
                    id="chang-pass-dialog-cancel"
                    variant="text"
                    :disabled="changePassDialog.isLoading"
                    min-width="150px"
                    block
                    @click="showChangePassDialog(false)"
                  >
                    キャンセル
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="pa-1"
                >
                  <v-btn
                    id="chang-pass-dialog-ok"
                    color="primary"
                    min-width="150px"
                    :disabled="!canChangePass"
                    :loading="changePassDialog.isLoading"
                    block
                    @click="changePassword"
                  >
                    変更
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="changePassDialog.errMessage !== ''">
                <v-col
                  cols="12"
                  class="py-1 text-right"
                >
                  <span class="text-red-darken-4">{{ changePassDialog.errMessage }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ログアウトダイアログ -->
      <v-dialog
        id="modal-logout"
        v-model="logoutDialog.visible"
        persistent
        max-width="350px"
      >
        <v-card>
          <v-card-title class="text-h5">
            <h3>ログアウト</h3>
          </v-card-title>
          <v-card-text>ログアウトしますか？</v-card-text>
          <v-card-actions>
            <v-container class="pa-3">
              <v-row
                no-gutters
                justify="end"
              >
                <v-col
                  cols="12"
                  md="6"
                  class="pa-1"
                >
                  <v-btn
                    id="logout-cancel"
                    variant="text"
                    :disabled="logoutDialog.isLoading"
                    block
                    @click="showLogoutDialog(false)"
                  >
                    キャンセル
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="pa-1"
                >
                  <v-btn
                    id="logout-logout"
                    color="secondary"
                    :loading="logoutDialog.isLoading"
                    :disabled="logoutDialog.isLoading"
                    block
                    @click="logout"
                  >
                    ログアウト
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- お知らせダイアログ -->
      <v-dialog
        id="info-dialog"
        v-model="announceDialog.visible"
        scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-container>
            <v-row
              id="info-dialog-body"
              justify="center"
              style="margin:0"
            >
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <v-sheet>
                    <v-row
                      id="info_area1"
                      justify="center"
                    >
                      <v-col
                        cols="11"
                        md="11"
                        class="px-0"
                      >
                        <div
                          v-if="customInfos.length === 0"
                          style="text-align:center;"
                        >
                          現在、お知らせは存在しません。
                        </div>
                        <v-carousel
                          v-else
                          id="infos"
                          hide-delimiter-background
                          delimiter-icon="mdi-circle-medium"
                          :cycle="true"
                          interval="10000"
                          height="300px"
                        >
                          <v-carousel-item
                            v-for="(item, i) in customInfos"
                            :key="item.infoId"
                          >
                            <v-card
                              :id="`infos-item-${i}`"
                              color="white"
                              height="100%"
                              rounded="0"
                              target="_blank"
                            >
                              <v-card-title>{{ item.title }}</v-card-title>
                              <v-card-subtitle style="padding:0">
                                {{ item.subtitle }}
                              </v-card-subtitle>
                              <div v-html="item.text" />
                            </v-card>
                          </v-carousel-item>
                        </v-carousel>
                      </v-col>
                      <v-col
                        cols="1"
                        align="right"
                        class="px-0"
                        style="position: absolute; right: 3%;"
                      >
                        <!-- ここに閉じるボタンを追加する -->
                        <v-icon
                          size="small"
                          @click="announceDialog.visible = false"
                        >
                          mdi-close
                        </v-icon>                          
                      </v-col>
                    </v-row>
                  </v-sheet>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- ログインダイアログ -->
      <v-dialog
        id="login-dialog"
        v-model="loginDialog.visible"
        fullscreen
        scrim="false"
        transition="dialog-bottom-transition"
        class="login"
      >
        <v-card class="login__container">
          <v-container
            class="pa-12"
            style="padding-top:48px !important;"
          >
            <v-form ref="loginDialog">
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  style="padding-bottom: 0;"
                >
                  <h1 class="login__logo mb-12">
                    SOIN-R
                  </h1>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <v-text-field
                    id="login-dialog-account-name"
                    v-model="loginDialog.accountName"
                    label="アカウント名"
                    density="compact"
                    :disabled="loginDialog.isLoading"
                    :rules="[rule_required, rule_length255]"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  class="pb-0"
                >
                  <v-text-field
                    id="login-dialog-password"
                    v-model="loginDialog.password"
                    label="パスワード"
                    :type="loginDialog.visiblePassword ? 'text' : 'password'"
                    :append-icon="loginDialog.visiblePassword ? 'mdi-eye-off' : 'mdi-eye'"
                    density="compact"
                    :disabled="loginDialog.isLoading"
                    :rules="[rule_required, rule_length255]"
                    @click:append="loginDialog.visiblePassword = !(loginDialog.visiblePassword)"
                    @keyup.enter="login"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  class="py-0"
                >
                  <v-checkbox
                    id="login-dialog-remember"
                    v-model="loginDialog.rememberMe"
                    label="ログイン状態を維持"
                    density="compact"
                    color="primary"
                    :disabled="loginDialog.isLoading"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                >
                  <v-btn
                    id="login-dialog-btn"
                    :loading="loginDialog.isLoading"
                    :disabled="!canLogin"
                    color="primary"
                    class="login__button"
                    size="large"
                    @click="login"
                  >
                    ログイン
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="loginDialog.errMessage !== ''"
                justify="center"
              >
                <v-col
                  cols="12"
                  md="6"
                  class="py-0 text-center"
                >
                  <span
                    id="login-error-msg"
                    class="text-red-darken-4"
                  >{{ loginDialog.errMessage }}</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="6"
                  class="py-0 text-center"
                >
                  <span class="glay--text-darken-4">{{ dispVersion }}</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  sm="6"
                  class="mt-12"
                >
                  <v-bottom-sheet v-model="loginDialog.visibleInfos">
                    <template #activator="{props}">
                      <v-btn
                        v-show="customInfos.length > 0"
                        id="login-dialog-info"
                        block
                        color="config.system_key_color"
                        variant="outlined"
                        v-bind="props"
                        size="large"
                      >
                        <v-container class="pa-0">
                          <v-row no-gutters>
                            <v-col align="left">
                              <v-icon class="mr-2">
                                mdi-email-multiple
                              </v-icon>お知らせ
                            </v-col>
                            <v-spacer />
                            <v-col align="right">
                              <v-chip
                                v-if="customInfos.length > 0"
                                id="infos-count"
                                color="config.system_key_color"
                                size="small"
                              >
                                {{ customInfos.length }}
                              </v-chip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-btn>
                    </template>
                    <v-sheet>
                      <v-row
                        id="info_area2"
                        justify="center"
                        style="margin:3px"
                      >
                        <v-col
                          cols="11"
                          md="6"
                          class="px-0"
                        >
                          <div
                            v-if="customInfos.length === 0"
                            style="text-align:center;"
                          >
                            現在、お知らせは存在しません。
                          </div>
                          <v-carousel
                            v-else
                            id="infos"
                            :show-arrows="false"
                            hide-delimiter-background
                            delimiter-icon="mdi-circle-medium"
                            color="black"
                            :cycle="true"
                            interval="10000"
                            height="200px"
                          >
                            <v-carousel-item
                              v-for="(item, i) in customInfos"
                              :key="item.infoId"
                            >
                              <v-card
                                :id="`infos-item-${i}`"
                                color="white"
                                height="100%"
                                rounded="0"
                                target="_blank"
                              >
                                <v-card-title>{{ item.title }}</v-card-title>
                                <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
                                <div v-html="item.text" />
                              </v-card>
                            </v-carousel-item>
                          </v-carousel>
                        </v-col>
                        <v-col
                          cols="1"
                          align="right"
                          class="px-0"
                        >
                          <!-- ここに閉じるボタンを追加する -->
                          <v-icon
                            id="info-dialog-close"
                            size="small"
                            @click="loginDialog.visibleInfos = false"
                          >
                            mdi-close
                          </v-icon>                          
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-bottom-sheet>
                </v-col>
              </v-row>
              <v-footer absolute>
                <v-col
                  class="pa-0 text-caption text-center"
                  cols="12"
                >
                  &copy; 2019 - {{ new Date().getFullYear() }}  Inc, All Rights Reserved.
                </v-col>
              </v-footer>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog
        id="infoLoadingDialog"
        v-model="isInfosLoading"
        persistent
        width="300px"
      >
        <v-card
          color="info"
          dark
        >
          <v-card-text>
            お知らせの取得中...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
/**
 * @namespace app
 */

import { mapGetters } from "vuex";
import _ from "lodash";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { useDisplay } from 'vuetify'

import packageJson from "../package.json";
// conifg
import config from "./config";
// dayjsのロケール指定
dayjs.locale(config.locale);
// serviceItem取得のために直接APIをコール
// debug設定
const debug = require("debug")("soin-client:app-vue");
DOMPurify.setConfig({ADD_ATTR: ['target', 'rel']});

/**
 * ビューモデル
 * @class
 * @memberof app
 */
export default {
  name: "App",
  components: {},
  setup() {
    const display = useDisplay();
    return { display };
  },
  data() {
    return {
      dialog: false,
      announceDialog: {
        visible: false,
        errMessage: "",
        isLoading: false,
      },
      loginDialog: {
        visible: true,
        accountName: "",
        password: "",
        visiblePassword: false,
        rememberMe: false,
        errMessage: "",
        isLoading: false,
        visibleInfos: false,
        hTimer: null
      },
      changePassDialog: {
        visible: false,
        isLoading: false,
        password1: "",
        password2: "",
        password3: "",
        visiblePassword1: false,
        visiblePassword2: false,
        visiblePassword3: false,
        errMessage: ""
      },
      logoutDialog: {
        visible: false,
        isLoading: false
      },
      infoDialog: {
        visible: false,
        isLoading: false,
        editedItem: {
          userId: null,
          accountName: null,
          userName: null,
          userMail: null,
        },
        headers: [
          {
            title: "事業所名",
            align: "start",
            sortable: false,
            value: "office",
            class: ["text-caption"]
          },
        ]
      },
      accountMenuItems: [
        { title: "ユーザ情報", icon: "mdi-settings", id:0 },
        { title: "パスワードの変更", icon: "mdi-key", id:1 },
        { title: "お知らせ表示", icon: "mdi-information-outline", id:3 },
        // { title: "ご意見、ご要望", icon: "mdi-account", id:6 },
        // { title: "アンケート", icon: "mdi-clipboard-edit", id:7 },
        // { title: "マニュアル", icon: "mdi-notebook", id:8 },
        { title: "ログアウト", icon: "mdi-logout-variant", id:5 }
      ],
      offsetTop: 0,
      dispVersion: "Ver " + packageJson.version, // + "(AI Ver " + config.ai_ver + ")",
      contact_url: config.contact_page_url,
      pathPrefix: 'guests',
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "isInfosLoading",
      "activeInfos",
      "isUserLoading",
      "activeUser",
      "isAdmin",
      "isOfficesLoading",
      "activeOffices",
      "activeGuests",
      "activeHists",
    ]),
    /**
     * お知らせ情報の加工
     * @returns {Array} お知らせ情報
     */
    customInfos() {
      return _(this.activeInfos || [])
        .map(x => ({
          infoId: x.information_id,
          //title: x.information_text,
          subtitle:
            _.isNil(x.begin_dtm) && _.isNil(x.end_dtm)
              ? ""
              : `掲載期間：${
                  _.isNil(x.begin_dtm)
                    ? ""
                    : dayjs(x.begin_dtm).format("YYYY年M月D日 HH時mm分")
                } 〜 ${
                  _.isNil(x.end_dtm)
                    ? ""
                    : dayjs(x.end_dtm).format("YYYY年M月D日 HH時mm分")
                }`,
          text: DOMPurify.sanitize(marked(x.information_text))
        }))
        .value();
    },
    /**
     * 表示名の加工
     * @returns {String} 表示名
     */
    dispName() {
      return _.isEmpty(this.activeUser.userName)
        ? this.activeUser.accountName
        : this.activeUser.userName;
    },
    /**
     * 事業所情報の加工
     * @returns {Array} 事業所情報
     */
    customOffices() {
      return _(this.activeOffices || [])
        .map(x => ({
          officeId: x.officeId,
          officeName: x.officeName,
          address: x.address,
          date: {
            begin: x.beginDt ? dayjs(x.beginDt).format("YYYY.MM.DD") : "",
            end: x.endDt ? dayjs(x.endDt).format("YYYY.MM.DD") : ""
          }
        }))
        .value();
    },
    /**
     * ログイン判定フラグ
     * @returns {Boolean} ログイン判定フラグ (true: ログイン中)
     */
    canLogin() {
      return (
        !this.loginDialog.isLoading &&
        this.rule_required(this.loginDialog.accountName) === true &&
        this.rule_length255(this.loginDialog.accountName) === true &&
        this.rule_required(this.loginDialog.password) === true &&
        this.rule_length255(this.loginDialog.password) === true
      );
    },
    /**
     * パスワード変更判定フラグ
     * @returns {Boolean} パスワード変更判定フラグ (true: 変更可能)
     */
    canChangePass() {
      return (
        !this.changePassDialog.isLoading &&
        this.rule_required(this.changePassDialog.password1) === true &&
        this.rule_length255(this.changePassDialog.password1) === true &&
        this.rule_required(this.changePassDialog.password2) === true &&
        this.rule_length255(this.changePassDialog.password2) === true &&
        this.changePassDialog.password2 === this.changePassDialog.password3
      );
    },
    /**
     * 保存判定フラグ
     * @returns {Boolean} 保存判定フラグ (true: 保存可能)
     */
    canSaveInfo() {
      return (
        this.rule_required(this.infoDialog.editedItem.userName) === true &&
        this.rule_email(this.infoDialog.editedItem.userMail) === true &&
        !this.infoDialog.isLoading
      );
    },
  },
  watch: {
    /**
     * お知らせ情報変更時の処理
     * @param {Array} val お知らせ情報
     */
    activeInfos(val) {
      if (_.isArray(val) && val.length > 0) {
        if (_.isNil(this.token)) {
          this.loginDialog.visibleInfos = true;
        }
      }
    },
    /**
     * ログインダイアログ状態変更時の処理
     */
    "loginDialog.visible"(val) {
      if (val) {
        this.updateInfos();
        if (!_.isNil(this.loginDialog.hTimer)) {
          clearInterval(this.loginDialog.hTimer);
        }
        this.loginDialog.hTimer = setInterval(() => {
          this.updateInfos();
        }, config.info_duration);
      } else {
        if (!_.isNil(this.loginDialog.hTimer)) {
          clearInterval(this.loginDialog.hTimer);
          this.loginDialog.hTimer = null;
        }
      }
    },
    /**
     * パスワードの状態変更時の処理
     */
    "changePassDialog.password2"() {
      this.$refs.repass.validate();
    },
    // token の変更を非同期で監視
    async token(val) {
      // トークンが無効な場合
      if (!val) {
        // localStorage
        localStorage.removeItem("token");

        // dialog
        this.loginDialog.visible = true;
        return; // ここで早期リターン
      }

      // 以下はトークンが有効な場合の処理

      // localStorage
      localStorage.setItem("token", val);

      // dialog
      this.loginDialog.visible = false;
      try {
        if (this.$refs.loginDialog && typeof this.$refs.loginDialog.reset === 'function') {
          this.$refs.loginDialog.reset();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Error resetting login dialog:", e);
      }

      this.loginDialog.visiblePassword = false;
      this.loginDialog.errMessage = "";
    },
  },
  /**
   * vueマウント時の処理
   */
  async mounted() {
    document.documentElement.style.setProperty('--key-color', config.system_key_color);
    this.onDebouncedScroll = _.debounce(this.onScroll, 100);
    window.addEventListener("scroll", this.onDebouncedScroll);
    this.addSafariClass();
    // tokenをキャッシュから復帰できるか確認
    let token = localStorage.getItem("token");
    if (!_.isEmpty(token)) {
      await this.$store.dispatch("initUser", { token });
    }

    // ユーザ情報の更新
    this.updateInfos();
    this.loginDialog.hTimer = setInterval(() => {
      this.updateInfos();
    }, config.info_duration);

    // 定期的なping送信の開始
    setInterval(async () => {
      if (!_.isNil(this.token)) {
        let pong = await this.$store.dispatch("ping");
        debug(pong);
      }
    }, 1000 * 60);
    this.pathPrefix = this.$route.path.substring(0, 9) == '/_guests/' ? '_guests' : 'guests';
  },
  /**
   * vue破棄時の処理
   */
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    /**
     * サファリ判定
     */
    addSafariClass() {
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    /**
     * 必須入力ルール
     * @param {String} value 入力値
     * @returns {Boolean|String} true: 正常, String: エラーメッセージ
     */
    rule_required(value) {
      return !!value || "空欄にはできません。";
    },
    /**
     * 電子メール入力ルール
     * @param {String} value 入力値
     * @returns {Boolean|String} true: 正常, String: エラーメッセージ
     */
    rule_email(value) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "メールアドレスが正しくありません。";
    },
    /**
     * パスワード入力ルール
     * @param {*} value 
     * @returns true: 正常, エラー文字列
     */
     rule_pwcheck(value) {
      // eslint-disable-next-line no-console
      console.log(value);
      /* @todo パスワードのルールを適用する場合はコメントアウトを外す
         @see https://cd-inc.backlog.com/view/SOIN_DEVELOPMENT-1550

      // 英大文字、英小文字、数字、記号を含むかどうかをチェック
      var upperCaseRegex = /[A-Z]/;
      var lowerCaseRegex = /[a-z]/;
      var digitRegex = /[0-9]/;
      var symbolRegex = /[\W_]/; // 記号の正規表現

      if (
        value.length < 10 // 10文字以上かどうかをチェック
        || !upperCaseRegex.test(value)
        || !lowerCaseRegex.test(value)
        || !digitRegex.test(value)
        || !symbolRegex.test(value)) {
        return "パスワードは10文字以上で英大文字・小文字、数字、記号を含む必要があります。";
      }
      */
      return true;
    },
    /**
     * パスワード２入力ルール
     * @param {String} value 入力値
     * @returns {Boolean|String} true: 正常, String: エラーメッセージ
     */
    rule_repass(value) {
      return (
        this.changePassDialog.password2 === value ||
        "パスワードが一致しません。"
      );
    },
    /**
     * 文字数255文字制限ルール
     * @param {String} value 入力値
     * @returns {Boolean|String} true: 正常, String: エラーメッセージ
     */
    rule_length255(value) {
      return (
        (!_.isEmpty(value) && value.length < 256) ||
        "255文字以内にしてください。"
      );
    },
    /**
     * ログイン処理
     */
    async login() {
      this.loginDialog.isLoading = true;
      try {
        let res = await this.$store.dispatch("login", {
          accountName: this.loginDialog.accountName,
          accountPassword: this.loginDialog.password,
          rememberMe: this.loginDialog.rememberMe
        });
        if (res) {
          this.$store.dispatch("closeLoginDialog");
          this.loginDialog.isLoading = false;
          this.$refs.loginDialog.reset();
        }
      } catch (err) {
        this.loginDialog.isLoading = false;
        this.loginDialog.errMessage = err.message;
      }
    },
    /**
     * お知らせ更新
     */
    async updateInfos() {
      await this.$store.dispatch("updateInfos", {
        targetDtm: dayjs().toISOString()
      });
    },
    /**
     * パスワード変更ダイアログ表示
     */
    showChangePassDialog(value) {
      if (this.changePassDialog.visible !== value) {
        this.changePassDialog.visible = value;
        if (value) {
          // DO NOTHING
        } else {
          this.$refs.changePassDialog.reset();
          this.changePassDialog.visiblePassword1 = false;
          this.changePassDialog.visiblePassword2 = false;
          this.changePassDialog.visiblePassword3 = false;
          this.changePassDialog.errMessage = "";
        }
      }
    },
    /**
     * パスワード変更処理
     */
    async changePassword() {
      this.changePassDialog.isLoading = true;

      let oldPassword = this.changePassDialog.password1;
      let newPassword = this.changePassDialog.password2;
      try {
        await this.$store.dispatch("changePassword", {
          oldPassword,
          newPassword
        });
        this.showChangePassDialog(false);
        this.changePassDialog.isLoading = false;
      } catch (err) {
        this.changePassDialog.isLoading = false;
        this.changePassDialog.errMessage = err.message;
      }
    },
    /**
     * ログアウトダイアログ表示
     */
    showLogoutDialog(value) {
      this.logoutDialog.visible = value;
    },
    /**
     * ログアウト処理
     */
    logout() {
      this.logoutDialog.isLoading = true;
      _.delay(() => {
        this.$store.dispatch("logout");
        this.logoutDialog.visible = false;
        this.logoutDialog.isLoading = false;
        // ログアウト時は/guestへ誘導
        this.$router.replace(`/${this.pathPrefix}`).catch(() => {});
      }, 500);
    },
    /**
     * ユーザ情報ダイアログの表示
     * @param {Boolean} value 表示フラグ
     */
    showInfoDialog(value) {
      if (this.infoDialog.visible !== value) {
        if (value) {
          _.assign(
            this.infoDialog.editedItem,
            _.pick(this.activeUser, [
              "userId",
              "accountName",
              "userName",
              "userMail",
            ])
          );
        } else {
          this.$refs.infoDialog.reset();
        }
        this.infoDialog.visible = value;
      }
    },
    /**
     * ユーザ情報更新処理
     */
    async updateUserInfo() {
      this.infoDialog.isLoading = true;
      let userName = this.infoDialog.editedItem.userName;
      let userMail = this.infoDialog.editedItem.userMail;
      let aiModelDefault = 1;
      try {
        await this.$store.dispatch("updateUserInfo", { userName, userMail, aiModelDefault });
        this.showInfoDialog(false);
        this.infoDialog.isLoading = false;
      } catch (err) {
        debug(JSON.stringify(err));
      }
    },
    /**
     * メニュー選択時の処理
     * @param {Number} idx メニュー項目のインデックス
     */
    async clickAccountMenuItem(idx) {
      switch (this.accountMenuItems[idx].id) {
        case 0:
          // 基本情報ダイアログを開く
          this.showInfoDialog(true);
          break;
        case 1:
          // パスワード変更ダイアログを開く
          this.showChangePassDialog(true);
          break;
        case 3: // お知らせ表示
          await this.updateInfos();
          if (!this.announceDialog.visible) {
            this.announceDialog.visible = true;
          }
          break;
        case 5:
          // ログアウト確認ダイアログの表示
          this.showLogoutDialog(true);
          break;
        case 6: // ご意見、ご要望
          // SOINご利用者ページ（外部）を別ウィンドウで開く
          open(config.request, "_blank");
          break;
        case 7: // アンケート(別ウィンドウ)
          open(config.enquete, "_blank");
          break;
        case 8: // マニュアル(別ウィンドウ)
          open(config.manual, "_blank");
          break;
      }
    },
    /**
     * スクロール時の処理
     */
    onScroll() {
      this.offsetTop = window.scrollY;
    }
  },
};
</script>


<style lang="scss">
@import "@/scss/global.scss";

@media print {
  @page {
    size: A4;
    margin: 9.7mm 12.7mm;
  }
  body {
    min-width: 200mm; /* needed for Chrome */
  }
}

html {
  font-size: 16px;
  overflow-x: auto;
}
html,
body {
  @media print {
    //min-width: $width_screensize_threshold;
    background-color: transparent;

  }
}
body {
  //position: relative;
  button.v-btn.text-primary:not(.steptab__circle) {
    border-color: var(--key-color) !important;
    background-color: var(--key-color) !important;
    color: #ffff !important;
  }
  button.v-btn.text-primary.steptab__circle {
    border-color: var(--key-color) !important;
    color: var(--key-color) !important;
  }
  button.v-btn.text-secondary {
    color: #CF1F1F !important;
  }
  div.v-dialog button.v-btn.text-secondary {
    border: 1px solid #fbcaca !important;
    background-color: #fdf6f6 !important;
  }
  button.v-btn.bg-primary{
    border-color: var(--key-color) !important;
    background-color: var(--key-color) !important;
    color: #ffff !important;
  }
  -webkit-text-size-adjust: 100%;
  .v-toolbar-title{
    flex:none !important;
  }
  i.mdi-trash-can {
    color: #e62323 !important;
    caret-color: #e62323 !important;
  }
  button.v-btn.type_button_primary {
    background-color: var(--key-color) !important;
    border-color: var(--key-color) !important;
    color: #fff !important;
    -webkit-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)
  }

  line-height: $lineheight_default;

  background-color: var(--v-lightgray);
  color: var(--v-gray);

  * {
    box-sizing: border-box;
    font-size: $fontsize_default;
    font-family: $font-default;
  }
  *::selection {
    background-color: rgba(0, 0, 0, 0.25);
    color: white;
  }
}
div#autoScroll{
  bottom: 11px;
  position: fixed;
  z-index: 9;
  right: 13px;
  width: 100px;
  height: 24px;
  display:flex;
  flex-wrap:nowrap;
  flex-direction:row;
  button {
    position: relative !important;
    display:block;
    background-color: inherit;
    height:auto;
    min-width: 40px;
    padding:0;
    z-index:9;
    box-shadow: none !important;
    width: 40px;
    margin-right: 10px;
  }
}
h2,
h3,
h4 {
  font-weight: bold;
  color: var(--v-gray);
  line-height: 1.5em;
}
h2 {
  font-size: 1.6rem;
}
h3 {
  font-size: 1.5rem;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.1rem;
  margin-bottom: 0.5em;
}
.v-toolbar__content > .v-toolbar-title {
    margin-inline-start: 10px !important;
}
.v-chip.v-size--small {
  border-radius: 4px !important;
}
mark {
  padding: 0.2em 0.5em 0.3em 0.5em;
  border-radius:3px;
  &.alert{
    background-color: #FEDAEF;
    color: #AE0900;
  }
  &.caution {
    background-color: #FFF9D9;
    color: #826804;
  }
  &.normal {
    background-color: #DAFEEC;
    color: #007D3F;
  }
}

/* ケアプラン編集＞サービス画面で「ケアプランを作成」と「※ 回数を含めて予測します（福祉用具貸与を除く）」が被る対策 */
div.care-plan-edit div.v-row.mb-6.services__title.v-row--no-gutters {
  height: auto;
}
.site-header__logo h1{
  background-position: 0 7px !important;

}
/* バージョン情報表示位置(SP以外) [全画面共通]*/
header {
  div.v-toolbar__title{
    overflow: inherit;
    margin-right: 1rem;
  }
  div.v-toolbar__items{
    span.v-chip__content{
      font-size:1rem;
      a span {
        font-size:1rem;
        font-weight:bold;
        i {
          font-size:1.2rem;
        }
      }
    }
    .mx-2 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}
header span.version_area{
  position: absolute;
  display: block;
  left: 42px;
  bottom: 11px;
  font-size: 10px;
  color: #888;
  font-weight: bold;
  padding-right: 28px !important;
  line-height:0;
}
#app-bar-username-outer {
  margin-right: 20px;
}

@media screen and (max-width: 960px) {
  #app-bar-username-outer {
    margin-right: 3px;
  }
}
/* ----------------------スマホ向け設定 ---------------------- */
div.sp_mode {
  .v-toolbar-title{
    margin-inline-start: 0;
  }
  /* バージョン情報表示位置(SPのみ) [全画面共通]*/
  header span.version_area{
    position: absolute;
    display: block;
    right:0;
    top:40px;
    font-size:10px;
    padding-right: 18px !important;
  }
  /* ROWに設定されたマージンを初期化(SPのみ) [全画面共通]*/
  .v-row {
    margin:0;
  }

  /* スマホ表示時の各要素を再定義(SPのみ) [全画面共通]*/
  h2{
    font-size:1.1rem;
    padding-left: 8px;
  }
  h2 b{
    font-size:1.1rem !important;
  }
  h3{
    font-size:1.1rem;
    padding-left: 1rem;
    padding-bottom: 0.5rem !important;
    background: $color_section_bg !important;
  }
  div.basic_care div h3{
    border: 3px solid var(--key-color) !important;
    border-radius: 0.3em;
    color: #041a2a;
    background: #fafdff !important;
  }
  div.careplan {
    padding:0 !important;
  }
  div.v-container>div.pa-2 {
    padding: 0 !important;
    div.pa-6, 
    div.mb-6 {
      padding: 4px !important;
    }
  }

  /* スマホ表示時のヘッダ部分の定義  [全画面共通]*/
  header {
    div.v-toolbar__content{
      padding: 0 6px !important;
    }
    /* スマホ表示時のヘッダ部分のユーザー名表示エリア  [全画面共通]*/
    span.v-chip__content {
        font-size: 0.9rem;
    }
  }

  /* Safariで非表示領域まで横スクロールしてしまう対応 */
  main {
    overflow: hidden;
    @media print {
      overflow: visible!important;
    }
  }

  /* スマホ表示時のヘッダ下の「ケアプラン新規作成」等 のメニューエリア [全画面共通]*/
  div.v-toolbar-title div.py-2{
    text-align: left !important;
    button {
      padding: 0 6px;
      margin-left: 6px;
      height: 36px !important;
      span{
        font-weight: bold;
        font-size: 0.8rem;
      }
      i{
        margin-right: 3px;
      }
    }
  }

  /* スマホ表示時のメインコンテンツ部分の定義 [全画面共通]*/
  main>div.v-container {
      padding: 0 !important;
  }

  /* スマホ表示時のテーブルの中のＤＩＶの文字サイズ（主に利用者一覧） [全画面共通]*/
  td.v-data-table__mobile-row div {
      font-size: 0.9rem;
  }

  /* モーダル・ダイアログ */
  div.v-dialog__content div.v-dialog {
    margin:8px !important;
    div.v-card__text {
      padding: 16px !important;
      div {
        font-size: 0.8rem !important;
      }
    }
    div.v-card__text div.container div.row div {
      &.col,
      &.col-md-6 {
        padding: 0 !important;
      }
    }
    h3 {
      padding:0 !important;
      margin:0 !important;
      background: none !important;
    }
    pre {
      white-space:inherit;
    }
  }
  /* LIFE読み込みボタン */
  div.home>div.v-container>div.v-row>div.v-col-12>div.v-row div.v-col.v-col-auto{
    padding:0;
  }
  /* 利用者一覧の各利用者との隙間を開ける */
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child{
    border-bottom: 5px solid rgba(0, 0, 0, 0.12);
  }
}

div.sp_mode div.v-application__wrap main div.v-container {
  div.care-plan-edit.pa-2{
    margin-bottom: 50px;
  }
  /* スマホ表示時のケアプラン「閲覧/編集」モードの「比較」画面 [ケアプラン閲覧/編集]*/
  div.care-plan-edit.pa-2,
  div.comparing_plans {
    &.v-col.v-col-12,
    &>div.v-container.pa-0.v-container--fluid>div.v-row>div.v-col.v-col-12>div.mb-6.v-sheet.elevation-5{
      padding:8px !important;
      div.mb-6.v-sheet>div.v-container,
      div.v-container.pa-6.v-container--fluid{
        padding: 0 !important;
        &>div.v-row>div.pa-0>div.v-row:nth-of-type(1) {
          margin: 0;
        }
        &>div.v-row>div.pa-0>div.v-row.comparing-selected {
          margin:0 !important;
          &>div {
            display:block !important;
            margin: 0 0 10px 0;
            min-width:100%;
            padding:10px !important;
            background-color: #FCFCFC;
            border:1px solid #ddd;
            border-radius: 6px;
          }
        }
      }
    }
  }

  /* 余白調整 */
  div.v-container--fluid div.careplan__container div.v-col,
  div.v-container--fluid>div.v-row>div.v-col,
  div.v-container--fluid div.v-row div.services__current-mobile,
  div.v-container--fluid div.v-row div.services__refer-mobile{
    padding:0;
  }
}

@media screen and (max-width: 420px) {
  /* 解像度が低いスマホの閲覧モードのタブ（基本生活～比較） */
  div.sp_mode div.v-slide-group__content div.v-tab{
    width:70px !important;
  }
}

@media screen and (max-width: 330px) {
  /* 解像度が低いスマホのヘッダの調整 */
  div.sp_mode header div.v-chip__content {
    font-size: 0.7rem;
  }
  /* 解像度が低いスマホの閲覧モードのタブ（基本生活～比較） */
  div.sp_mode div.v-slide-group__content{
    display: block;
    div.v-tab{
      font-size: 0.9rem !important;
      line-height: 1rem;
      min-width: 60px;
      width:60px !important;
      padding:0 4px;
      height:50px;
      display: table-cell;
      vertical-align: middle;
      span{
        font-size: 0.9rem !important;
        display: inline-block;
      }
    }
  }
  /* 解像度が低いスマホの編集モードのタブ（基本生活～比較） */
  div.sp_mode div.v-application__wrap main div.v-container {
    div.care-plan-edit {
      div.steptab {
        min-height: 56px !important;
        div.steptab__item {
          margin: 0 1px;
          button.steptab__circle{
            width: 56px !important;
            height: 56px !important;
            span div.steptab__content span {
              font-size: 1rem !important;
              line-height:1rem
            }
          }
        }
      }
    }
  }
}
.nowrap{
  white-space: nowrap;
}

.v-expansion-panel-text__wrapper h4 span{
 font-size: 1.3rem;
}

.v {
  &-text-field {
    input {
      line-height: 1em !important;
    }
  }
  &-messages__message {
    font-size: 0.8rem;
    line-height: 1em !important;
  }
  &-slide-group__prev {
    display: none !important;
  }
}

// transition
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.hide {
  &--print {
    @media print {
      display: none !important;
    }
  }
  &--overflow {
    overflow-x: hidden;
  }
}

// md
.v-application {
  @media print {
    background-color: transparent !important;
  }

  .markdown {
    * {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.8em;
      margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      color: black;
      margin: 0.5em 0 0.1em 0;
      line-height: 1.5em;
    }
    h1 {
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1.3rem;
    }
    h3 {
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1.1rem;
    }
    h5 {
      font-size: 1rem;
    }
    h6 {
      font-size: 1rem;
      font-weight: normal;
    }

    p {
      margin-bottom: 1.4em;
    }

    ul,
    ol,
    blockquote,
    code {
      margin-bottom: 1.6em;
    }

    ul,
    ol {
      ul,
      ol {
        margin-bottom: 0;
      }
    }

    blockquote {
      border-left: 5px solid $color_gray_d;
      padding-left: 10px;
    }

    pre {
      code {
        width: 100%;
      }
    }

    code {
      box-shadow: none;
      border-radius: 0;
      color: $color_gray;
    }

    hr {
      margin: 0 0 1.6em 0;
      border: 0;
      height: 2px;
      background-color: $color_gray_d;
    }

    em {
      font-style: italic;
    }
    strong,
    strong * {
      font-weight: bold;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    table {
      border-collapse: collapse;

      th,
      td {
        padding: 0 10px;
      }

      thead {
        th {
          font-weight: bold;
          border-bottom: 2px solid $color_gray;
        }
      }
      tbody {
        tr:nth-child(2n - 1) {
          background-color: $color_gray_f;
        }
        td {
          border-bottom: 1px solid $color_gray_d;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.site {
  // Header
  &-header {
    .v-application &.lightgray {
      background-color: transparent !important;
    }

    &__logo {
      h1 {
        width: 85px;
        height: 48px;

        @include blank_block;

        background-image: url(./assets/logo-soin.svg);
        background-position: left center;
        background-size: 85px 32px;
      }
    }

    &__tolist {
      //box-shadow: none;
    }

    &__account {
      &-name {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        color: $color-gray;
        cursor: pointer;

        transition: $transition_backgroundcolor;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &-menuitem {
        padding: 10px 30px 10px 15px;
        border-bottom: 1px solid $color_gray_b;
        transition: $transition_backgroundcolor;

        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:last-child {
          border: 0;
        }

        &--blank {
          position: relative;
          &:after {
            position: absolute;
            right: 10px;

            display: block;
            width: 12px;
            height: 12px;
            content: "";

            background-image: url(./assets/icon-newwindow.svg);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }
  }
}

// Dialog
.login {
  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    @include blank_block;

    height: 80px;
    background-image: url(./assets/logo-soin.svg);
  }

  &__form {
    & > * {
      margin-bottom: 10px;
    }
  }

  &__button {
    width: 100%;
  }
}

@media print {
  .v-dialog__content {
    display: none !important;
  }
}

</style>
