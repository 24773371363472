import config from '../config';

import { createApp } from 'vue';
import { createStore } from 'vuex';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import "dayjs/locale/ja";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import api from '../data/server-api';
import adminapi from '../data/admin-api';
// Day.jsの設定
dayjs.locale(config.locale);
dayjs.extend(isSameOrBefore);

const debug = require('debug')('soin-client:store');

function p401(context) {
  return (err) => {
    if (err && err.response && err.response.status && (err.response.status === 401 || err.response.status === 403)) {
      context.commit('setToken', null);
    } else {
      throw err;
    }
  }
}

// Vueアプリケーションの作成
import App from '../App.vue'; // ルートコンポーネントのインポート
const app = createApp(App);

// Vuexストアの作成
const store = createStore({
  strict: true,
  state: {
    token: null,

    //visibleLoginDialog: false,
    isInfosLoading: false,
    infos: [],

    isUserLoading: false,
    user: {
      userId: null,
      accountName: null,
      roles: [],
      userName: null,
      email: null,
    },
    isSexTypesLoading: false,
    sexTypes: [],

    isOfficesLoading: false,
    offices: [],

    isGuestsLoading: false,
    guests: [],
    selectedGuestId: null,

    isCheckListsLoading: false,
    checkLists: [],
    selectedCheckListId: null,
    isCheckListDeleting: false,
    isCheckListCreating: false,
    isCheckListSaving: false,

    serviceItems: [],

    isConditionsSaving: false,

    isServiceItemsOfCheckListUpdating: false,

    isFrailAiLoading: false,
    isForecastConditionsLoading: false,

    pagination: {
      page: 1,
      rowsPerPage: 5,
      sortBy: 'guestId',
      descending: false,
    },
    fiscalYear: null,
    facilities: null,
    hists: null,
    selectedHist: null,

    // admin ------------
    adminToken: null,
    admin: {
      userId: null,
      accountName: null,
      roles: [],
      userName: null,
      email: null,
    },
    users: [],
    storedPageOptions: null, // カスタマーページングオプション
    storedHistOptions: null, // 履歴ページングオプション
    debugPrint: '',
  },
  mutations: {
    /**
     * トークン設定
     * @param {*} state 
     * @param {*} value 設定値 
     */
    setToken(state, value) {
      let _now = Date.now();
      state.token = value;
      debug(`[M] setToken: ${value} ... ${Date.now() - _now} msec.`)
    },
    showLoginDialog(state, value) {
      let _now = Date.now();
      state.visibleLoginDialog = value;
      debug(`[M] showLoginDialog: ${value} ... ${Date.now() - _now} msec.`)
    },
    setInfosLoading(state, value) {
      let _now = Date.now();
      state.isInfosLoading = value;
      debug(`[M] setInfosLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setInfos(state, infos) {
      let _now = Date.now();
      state.infos = infos;
      debug(`[M] setInfos: ${infos} ... ${Date.now() - _now} msec.`)
    },
    setUserLoading(state, value) {
      let _now = Date.now();
      state.isUserLoading = value;
      debug(`[M] setUserLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setUser(state, user) {
      let _now = Date.now();
      state.user = user;
      debug(`[M] setUser: ${user} ... ${Date.now() - _now} msec.`)
    },
    setSexTypesLoading(state, value) {
      let _now = Date.now();
      state.isSexTypesLoading = value;
      debug(`[M] setSexTypesLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setSexTypes(state, value) {
      let _now = Date.now();
      state.sexTypes = value;
      debug(`[M] setSexTypes: ${value} ... ${Date.now() - _now} msec.`)
    },
    setOfficesLoading(state, value) {
      let _now = Date.now();
      state.isOfficesLoading = value;
      debug(`[M] setOfficesLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setOffices(state, offices) {
      let _now = Date.now();
      state.offices = offices;
      debug(`[M] setOffices: ${offices} ... ${Date.now() - _now} msec.`)
    },
    setGuestsLoading(state, value) {
      let _now = Date.now();
      state.isGuestsLoading = value;
      debug(`[M] setGuestsLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setGuests(state, guests) {
      let _now = Date.now();
      state.guests = guests;
      debug(`[M] setGuests: ${guests} ... ${Date.now() - _now} msec.`)
    },
    setHists(state, hists) {
      let _now = Date.now();
      state.hists = hists;
      debug(`[M] setHists: ${hists} ... ${Date.now() - _now} msec.`)
    },
    setSelectedGuestId(state, id) {
      let _now = Date.now();
      state.selectedGuestId = id;
      debug(`[M] setSelectedGuestId: ${id} ... ${Date.now() - _now} msec.`)
    },
    setCheckListsLoading(state, value) {
      let _now = Date.now();
      state.isCheckListsLoading = value;
      debug(`[M] setCheckListsLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setCheckLists(state, checkLists) {
      let _now = Date.now();
      state.checkLists = checkLists;
      debug(`[M] setCheckLists: ${checkLists} ... ${Date.now() - _now} msec.`)
    },
    setSelectedCheckListId(state, id) {
      let _now = Date.now();
      state.selectedCheckListId = id;
      debug(`[M] setSelectedCheckListId: ${id} ... ${Date.now() - _now} msec.`)
    },
    setCheckListDeleting(state, value) {
      let _now = Date.now();
      state.isCheckListDeleting = value;
      debug(`[M] etCheckListDeleting: ${value} ... ${Date.now() - _now} msec.`)
    },
    setCheckListCreating(state, value) {
      let _now = Date.now();
      state.isCheckListCreating = value;
      debug(`[M] setCheckListCreating: ${value} ... ${Date.now() - _now} msec.`)
    },
    setCheckListSaving(state, value) {
      let _now = Date.now();
      state.isCheckListSaving = value;
      debug(`[M] setCheckListSaving: ${value} ... ${Date.now() - _now} msec.`)
    },
    setServiceItems(state, value) {
      let _now = Date.now();
      state.serviceItems = value;
      debug(`[M] setServiceItems: [] ... ${Date.now() - _now} msec.`)
    },
    setCheckListChecklists(state, value) {
      let _now = Date.now();
      if (_.isArray(state.checkLists) && state.selectedCheckListId !== null) {
        let selectedCheckList = _.find(state.checkLists, x => x.checkListId === state.selectedCheckListId)
        selectedCheckList.checklists = value;
      }
      debug(`[M] setCheckListChecklists: ${value} ... ${Date.now() - _now} msec.`)
    },
    setCheckListConditonsSaving(state, value) {
      let _now = Date.now();
      state.isConditionsSaving = value;
      debug(`[M] setCheckListConditonsSaving: ${value} ... ${Date.now() - _now} msec.`)
    },
    setServiceItemsOfCheckList(state, { serviceType, serviceItems }) {
      //let _now = Date.now();
      let checkListId = state.selectedCheckListId;
      if (checkListId) {
        let checkList = _.find(state.checkLists, x => x.checkListId === checkListId);
        if (checkList) {
          if (_.isNil(checkList.servicePlans[serviceType])) {
            checkList.servicePlans[serviceType] = { items : serviceItems };
          } else {
            checkList.servicePlans[serviceType].items = serviceItems
          }
        }
      }
      //debug(`[M] setServiceItemsOfCheckList: {${serviceType}, ${serviceItems}} ... ${Date.now() - _now} msec.`)
    },
    setResultsOfCheckList(state, { results }) {
      //let _now = Date.now();
      let checkListId = state.selectedCheckListId;
      if (checkListId) {
        let checkList = _.find(state.checkLists, x => x.checkListId === checkListId);
        if (checkList) {
          checkList.results = results;
          let date = new Date();
          checkList.update = date.toLocaleString();
        }
      }
      //debug(`[M] setServiceResultsOfCheckList: {${serviceType}, ${results}} ... ${Date.now() - _now} msec.`)
    },
    setServiceItemsOfCheckListUpdating(state, value) {
      let _now = Date.now();
      state.isServiceItemsOfCheckListUpdating = value;
      debug(`[M] setServiceItemsOfCheckListUpdating: ${value} ... ${Date.now() - _now} msec.`)
    },
    setFrailAiLoading(state, value) {
      let _now = Date.now();
      state.isFrailAiLoading = value;
      debug(`[M] setFrailAiLoading: ${value} ... ${Date.now() - _now} msec.`)
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setFiscalYear(state, value) {
      state.fiscalYear = value;
    },
    setFacilities(state, value) {
      let _now = Date.now();
      state.facilities = value;
      debug(`[M] setFacilities: ${value} ... ${Date.now() - _now} msec.`)
    },

    // 管理 -----------------------------------------------
    setAdminToken(state, value) {
      let _now = Date.now();
      state.adminToken = value;
      debug(`[M] setAdminToken: ${value} ... ${Date.now() - _now} msec.`)
    },
    setAdminUser(state, value) {
      let _now = Date.now();
      state.admin = value;
      debug(`[M] setAdminUser: ${value} ... ${Date.now() - _now} msec.`)
    },
    setUsers(state, users) {
      let _now = Date.now();
      state.users = users;
      debug(`[M] setUsers: ${users} ... ${Date.now() - _now} msec.`)
    },
    setPageOptions(state, pageOptions) {
      let _now = Date.now();
      state.storedPageOptions = pageOptions;
      debug(`[M] setPageOptions: ${pageOptions} ... ${Date.now() - _now} msec.`)
    },
    setHistOptions(state, pageOptions) {
      let _now = Date.now();
      state.storedHistOptions = pageOptions;
      debug(`[M] setHistOptions: ${pageOptions} ... ${Date.now() - _now} msec.`)
    },
    setDebugPrint(state, value) {
      state.debugPrint = value;
    },
    addDebugPrint(state, value) {
      state.debugPrint += value;
    }
  },
  actions: {
    openLoginDialog({ commit }) {
      debug('openLoginDialog()');

      commit('showLoginDialog', true);
    },
    closeLoginDialog({ commit }) {
      debug('closeLoginDialog()');

      commit('showLoginDialog', false);
    },
    async updateInfos(context, { targetDtm }) {
      debug(`updateInfos({targetDtm:'${targetDtm}'})`);

      targetDtm = targetDtm ? dayjs(targetDtm).toISOString() : dayjs().toISOString();

      let infos = await api.findInfos({ targetDtm });
      context.commit('setInfos', infos);
      return true;
    },
    async login(context, { accountName, accountPassword, rememberMe }) {
      debug(`login('${accountName}', '${accountPassword}', ${rememberMe})`);

      try {
        // 認証
        let token = await api.authenticate({ accountName, accountPassword, rememberMe }).catch(p401(context));
        /*
        if (token) {
          // ユーザ情報取得
          let user = await api.findSelf({ token }).catch(p401(context));
          context.commit('setUser', user);
          // ログインしたユーザに関するデータ取得
          if (_.isNil(context.getters.fiscalYear)) {
            context.commit("setFiscalYear", Number(dayjs().subtract(3, 'M').format('YYYY')));
          }
          await Promise.all([
            context.dispatch('updateOffices', token),
            context.dispatch('updateGuests', token),
            context.dispatch('updateSexTypes', token),
          ]);

          // loginDialogを閉じる
          context.commit('setToken', token);
        }

        return true;
        */
        return await context.dispatch('initUser', { token });
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async logout(context) {
      debug(`logout()`);

      context.commit('setToken', null);
      context.commit('setAdminToken', null);
    },

    async initUser(context, { token }) {
      debug(`initUser('${token}')`);

      // ユーザ情報取得
      try {
        let user = await api.findSelf({ token });
        if (user) {
          context.commit('setUser', user);
        } else {
          context.commit('setUser', null);
          context.commit('setToken', null);
          return false;
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          context.commit('setToken', null);
        } else {
          throw err;
        }
        return false;
      }
      if (_.isNil(context.getters.fiscalYear)) {
        context.commit("setFiscalYear", Number(dayjs().subtract(3, 'M').format('YYYY')));
      }

      // ログインしたユーザに関するデータ取得
      await Promise.all([
        context.dispatch('updateOffices', token),
        context.dispatch('updateGuests', token),
        context.dispatch('updateSexTypes', token),
      ]);

      // （ユーザ取得できていれば）トークンを登録
      context.commit('setToken', token);
      return true;
    },

    async updateUserInfo(context, { userName, userMail, aiModelDefault }) {
      try {
        let token = context.getters.token;
        let user = await api.updateUserInfo({ token, userName, userMail, aiModelDefault }).catch(p401(context));
        context.commit('setUser', user);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async changePassword(context, { oldPassword, newPassword }) {
      debug(`changePassword('${oldPassword}', '${newPassword}')`);

      try {
        let token = context.getters.token;
        await api.changePassword({ token, oldPassword, newPassword }).catch(p401(context));
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateSexTypes(context, token) {
      debug(`updateSexTypes()`);

      try {
        let _token = token || context.getters.token;
        let sexTypes = await api.findSexTypes({ token: _token }).catch(p401(context));
        context.commit('setSexTypes', sexTypes);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateOffices(context, token) {
      debug(`updateOffices()`);

      try {
        let _token = token || context.getters.token;
        let offices = await api.findOffices({ token: _token }).catch(p401(context));
        context.commit('setOffices', offices);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async createOffice(context, { officeName, officeAddress, areaId }) {
      debug(`createOffice({'${officeName}', '${officeAddress}', '${areaId}'}')`);
      try {
        let [office = []] = await adminapi.createOffice({
          token: context.getters.adminToken,
          officeId: null,
          officeName,
          officeAddress,
          areaId,
        }).catch(p401(context));
        await context.dispatch('updateOffices');
        return office;
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateOffice(context, { officeId, officeName, officeAddress, ordByNo }) {
      debug(`updateOffice('${officeId}', '${officeName}', '${officeAddress}', '${ordByNo}')`);
      try {
        let token = context.getters.adminToken;
        await adminapi.updateOffice({
          token,
          officeId,
          officeName,
          officeAddress,
          ordByNo
        }).catch(p401(context));
        await context.dispatch('updateOffices');
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async deleteOffice(context, { officeId }) {
      debug(`deleteOffice('${officeId}')`);
      try {
        // alert(`${token}, ${userId}`);
        await adminapi.deleteOffice({
          token: context.getters.adminToken,
          officeId,
        }).catch(p401(context));
        await context.dispatch('updateOffices');
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateGuests(context, token) {
      debug(`updateGuests('${token}')`);

      try {
        let _token = token || context.getters.token;
        let guests = await api.findGuests({ token: _token }).catch(p401(context));
        context.commit('setGuests', guests);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateHists(context, token) {
      debug(`updateHists()`);
      try {
        let _token = token || context.getters.token;
        let hists = await api.findHists({ token: _token }).catch(p401(context));
        context.commit('setHists', hists);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async createGuest(context, { officeId, insuranceId, lastName, firstName, address, sexTypeCd, birthDt, managementNo }) {
      debug(`createGuest('${officeId}', '${insuranceId}', '${lastName}', '${firstName}', '${address}', '${sexTypeCd}', '${birthDt}', '${managementNo}')`);

      try {
        let token = context.getters.token;
        let guest = await api.createGuest({ token, officeId, insuranceId, guestLastName: lastName, guestFirstName: firstName, guestAddress: address, sexTypeCd, guestBirthDt: birthDt,
          managementNo
        }).catch(p401(context));
        await context.dispatch('updateGuests');
        return guest;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateGuest(context, { guestId, officeId, insuranceId, lastName, firstName, address, sexTypeCd, birthDt, managementNo }) {
      debug(`updateGuest('${guestId}', '${officeId}', '${insuranceId}', '${lastName}', '${firstName}', '${address}', '${sexTypeCd}', '${birthDt}', '${managementNo}')`);

      try {
        let token = context.getters.token;
        await api.updateGuest({ token, guestId, officeId, insuranceId, guestLastName: lastName, guestFirstName: firstName, guestAddress: address, sexTypeCd, guestBirthDt: birthDt,
          managementNo
        }).catch(p401(context));
        await context.dispatch('updateGuests');
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async deleteGuest(context, { guestId }) {
      debug(`deleteGuest('${guestId}')`);

      try {
        let token = context.getters.token;
        await api.deleteGuest({ token, guestId }).catch(p401(context));
        await context.dispatch('updateGuests');
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async selectGuest(context, { guestId }) {
      debug(`selectGuest('${guestId}')`);

      if (_.some(context.getters.activeGuests || [], x => x.guestId === guestId)) {
        context.commit('setSelectedGuestId', guestId);
        await context.dispatch('updateCheckLists');
        return true;
      } else {
        return false;
      }
    },
    async createCheckList(context, { guestId, targetDt }) {
      debug(`createCheckList('${guestId}', '${targetDt}')`);

      try {
        let token = context.getters.token;
        let checklists = {};
        let results = null;
        targetDt = dayjs(targetDt).toISOString();

        // 同日のデータがあればそれを返す
        let same = _(context.getters.activeCheckLists || [])
          .filter(x => dayjs(x.targetDtm).isSame(targetDt))
          .orderBy(['targetDtm', 'insDtm'], ['desc', 'desc'])
          .head();
        if (same) {
          return same.checkListId;
        }

        // ひとつ前のケアプランからアップデート
        let previous = _(context.getters.activeCheckLists || [])
          .filter(x => dayjs(x.targetDtm).isBefore(targetDt))
          .orderBy(['targetDtm', 'insDtm'], ['desc', 'desc'])
          .head();
        if (previous) {
          checklists = _.cloneDeep(previous.checklists);
          results    = _.cloneDeep(previous.results);
        }
        // 初期値設定
        checklists.defect = 2; // 不備あり:いいえ
        checklists.careLevel  = 1; // 要介護度:自立
        checklists.judge_i_iv = 0;
        // 利用者情報からアップデート
        let guest = context.getters.selectedGuest;
        if (guest) {
          if (checklists.sex !== 1 && checklists.sex !== 2) {
            checklists.sex = guest.sexTypeCd;
          }
          if (_.isEmpty(checklists.age)) { // 空なら誕生日から年齢を計算する
            checklists.age = dayjs().diff(dayjs(guest.birthDt), "years");
          }
        }
        // 作成
        let checkList = await api.createCheckList({ token, guestId, targetDt, checklists, results });
        await context.dispatch('updateCheckLists');
        return checkList.checkListId;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    // *
    // * 利用者に紐づくケアプランの数が多くなるとviewにbindするときのコストが大きくなる（通常ケースではあまり問題なるとは考えにくい）
    // * テストなどで連続登録するケースや長期運用を検討するのであれば、下記はリファクタリングの対象となる。
    // *
    async updateCheckLists(context) {
      debug(`updateCheckLists()`);
      try {
        let token = context.getters.token;
        let checkLists = await api.findCheckLists({
          token,
          guestId: context.getters.selectedGuest.guestId,
        }).catch(p401(context));
        context.commit('setCheckLists', checkLists);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    selectCheckList(context, { checkListId }) {
      debug(`selectCheckList('${checkListId}')`);

      if (checkListId) {
        context.commit('setSelectedCheckListId', checkListId);
        return true;
      } else {
        return false;
      }
    },
    async deleteCheckList(context, { checkListId }) {
      debug(`deleteCheckList('${checkListId}')`);

      try {
        let token = context.getters.token;
        await api.deleteCheckList({ token, checkListId }).catch(p401(context));
        await context.dispatch('updateCheckLists');
        return true;
      } catch (err) {
        throw err.response.data;
      }
    },
    async saveCheckListChecklists(context, { checklists }) {
      debug(`saveCheckListChecklists(${JSON.stringify(checklists)})`);
      try {
        let token = context.getters.token;
        let checkListId = context.state.selectedCheckListId;
        await api.saveCheckListChecklists({ token, checkListId, checklists }).catch(p401(context));
        context.commit('setCheckListChecklists', checklists)
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async saveCheckList(context, { checklists, results }) {
      debug(`saveCheckList('${JSON.stringify(checklists)}', '${JSON.stringify(results)}')`);
      try {
        let token = context.getters.token;
        let checkListId = context.state.selectedCheckListId;
        await api.saveCheckList({ token, checkListId, checklists, results }).catch(p401(context));
        context.commit('setCheckListChecklists', checklists)
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async saveCheckListResults(context, { results }) {
      debug(`saveCheckListResults(${JSON.stringify(results)})`);
      try {
        let token = context.getters.token;
        let checkListId = context.state.selectedCheckListId;
        await api.saveCheckListResults({ token, checkListId, results }).catch(p401(context));
        await context.dispatch('updateGuests');
      //  context.commit('setCheckListResults', results)
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    /**
     * チェックリストPDF出力
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
    async pdfCheckList(context, { guests }) {
      // debug(`pdfCheckList('${JSON.stringify(guests)}'}`);
      try {
        let token = context.getters.token;
        let res = await api.pdfCheckList({ token, guests }).catch(p401(context));
        return res;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    /**
     * 予測PDF出力
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
    async pdfPrediction(context, { guests, fiscalYear }) {
      // debug(`pdfCheckList('${JSON.stringify(guests)}', '${fiscalYear'}`);
      try {
        let token = context.getters.token;
        let res = await api.pdfPrediction({ token, guests, fiscalYear }).catch(p401(context));
        return res;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    // *** NEXT ***
    async clearActiveResults(context) {
      context.commit('setServiceResultsOfCheckList', { serviceType: 'active', results: {} });
    },
    async updateServiceItemsOfCheckList(context, { serviceType, serviceItems }) {
      debug(`updateServiceItemsOfCheckList('${serviceType}', ${JSON.stringify(serviceItems)})`);

      let checkListId = context.state.selectedCheckListId
      if (checkListId && _.isArray(serviceItems)) {
        //context.commit('setServiceItemsOfCheckListUpdating', true);
        let items = _.cloneDeep(serviceItems);
        _.forEach(items, item => {
          item.id = uuid();
        });
        //await api.updateServiceItemsOfCheckList({ checkListId, serviceType, serviceItems: items });
        context.commit('setServiceItemsOfCheckList', { serviceType, serviceItems: items });
        //context.commit('setServiceItemsOfCheckListUpdating', false);
        return true;
      } else {
        return false;
      }
    },
    /**
     * 事業所情報取得
     */
    async updateFacilities(context, { fiscalYear }) {
      debug(`updateFacilities('${fiscalYear}')`);
      try {
        let token = context.getters.token;
        let facilities = await api.findFacilities({ token, fiscalYear }).catch(p401(context));
        context.commit('setFacilities', facilities);
        return true;
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    /**
     *「改善志向プラン」＆「状態が最も近い方のプラン」作成＆状態予測の実行
     * @param {*} context 
     * @param {*} param1 
     */
    async callPredictFrail(context) {
      debug(`callPredictFrail()`);
      let selectedCheckList = context.getters.selectedCheckList;
      let checklists = selectedCheckList.checklists;

      // alert(JSON.stringify(checklists));
      let height = Number(checklists["height"]);
      let weight = Number(checklists["weight"]);
      if (height < 1.0) {
        height = 1.0;
      }
      if (weight < 1.0) {
        weight = 1.0;
      }
      let height100 = height / 100;
      let bmi = weight / height100 / height100;
      if (bmi > 200) {
        bmi = 200;
      }
      let bmi_decrease = checklists["check012"];
      if (_.isNil(bmi_decrease)) {
        bmi_decrease = (bmi < 18.5 ? 1 : 0);
      }
      let query = {
        options: {
           ai_model: 1,
        },
        attribute: {
          sex:        checklists["sex"],
          create_age: Number(checklists["age"]),
        },
        conditions: {
          means_go_out_bus_train:     checklists["check001"],
          shopping:                   checklists["check002"],
          financial_deposits_savings: checklists["check003"],
          visit_friend:               checklists["check004"],
          seeking_advice:             checklists["check005"],
          climbing_stairs:            checklists["check006"],
          getting_up_chair:           checklists["check007"],
          continuous_walking:         checklists["check008"],
          fallen:                     checklists["check009"],
          anxiety_falling:            checklists["check010"],
          weight_loss:                checklists["check011"],
          bmi_decrease:               bmi_decrease,
          height:                     height,  
          weight:                     weight, 
          bmi:                        bmi,
          eat_hard_food:              checklists["check013"],
          whiffing:                   checklists["check014"],
          dry_mouth:                  checklists["check015"],
          go_out:                     checklists["check016"],
          decrease_go_out:            checklists["check017"],
          forget:                     checklists["check018"],
          research_phone_num:         checklists["check019"],
          forget_date:                checklists["check020"],
          mood_fulfillment:           checklists["check021"],
          mood_unenjoyable:           checklists["check022"],
          feeling_bored:              checklists["check023"],
          feeling_useful:             checklists["check024"],
          feeling_tired:              checklists["check025"],
        }
      };
      // alert(JSON.stringify(query));
      let token = context.getters.token;
      try {
        /** 事前バージョンチェック */
        let res = await api.callPredictFrail({ token, query }).catch(p401(context));
        // alert('res' + JSON.stringify(res) + 'res.data.report_info_list = ' + JSON.stringify(res.data.report_info_list));
        let _predict = res.data.predict;
        let data = {
          predict: { // 予測結果
            frail: _predict.frail, // フレイル予測 0: 健常, 1: プレフレイル, 2: フレイル, 3: 要介護認定
            clevel3y: _predict.clevel3y, // ３年以内の要介護認定予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            life_functions: _predict.life_functions, // 生活機能予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            motor_functions: _predict.motor_functions, // 運動機能予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            life_nutritional_conditions: _predict.life_nutritional_conditions, // 栄養状態予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            oral_functions: _predict.oral_functions, // 口腔機能の予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            seclusion: _predict.seclusion, // 閉じこもり予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            cognitive_functions: _predict.cognitive_functions, // 認知機能予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
            depression: _predict.depression, // うつ予測 0: 灰（OFF）, 1: 黄（注意）, 2: 赤（警告）
          },
          others: { // その他
            version: res.data.others.version, // バージョン
            ai_model: res.data.others.ai_model, // AIモデル種別
          },
          results: {
            frail_judge: res.data.results.frail_judge,
          },
        };
        let results = {
          data: [
            (6 - (res.data.results.frail_judge * 2)),
            (6 - (_predict.frail * 2)),
            -1, -1],
          predict: data.predict,
          others: data.others,
          report_info_list: res.data.report_info_list, // レポート情報
        };
        await api.saveCheckListResults({
          token,
          checkListId: context.state.selectedCheckListId,
          results,
        }).catch(p401(context));
      } catch (err) {
        // エラー発生時に、予測結果をクリアする。
        await api.saveCheckListResults({
          token,
          checkListId: context.state.selectedCheckListId,
          results: null,
        }).catch(p401(context));
        if (err.response) {
          // alert(JSON.stringify(err.response.data));
          throw err.response.data.message;
        } else {
          throw err;
        }
      }
    },
    /**
     *「ChatGPT」とのChatを実行 Vuexストアの actions
     * @param {*} context 
     * @param {*} param1 
     */
    async callVxActAdviceGpt(context, payload) {
      debug(`callVxActAdviceGpt()`);
      let checklists = context.getters.selectedCheckList.checklists;
 
      // Spec Sheet: https://cd-inc.backlog.com/alias/wiki/972345
      let height = Number(checklists['height']);
      let weight = Number(checklists['weight']);
      let height100 = height / 100;
      let bmi = weight / height100 / height100;

      let query = {
        advice: {
          history: payload.history,
          type:    payload.mode,
        },
        attributes: {
          sex: checklists['sex'],
          create_age: Number(checklists['age']),
        },
        conditions: {
          means_go_out_bus_train: checklists['check001'], // 1) ﾊﾞｽや電車で1人で外出していますか integer 0:はい / 1:いいえ
          shopping: checklists['check002'], // 2) 日用品の買い物をしていますか integer 0:はい / 1:いいえ
          financial_deposits_savings: checklists['check003'], // 3) 預貯金の出し入れをしていますか integer 0:はい / 1:いいえ
          visit_friend: checklists['check004'], // 4) 友人の家を訪ねていますか integer 0:はい / 1:いいえ
          seeking_advice: checklists['check005'], // 5) 家族や友人の相談にのっていますか integer 0:はい / 1:いいえ
          climbing_stairs: checklists['check006'], // 6) 階段を手すりや壁をつたわらずに昇っていますか integer	0:はい / 1:いいえ
          getting_up_chair: checklists['check007'], // 7) 椅子に座った状態から何もつかまらずに立ち上がっていますか integer 0:はい / 1:いいえ
          continuous_walking: checklists['check008'], // 8) 15分位続けて歩いていますか integer 0:はい / 1:いいえ
          fallen: checklists['check009'], // 9) この1年間に転んだことがありますか integer	1:はい / 0:いいえ
          anxiety_falling: checklists['check010'], // 10) 転倒に対する不安は大きいですか integer 1:はい / 0:いいえ
          weight_loss: checklists['check011'], // 11) 6ヶ月間で2〜3kg以上の体重減少がありましたか integer	1:はい / 0:いいえ
          bmi_decrease: (bmi < 18.5 ? 1 : 0), // 12) BMIが18.5未満 integer	1:はい / 0:いいえ
          height: height, // 身長	height	number	1.0 ～
          weight: weight, // 体重	weight	number	1.0 ～
          bmi: bmi,       // BMI	bmi	number	1.0 ～
          eat_hard_food: checklists['check013'], // 13) 半年前に比べて硬いものが食べにくくなりましたか integer 1:はい / 0:いいえ
          whiffing: checklists['check014'], // 14) お茶や汁物等でむせることがありますか integer 1:はい / 0:いいえ
          dry_mouth: checklists['check015'], // 15) 口の渇きが気になりますか integer 1:はい / 0:いいえ
          go_out: checklists['check016'], // 16) 週に1回以上は外出していますか integer 0:はい / 1:いいえ
          decrease_go_out: checklists['check017'], // 17) 昨年と比べて外出の回数が減っていますか integer 1:はい / 0:いいえ
          forget: checklists['check018'], // 18) 周りの人から｢いつも同じ事を聞く｣などの物忘れがあると言われますか integer 1:はい / 0:いいえ
          research_phone_num: checklists['check019'], // 19) 自分で電話番号を調べて、電話をかけることをしていますか integer 0:はい / 1:いいえ
          forget_date: checklists['check020'], // 20) 今日が何月何日かわからない時がありますか integer 1:はい / 0:いいえ
          mood_fulfillment: checklists['check021'], // 21) (ここ2週間)毎日の生活に充実感がない integer 1:はい / 0:いいえ
          mood_unenjoyable: checklists['check022'], // 22) (ここ2週間)これまで楽しんでやれたことが楽しめなくなった integer 1:はい / 0:いいえ
          feeling_bored: checklists['check023'], // 23) (ここ2週間)以前は楽にできていたことが今ではおっくうに感じられる integer 1:はい / 0:いいえ
          feeling_useful: checklists['check024'], // 24) (ここ2週間)自分が役に立つ人間だと思えない integer 1:はい / 0:いいえ
          feeling_tired: checklists['check025'], // 25) (ここ2週間)わけもなく疲れたような感じがする integer 1:はい / 0:いいえ
        },
        options: {
          ai_model: 1,
        },
      };
      let token = context.getters.token;
      // SOIN-AIのAPIを呼出し
      let res = await api.callApiStreamGpt({ token, query }).catch(p401(context));
      try {
        let chatGenerator = api.streamChatCompletion({ token, query:res });
        return chatGenerator;
      } catch (error) {
        p401(context)(error);
      }
    },

    /**
     * ピング
     * @param {*} context 
     * @returns 
     */
    async ping(context) {
      debug(`ping`);
      try {
        let token = context.getters.token;
        let res = await api.ping({ token }).catch(p401(context));
        return res;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    // 管理機能 ------------------------------------------
    /**
     * 管理者ログイン
     * @param {Object} context 
     * @param {*} param1 
     * @returns 
     */
    async initAdminUser(context, { token }) {
      debug(`initAdminUser('${token}'`);

      try {
        // 認証
        if (token) {
          // ユーザ情報取得
          let adminUser = await adminapi.findSelf({ token }).catch(p401(context));
          context.commit('setAdminUser', adminUser);
          
          // ログインしたユーザに関するデータ取得
          await Promise.all([
            context.dispatch('adminUpdateUsers', token),
          ]);
          // loginDialogを閉じる
          context.commit('setAdminToken', token);
        }
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    // 管理機能 ------------------------------------------
    /**
     * 管理者ログイン
     * @param {Object} context 
     * @param {*} param1 
     * @returns 
     */
    async adminLogin(context, { accountName, accountPassword, rememberMe }) {
      debug(`adminLogin('${accountName}', '${accountPassword}', ${rememberMe})`);

      try {
        // 認証
        let token = await adminapi.authenticate({ accountName, accountPassword, rememberMe }).catch(p401(context));
        await context.dispatch("initAdminUser", { token });
        /*
        if (token) {
          // ユーザ情報取得
          let adminUser = await adminapi.findSelf({ token }).catch(p401(context));
          context.commit('setAdminUser', adminUser);
          
          // ログインしたユーザに関するデータ取得
          await Promise.all([
            context.dispatch('adminUpdateUsers', token),
          ]);
          // loginDialogを閉じる
          context.commit('setAdminToken', token);
        }
        */
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    /**
     * ユーザ一覧取得
     * @param {*} context 
     * @param {*} adminToken 
     * @returns 
     */
    async adminUpdateUsers(context, adminToken) {
      debug(`adminUpdateUsers('${adminToken}')`);

      try {
        let _token = adminToken || context.getters.adminToken;
        let users = await adminapi.findUsers({ token: _token }).catch(p401(context));
        context.commit('setUsers', users);
        return true;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async createUser(context, { userName, userMail, accountName, accountPassword, beginDtm, endDtm }) {
      debug(`createUser('${userName}', '${userMail}', '${accountName}', '${accountPassword}', '${beginDtm}', '${endDtm}')`);
      try {
        let [user = []] = await adminapi.createUser({
          token: context.getters.adminToken,
          userId: null,
          userName,
          userMail,
          accountName,
          accountPassword,
          beginDtm,
          endDtm,
        }).catch(p401(context));
        return user;
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updateUser(context, { userId, userName, userMail, beginDtm, endDtm }) {
      debug(`updateUser('${userId}', '${userName}', '${userMail}', '${beginDtm}', '${endDtm}')`);
      try {
        let token = context.getters.adminToken;
        await adminapi.updateUser({
          token,
          userId,
          userName,
          userMail,
          beginDtm,
          endDtm,
        }).catch(p401(context));
        await context.dispatch('adminUpdateUsers');
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async updatePassword(context, { userId, accountPassword }) {
      debug(`updatePassword('${userId}', '${accountPassword}')`);
      try {
        let token = context.getters.adminToken;
        await adminapi.updatePassword({
          token,
          userId,
          accountPassword,
        }).catch(p401(context));
        await context.dispatch('adminUpdateUsers');
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async deleteUser(context, { userId }) {
      debug(`deleteUser('${userId}')`);
      try {
        // alert(`${token}, ${userId}`);
        await adminapi.deleteUser({
          token: context.getters.adminToken,
          userId,
        }).catch(p401(context));
        await context.dispatch('adminUpdateUsers');
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    /**
     * オフィス - ユーザレコード作成
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
    async createOfficeUsers(context, { officeId, userId, beginDtm, endDtm }) {
      debug(`createOfficeUsers({'${officeId}', '${userId}', '${beginDtm}', '${endDtm}'}')`);
      try {
        let [officeUsers = []] = await adminapi.createOfficeUsers({
          token: context.getters.adminToken,
          officeId,
          userId,
          beginDtm,
          endDtm,
        }).catch(p401(context));
        await context.dispatch('updateOffices');
        return officeUsers;
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    /**
     * オフィス - ユーザレコード作成
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
     async findOfficeUsersByUserId(context, { userId }) {
      debug(`findOfficeUsersByUserId('${userId}')`);
      try {
        let officeUsers = await adminapi.findOfficeUsersByUserId({
          token: context.getters.adminToken,
          userId
        }).catch(p401(context));
        return officeUsers;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    /**
     * オフィス - ユーザレコード作成
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
     async findOfficeUsers(context) {
      debug(`findOfficeUsers()`);
      try {
        let officeUsers = await adminapi.findOfficeUsers({
          token: context.getters.adminToken
        }).catch(p401(context));
        return officeUsers;
      } catch (err) {
        // alert(err.message);
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },

    /**
     * オフィス - ユーザレコード作成
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
    async createInfo(context, { informationText, beginDtm, endDtm }) {
      debug(`createInfo({'${informationText}', '${beginDtm}', '${endDtm}'}')`);
      context.commit("addDebugPrint", `createInfo({'${informationText}', '${beginDtm}', '${endDtm}'}')` + `<br />`);
      try {
        let [infos = []] = await adminapi.createInfo({
          token: context.getters.adminToken,
          informationText,
          beginDtm,
          endDtm,
        }).catch(p401(context));
        await context.dispatch('updateInfos', { targetDtm: null});
        return infos;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    /**
     * オフィス - ユーザレコード作成
     * @param {*} context 
     * @param {*} param1 
     * @returns 
     */
     async updateInfo(context, { informationId, informationText, beginDtm, endDtm }) {
      debug(`updateInfo({'${informationId}', '${informationText}', '${beginDtm}', '${endDtm}'}')`);
      try {
        let [infos = []] = await adminapi.updateInfo({
          token: context.getters.adminToken,
          informationId,
          informationText,
          beginDtm,
          endDtm,
        }).catch(p401(context));
        await context.dispatch('updateInfos', { targetDtm: null});
        return infos;
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
    async deleteInfo(context, { informationId }) {
      debug(`deleteInfo({'${informationId}'})`);
      context.commit("addDebugPrint", `deleteInfo({'${informationId}'})` + `<br />`);
      try {
        await adminapi.deleteInfo({
          token: context.getters.adminToken,
          informationId,
        }).catch(p401(context));
        await context.dispatch('updateInfos', { targetDtm: null});
      } catch (err) {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      }
    },
  },
  getters: {
    token: state => state.token,

    //visibleLoginDialog: state => state.visibleLoginDialog,
    isInfosLoading: state => state.isInfosLoading,
    activeInfos: state => state.infos,

    isUserLoading: state => state.isUserLoading,
    activeUser: state => state.user,
    isAdmin: state => _.indexOf(state.user.roles || [], 'Admin') >= 0,

    isSexTypesLoading: state => state.isSexTypesLoading,
    activeSexTypes: state => state.sexTypes,

    isOfficesLoading: state => state.isOfficesLoading,
    activeOffices: state => state.offices,

    isGuestsLoading: state => state.isGuestsLoading,
    activeGuests: state => state.guests,
    selectedGuest: state => _.isNil(state.selectedGuestId) ? _.noop() : _.find(state.guests, x => x.guestId === state.selectedGuestId),
    activeHists: state => state.hists,
    isCheckListsLoading: state => state.isCheckListsLoading,
    activeCheckLists: state => state.checkLists,
    selectedCheckList: state => {
      let res = _.isNil(state.selectedCheckListId)
        ? _.noop()
        : _.find(state.checkLists, x => x.checkListId === state.selectedCheckListId);
      return res;
    },
    isCheckListDeleting: state => state.isCheckListDeleting,
    isCheckListCreating: state => state.isCheckListCreating,
    isCheckListSaving: state => state.isCheckListSaving,

    serviceItems: state => state.serviceItems,

    isConditionsSaving: state => state.isConditionsSaving,

    isServiceItemsOfCheckListUpdating: state => state.isServiceItemsOfCheckListUpdating,

    isFrailAiLoading: state => state.isFrailAiLoading,
  
    paginationInStore: state => state.pagination,
    fiscalYear: state => state.fiscalYear,
    activeFacilities: state => state.facilities,

    // 管理
    adminUser: state => state.admin,
    adminToken: state => state.adminToken,
    users: state => state.users,

    storedPageOptions: state => state.storedPageOptions,
    storedHistOptions: state => state.storedHistOptions,
    debugPrint: state => state.debugPrint,
  },
});

// ストアの適用
app.use(store);

export default store;